import React, { useState, useEffect } from 'react';
import NavBar from '../../../NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faGraduationCap, faMapMarkerAlt, faBook, faTag, faEyeSlash, faChevronDown, faChevronUp, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, serverTimestamp, arrayUnion, setDoc } from "firebase/firestore";
import '../../../../index.css';
import { db } from '../../../firebase';
import Draggable from 'react-draggable';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileJoyrideTour from '../../../Profile/ProfileJoyrideTour';
import { BsThreeDots, BsPlus } from 'react-icons/bs'; 
import { Card, Button } from 'flowbite-react';
import { useAuth } from '../../../../contexts/AuthContext';
import CustomSidebar from '../../Sidebar/Sidebar';
import { toast } from 'react-toastify';
import GPAGraph from './GPAGraph';
import { Timestamp } from 'firebase/firestore';
import { Spinner } from 'flowbite-react';
import StudentAdvisorMeeting from '../../../Profile/StudentAdvisorMeeting'; 

const AdvisorReviewStudentProfile = () => {
    
    const [currentStudent, setCurrentStudent] = useState(null);
    const [schedule, setSchedule] = useState([]);
    const student = useParams().studentId;
    const { currentUser, fetchedCourseData, fetchedMajors, fetchedSurveyData } = useAuth();
    const [photoURL, setPhotoURL] = useState([]);
    const [loading, setLoading] = useState(true);
    const [finalizedSchedule, setFinalizedSchedule] = useState({});
    const [completedSemesters, setCompletedSemesters] = useState({});
    const [showGPAGraph, setShowGPAGraph] = useState(false);
    const [engagementResources, setEngagementResources] = useState([]);
    const [applicableEngagements, setApplicableEngagements] = useState([]);
    const [loadingEngagements, setLoadingEngagements] = useState(true);
    const [milestoneData, setMilestoneData] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [completedMilestones, setCompletedMilestones] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [showNotes, setShowNotes] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [selectedMajor, setSelectedMajor] = useState(null); // New state for selected major
    const [dropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility
    const [surveyAnswers, setSurveyAnswers] = useState({});
    const [advisors, setAdvisors] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [scheduledMeetings, setScheduledMeetings] = useState([]);
    const [recommendedResources, setRecommendedResources] = useState([]); // New state for recommended resources
    const [resourcesLoading, setResourcesLoading] = useState(true); // Loading state for resources
    const [resourcesError, setResourcesError] = useState(null); // Error state for resources
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');

    // Function to add a new note
    useEffect(() => {
        // Fetch notes for this student from the currently logged-in advisor
        const loadNotes = async () => {
            if (!currentUser || !currentStudent) return;

            const notesDocRef = doc(db, 'advisorNotes', `${currentStudent.uid}_${currentUser.uid}`);
            const notesDoc = await getDoc(notesDocRef);
            if (notesDoc.exists()) {
                const data = notesDoc.data();
                setNotes(data.notes || []);
            } else {
                setNotes([]);
            }
        };
        loadNotes();
    }, [currentUser, currentStudent]);

    const handleAddNote = async () => {
        if (newNote.trim() && currentUser && currentStudent) {
            const notesDocRef = doc(db, 'advisorNotes', `${currentStudent.uid}_${currentUser.uid}`);

            // Ensure the document exists or create it
            const notesDoc = await getDoc(notesDocRef);
            if (!notesDoc.exists()) {
                await setDoc(notesDocRef, { notes: [] });
            }

            
            const newNoteObj = { text: newNote.trim(), timestamp: Timestamp.now() };
            await updateDoc(notesDocRef, {
            notes: arrayUnion(newNoteObj)
            });

            // Update local state
            setNotes(prevNotes => [...prevNotes, newNoteObj]);
            setNewNote('');
        }
    };

    const handleDeleteNote = async (index) => {
        if (currentUser && currentStudent) {
            // Remove note at index locally
            const updatedNotes = notes.filter((_, i) => i !== index);
            setNotes(updatedNotes);

            // Update Firestore
            const notesDocRef = doc(db, 'advisorNotes', `${currentStudent.uid}_${currentUser.uid}`);
            await setDoc(notesDocRef, { notes: updatedNotes }, { merge: true });
        }
    };

    useEffect(() => {
        const fetchStudentData = async () => {
            if (student) {
                try {
                    const studentDocRef = doc(db, 'users', student); // Ensure 'users' is your correct collection
                    const studentDoc = await getDoc(studentDocRef);
                    
                    if (studentDoc.exists()) {
                        setCurrentStudent({ uid: studentDoc.id, ...studentDoc.data() }); // Include the ID
                        console.log('Current user set:', { uid: studentDoc.id, ...studentDoc.data() });
                    } else {
                        console.error('No such student!');
                        // Optionally, navigate to a "Not Found" page or show an error message
                    }
                } catch (error) {
                    console.error('Error fetching student data:', error);
                    // Optionally, handle fetch errors
                }
            }
        };
    
        fetchStudentData();
    }, [student]); 


    useEffect(() => {
        const fetchEngagementResources = async () => {
            try {
                const resourcesSnapshot = await getDocs(collection(db, 'engagementResources'));
                const resourcesData = resourcesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setEngagementResources(resourcesData);
            } catch (error) {
                console.error('Error fetching engagement resources:', error);
            }
        };

        fetchEngagementResources();
    }, []);

    useEffect(() => {
        const evaluateEngagementResources = () => {
            if (!currentStudent || !engagementResources.length) return;

            const applicable = engagementResources.filter(resource => {
                console.log('Resource: ', resource, resource.field);
                const userValue = getNestedValue(resource.field, currentStudent); 
                console.log('userVal: ', userValue);// Utility to get nested fields
                const resourceValue = parseFloat(resource.value) || resource.value;
                console.log('Resource value: ', resourceValue, resource.operator);

                switch (resource.operator) {
                    case 'Equals':
                        return userValue === resourceValue;
                    case 'Less Than':
                        return parseFloat(userValue) < parseFloat(resourceValue);
                    case 'Greater Than':
                        return parseFloat(userValue) > parseFloat(resourceValue);
                    default:
                        return false;
                }
            });


            setApplicableEngagements(applicable);
            setLoadingEngagements(false);
        };

        evaluateEngagementResources();
    }, [currentStudent, engagementResources]);

    const getNestedValue = (fieldPath, obj) => {
        return fieldPath.split('.').reduce((acc, key) => acc && acc[key], obj);
    };

    const yearMapping = {
        "First Year": "year1",
        "Second Year": "year2",
        "Third Year": "year3",
        "Fourth Year": "year4",
        "Fifth Year": "year5"
    };

    useEffect(() => {
        if (currentStudent?.surveyAnswers?.Majors?.length > 0) {
            setSelectedMajor(currentStudent.surveyAnswers.Majors[0]); // Set initial major
        }
    }, [currentStudent]);

    const handleMajorChange = (major) => {
        setSelectedMajor(major);
        setDropdownVisible(false); // Hide dropdown after selection
    };

    const handleSelectMajorButtonClick = () => {
        setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
    };

    function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
        // Create a set for faster lookup of non-usable courses
        const nonUsableSet = new Set(nonUsableCourses);
    
        // Filter out the requirements from majorCourses
        const requirements = majorCourses.filter(majorCourse =>
            (majorCourse.courseType === 'Major Course' && majorCourse.Requirements) ||
            majorCourse.courseType === 'Requirement'
        );
    
        // Map student's courses for quick access and exclude non-usable courses
        const studentCoursesMap = new Map();
        courses.forEach((course, index) => {
            if (!nonUsableSet.has(course.courseInfo_courseNumber)) {
                studentCoursesMap.set(course.courseInfo_courseNumber, { ...course, index });
            }
        });
    
        // Build the possible matches between requirements and student's courses
        const reqIndexToPossibleCourses = [];
        requirements.forEach((requirement, reqIndex) => {
            const possibleCourses = requirement.Possibilities.split(',').map(course => course.trim());
            reqIndexToPossibleCourses[reqIndex] = [];
            possibleCourses.forEach(courseNumber => {
                if (studentCoursesMap.has(courseNumber)) {
                    reqIndexToPossibleCourses[reqIndex].push(studentCoursesMap.get(courseNumber).index);
                }
            });
        });
    
        // Initialize match arrays
        const matchToCourse = new Array(requirements.length).fill(-1); // requirement index -> course index
        const matchToRequirement = new Array(courses.length).fill(-1); // course index -> requirement index
    
        // Helper function for DFS in bipartite matching
        function bpm(u, seen) {
            for (const v of reqIndexToPossibleCourses[u]) {
                if (!seen[v]) {
                    seen[v] = true;
                    if (matchToRequirement[v] === -1 || bpm(matchToRequirement[v], seen)) {
                        matchToRequirement[v] = u;
                        matchToCourse[u] = v;
                        return true;
                    }
                }
            }
            return false;
        }
    
        // Perform the matching
        for (let u = 0; u < requirements.length; u++) {
            const seen = new Array(courses.length).fill(false);
            bpm(u, seen);
        }
    
        // Extract the matched courses
        const largestValidArray = [];
        for (let u = 0; u < matchToCourse.length; u++) {
            if (matchToCourse[u] !== -1) {
                largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
            }
        }
    
        return largestValidArray;
    }


    function findElectiveCourses(majorCourses, courses) {
        // Filter out the elective requirements from majorCourses
        const electiveRequirements = majorCourses.filter(
            majorCourse => majorCourse.courseType === 'Elective'
        );
    
        // Build the possible matches between electives and student's courses
        const electiveIndexToPossibleCourses = [];
        electiveRequirements.forEach((electiveReq, electiveIndex) => {
            const electiveName = electiveReq.Possibilities;
            electiveIndexToPossibleCourses[electiveIndex] = [];
    
            // If the elective name is "Free-NonMajor-Elective", allow any course to fulfill it
            if (electiveName === "Free-NonMajor-Elective") {
                for (let i = 0; i < courses.length; i++) {
                    electiveIndexToPossibleCourses[electiveIndex].push(i);
                }
            } else {
                // Otherwise, only include courses that match specific elective fulfillment criteria
                courses.forEach((studentCourse, courseIndex) => {
                    if (
                        studentCourse.Elective_fulfillment &&
                        studentCourse.Elective_fulfillment.includes(electiveName)
                    ) {
                        electiveIndexToPossibleCourses[electiveIndex].push(courseIndex);
                    }
                });
            }
        });
    
        // Initialize match arrays
        const matchToCourse = new Array(electiveRequirements.length).fill(-1); // elective index -> course index
        const matchToElective = new Array(courses.length).fill(-1); // course index -> elective index
    
        // Helper function for DFS in bipartite matching
        function bpm(u, seen) {
            for (const v of electiveIndexToPossibleCourses[u]) {
                if (!seen[v]) {
                    seen[v] = true;
                    if (matchToElective[v] === -1 || bpm(matchToElective[v], seen)) {
                        matchToElective[v] = u;
                        matchToCourse[u] = v;
                        return true;
                    }
                }
            }
            return false;
        }
    
        // Perform the matching
        for (let u = 0; u < electiveRequirements.length; u++) {
            const seen = new Array(courses.length).fill(false);
            bpm(u, seen);
        }
    
        // Extract the matched courses
        const largestValidArray = [];
        for (let u = 0; u < matchToCourse.length; u++) {
            if (matchToCourse[u] !== -1) {
                largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
            }
        }
    
        return largestValidArray;
    }


    const getStudentInfo = async () => {
        try {
            if (currentStudent) {
                setCurrentStudent(currentStudent);
                const userDocRef = doc(db, 'users', currentStudent.uid);
                const userDoc = await getDoc(userDocRef);
    
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    console.log('userData:', userData); // Debugging
    
                    // Validate and retrieve primaryTab
                    const primaryTab = userData?.primaryTab;
                    console.log('primaryTab:', primaryTab); // Debugging
    
                    if (!primaryTab) {
                        console.error('primaryTab is undefined');
                        // Optionally, you can set default values or handle this case as needed
                        return;
                    }
    
                    // Validate and retrieve schedules
                    const schedules = userData?.schedules;
                    if (!schedules || !schedules[primaryTab]) {
                        console.error(`No schedules found for primaryTab: ${primaryTab}`);
                        // Optionally, set default schedule or handle the absence of schedules
                        return;
                    }
    
                    const retrievedFinalizedSchedule = schedules[primaryTab];
                    setFinalizedSchedule(retrievedFinalizedSchedule);
                    console.log('finalizedSchedule:', finalizedSchedule); // Debugging
    
                    // Retrieve completedSemesters and photoURL with defaults
                    const retrievedCompletedSemesters = userData.completedSemesters || {};
                    setCompletedSemesters(retrievedCompletedSemesters);

                    const photoUrl = userData.photoUrl || '/default.jpg';
                    setPhotoURL(photoUrl);

                    let courseArray = [];
                    for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
                        let year = `year${i}`;
                        for (let j = 1; j <= 2; j++) {
                            let semester = `S${j}`;
                            if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                                courseArray.push(...finalizedSchedule[year][semester]['courses']);
                            }
                        }
                    }

                    const studentCourses = fetchedCourseData
                        ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
                        : [];

                    const retrievedSurveyAnswers = userData?.surveyAnswers;
                    setSurveyAnswers(retrievedSurveyAnswers);

                    // Retrieve the academic year from surveyAnswers with a default
                    const seeYr = userData.surveyAnswers?.Year || 'defaultYear';
                    const mappedSeeYr = yearMapping[seeYr];
                    if (!mappedSeeYr) {
                        console.warn(`Year mapping for '${seeYr}' not found.`);
                    }
    
                    // Aggregate courses for the specific academic year
                    let courseArray1 = [];
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (retrievedFinalizedSchedule[mappedSeeYr] && retrievedFinalizedSchedule[mappedSeeYr][semester]) {
                            courseArray1.push(...retrievedFinalizedSchedule[mappedSeeYr][semester]['courses']);
                        }
                    }

                    // Fetch and set schedule data
                    const year = userData.surveyAnswers?.Year || 'N/A';
                    await fetchScheduleData(year, schedules[primaryTab]);
    
                    // Calculate total credits for the current year
                    const currentYearCourses = studentCourses.filter(course => courseArray1.includes(course.courseInfo_courseNumber));
                    const totalCreditsCalculated = currentYearCourses.reduce((acc, course) => {
                        const credits = parseInt(course.Credits, 10);
                        return acc + (isNaN(credits) ? 0 : credits);
                    }, 0);
    
                    setTotalCredits(totalCreditsCalculated);
                    console.log("Student info fetched successfully");
                } else {
                    console.error('User document does not exist');
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };    



    const calculateProgressForMajor = (major) => {
        if (!major || !fetchedMajors){
            return {
                takenCoursesCount: 0,  // Total taken courses count
                planCoursesCount: 0,     // Remaining planned courses count
                totCoursesCount: 0           // Total courses count for the major
            }
        }

        // Aggregate courses from all years and semesters
        let courseArray = [];
        for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
            let year = `year${i}`;
            for (let j = 1; j <= 2; j++) {
                let semester = `S${j}`;
                if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                    courseArray.push(...finalizedSchedule[year][semester]['courses']);
                }
            }
        }
    
        // Get the student courses
        const studentCourses = fetchedCourseData
            ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
            : [];

        
        // Get the major courses and total courses count
        const majorCourses = Object.values(fetchedMajors[major]);
        const totalCourses = majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length;
    
        // Taken Courses Logic
        const takenCoursesSet = new Set();
        Object.keys(completedSemesters).forEach(year => {
            Object.keys(completedSemesters[year]).forEach(semesterKey => {
                if (completedSemesters[year][semesterKey]) {
                    const completedCourses = finalizedSchedule[year]?.[semesterKey]?.courses || [];
                    completedCourses.forEach(course => {
                        takenCoursesSet.add(course);
                    });
                }
            });
        });
        const takenCoursesArray = Array.from(takenCoursesSet);
        const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));
    
        // Identify taken major courses
        const majorRequiredSpecificCourses = majorCourses.filter((majorCourse) => ((majorCourse.courseType == 'Major Course') && (majorCourse.Possibilities == "")));
        const takenMajorCoursesArray = takenCourses.filter(course => {
            return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
        });
    
        // Identify taken elective courses
        const takenElectiveCoursesArray = findElectiveCourses(
            majorCourses.filter(course => course.courseType === 'Elective'),
            takenCourses
        );
    
        // Identify taken requirement courses
        const takenRequirementCoursesArray = findRequirementCourses(
            majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || course.courseType === 'Requirement'),
            takenCourses,
            takenElectiveCoursesArray
        );
    
        // Calculate all major, elective, and requirement courses
        const allMajorCoursesArray = studentCourses.filter(course => {
            return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
        });
    
        const allElectiveCoursesArray = findElectiveCourses(
            majorCourses.filter(course => course.courseType === 'Elective'),
            studentCourses
        );
    
        const allRequirementCoursesArray = findRequirementCourses(
            majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || course.courseType === 'Requirement'),
            studentCourses,
            allElectiveCoursesArray
        );
    
        // Calculate counts
        let takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length;
        let allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths;

    
        return {
            takenCoursesCount: takenCoursesLengths,  // Total taken courses count
            planCoursesCount: allCoursesLengths,     // Remaining planned courses count
            totCoursesCount: totalCourses            // Total courses count for the major
        };
    };

    const handleGPATagClick = () => {
        setShowGPAGraph((prev) => !prev);
    };

    // Generate mock GPA data
    const generateGPAData = () => {
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-indexed
        const currentYear = currentDate.getFullYear();

        const gpaData = [];

        for (let i = 11; i >= 0; i--) {
            const date = new Date(currentYear, currentMonth - i, 1);
            const monthName = months[date.getMonth()];
            const year = date.getFullYear();

            // For simplicity, previous months have random GPA between 3.0 and 4.0
            // The current month has the actual GPA
            if (i === 0) {
                gpaData.push({
                    month: `${monthName} ${year}`,
                    gpa: parseFloat(currentStudent?.surveyAnswers.GPA) || 0,
                });
            } else {
                gpaData.push({
                    month: `${monthName} ${year}`,
                    gpa: parseFloat((Math.random() * 1 + 3).toFixed(2)), // Random GPA between 3.00 and 4.00
                });
            }
        }

        return gpaData;
    };

    const gpaData = currentStudent ? generateGPAData() : [];
    

    // Get progress based on the selected major
    const { takenCoursesCount, planCoursesCount, totCoursesCount}  = calculateProgressForMajor(selectedMajor);
    
    const fetchScheduleData = (year, finalizedSchedule) => {
        if (year && finalizedSchedule) {
            const mappedYear = yearMapping[year];
            if (mappedYear) {
                const fallCourses = [];
                const springCourses = [];
                // Check if Fall semester exists and push courses to fallCourses array
                if (finalizedSchedule[mappedYear]?.S1) {
                    fallCourses.push(...finalizedSchedule[mappedYear].S1.courses);
                }
                // Check if Spring semester exists and push courses to springCourses array
                if (finalizedSchedule[mappedYear]?.S2) {
                    springCourses.push(...finalizedSchedule[mappedYear].S2.courses);
                }
                // Set the organized schedule state
                setSchedule({
                    Fall: fallCourses,
                    Spring: springCourses,
                });
            }
        }
    };    

    useEffect(() => {
        const fetchMilestoneData = async () => {
            if (currentStudent) {
                const userDocRef = doc(db, 'users', currentStudent.uid);
                console.log('dref: ', userDocRef);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setMilestoneData(data);
    
                    if (data.finalizedMilestones) {
                        const primaryTab = data.primaryTab || 'defaultTab'; // Get the active tab
                        const milestonesInprimaryTab = data.finalizedMilestones[primaryTab] || {};
    
                        const milestoneList = Object.values(milestonesInprimaryTab).reduce((acc, yearData) => {
                            Object.values(yearData).forEach(semData => {
                                if (semData.milestones) {
                                    acc = acc.concat(semData.milestones);
                                }
                            });
                            return acc;
                        }, []);
    
                        setMilestones(milestoneList);
                        const completed = milestoneList.filter(milestone => milestone.completed).length;
                        setCompletedMilestones(completed);
                    }
                }
            }
        };
        fetchMilestoneData();
    }, [currentStudent]);

    useEffect(() => {
        if (currentStudent && fetchedCourseData) {
            getStudentInfo();
        }
    }, [currentStudent, fetchedCourseData, fetchedSurveyData]);

    const handleNameClick = () => {
        setClickCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 4) {
                navigate('/roles');
            }
            return newCount;
        });
    };

    const userFullName = () => {
        if (currentStudent && currentStudent.firstName && currentStudent.lastName) {
            return `${currentStudent.firstName} ${currentStudent.lastName}`;
        }
        return 'User';
    };

    useEffect(() => {
        const fetchAdvisor = async () => {
          try {   

            if (!currentStudent) {
                setLoading(true);
                return;
              }
            const studentId = currentStudent.uid;
    
            // Fetch the advisor assignment from 'studentAdvisors' collection
            const mappingDoc = await db.collection('studentAdvisors').doc(studentId).get();
    
            if (!mappingDoc.exists) {
              // toast.info(`${userFullName()} has not been assigned an advisor yet.`);
              setIsLoading(false);
              return;
            }

            const {advisorIds}  = mappingDoc.data();
    
            if (!advisorIds) {
              // toast.info('Advisor assignment is incomplete.');
              setIsLoading(false);
              return;
            }
    
            // Fetch details for each advisor
            const advisorPromises = advisorIds.map(id => db.collection('advisors').doc(id).get());
            const advisorDocs = await Promise.all(advisorPromises);


            // Filter out any non-existing advisor documents
            const existingAdvisors = advisorDocs
                .filter(doc => doc.exists)
                .map(doc => ({
                    id: doc.id,
                    advisorId: doc.id, // Assuming advisorId is same as user id
                    firstName: doc.data().firstName,
                    lastName: doc.data().lastName,
                    meetingLink: doc.data().meetingLink || '',
                    profilePicture: doc.data().profilePicture || '/default.jpg', // Assuming profilePicture field
                }));

            const missingAdvisors = advisorDocs.filter(doc => !doc.exists).map(doc => doc.id);
            if (missingAdvisors.length > 0) {
                toast.error(`Assigned advisor data not found for IDs: ${missingAdvisors.join(', ')}`);
            }

            setAdvisors(existingAdvisors);
          } catch (error) {
            console.error('Error fetching advisor data:', error);
            toast.error('Failed to fetch advisor data.');
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchAdvisor();
      }, [currentStudent]);

      useEffect(() => {
        const fetchScheduledMeetings = async () => {
            try {
                const meetingsSnapshot = await db
                    .collection('meetings')
                    .where('studentId', '==', currentStudent.uid)
                    .orderBy('datetime', 'asc')
                    .limit(5)
                    .get();
    
                const meetingsData = meetingsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
    
                setScheduledMeetings(meetingsData);
            } catch (error) {
                console.error('Error fetching scheduled meetings:', error);
            }
        };
    
        if (currentStudent) {
            fetchScheduledMeetings();
        }
    }, [currentStudent]);    

        // Fetch recommended resources
        const fetchRecommendedResources = async () => {
            try {
                if (currentStudent) {
                    const userDocRef = doc(db, 'users', currentStudent.uid);
                    const userDoc = await getDoc(userDocRef);
        
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const resourceIds = userData.Resources || [];
        
                        if (resourceIds.length > 0) {
                            const resourcesData = [];
                            const batchSize = 10;
                            const batches = [];
        
                            // Split resourceIds into batches of 10
                            for (let i = 0; i < resourceIds.length; i += batchSize) {
                                const batchIds = resourceIds.slice(i, i + batchSize);
                                const resourcesCollection = collection(db, 'resources');
                                const resourcesQuery = query(resourcesCollection, where('__name__', 'in', batchIds));
                                batches.push(getDocs(resourcesQuery));
                            }
        
                            // Execute all batches concurrently
                            const snapshots = await Promise.all(batches);
        
                            // Aggregate results from all batches
                            snapshots.forEach(snapshot => {
                                snapshot.docs.forEach(docSnap => {
                                    resourcesData.push({
                                        id: docSnap.id,
                                        ...docSnap.data(),
                                    });
                                });
                            });
        
                            setRecommendedResources(resourcesData);
                        } else {
                            setRecommendedResources([]);
                        }
                    } else {
                        setRecommendedResources([]);
                    }
                }
            } catch (error) {
                console.error('Error fetching recommended resources:', error);
                setResourcesError('Failed to load recommended resources.');
            } finally {
                setResourcesLoading(false);
            }
        };
    
        useEffect(() => {
            if (currentStudent) {
                fetchRecommendedResources();
            }
        }, [currentStudent]);
        
        // Function to open the scheduling modal
    const openModal = () => {
        setIsModalOpen(true);
    };

      // Function to close the scheduling modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    const CustomModal = ({ isOpen, onClose, title, children }) => {
        // Close the modal when the Escape key is pressed
        useEffect(() => {
          const handleEsc = (event) => {
            if (event.key === 'Escape') onClose();
          };
          window.addEventListener('keydown', handleEsc);
          return () => window.removeEventListener('keydown', handleEsc);
        }, [onClose]);
    
        if (!isOpen) return null;
    
        return (
          <div
            className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
            onClick={onClose} // Close when clicking on the backdrop
          >
            <div
              className="relative w-full max-w-7xl h-full max-h-full"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
              {/* Modal content */}
              <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700 flex flex-col h-full">
                {/* Modal header */}
                <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    {title}
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={onClose}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
    
                {/* Modal body */}
                <div className="p-6 space-y-6 overflow-y-auto flex-grow bg-white dark:bg-gray-700">
                  {children}
                </div>

              </div>
            </div>
          </div>
        );
      };

    if (loading) {
        return (
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        );
      }

    const totalMilestones = milestones.length;
    const milestoneProgress = totalMilestones > 0 ? Math.round((completedMilestones / totalMilestones) * 100) : 0;
    // const takenCourses = 0; // Since completedCourses is currently zero
    const progressTaken = totCoursesCount > 0 ? Math.round((planCoursesCount / totCoursesCount) * 100) : 0;
    const progressDone = totCoursesCount > 0 ? Math.round((takenCoursesCount / totCoursesCount) * 100) : 0;
    const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

    const TagList = ({ tags }) => {
        const [showAll, setShowAll] = useState(false);
    
        if (!Array.isArray(tags) || tags.length === 0) {
            return (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                    No Tags Selected
                </span>
            );
        }
    
        const handleToggle = () => setShowAll(!showAll);
    
        return (
            <div className="relative">
                <div
                    className={`flex flex-wrap items-center ${showAll ? 'max-h-none' : 'max-h-12 overflow-hidden'} transition-all`}
                >
                    {tags.map((tag, index) => (
                        <div
                            key={index}
                            className={`flex items-center mr-2 mb-1 ${!showAll && index >= 3 ? 'hidden' : ''}`}
                            style={{ lineHeight: '1.2' }} // Ensures better alignment of text and icon
                        >
                            <FontAwesomeIcon
                                icon={faTag}
                                className="mr-1"
                                style={{ verticalAlign: 'middle', lineHeight: 'inherit' }} // Vertically align the icon
                            />
                            <span className="text-sm leading-none">{tag}</span>
                        </div>
                    ))}
                    {showAll && (
                        <button
                            onClick={handleToggle}
                            className="rounded bg-gray-100 px-1 py-1 flex items-center justify-center hover:text-blue-500 hover:underline"
                            aria-label="Hide"
                        >
                            <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                        </button>
                    )}
                </div>
                {tags.length > 3 && !showAll && (
                    <button
                        onClick={handleToggle}
                        className="absolute top-0 rounded right-0 bg-gray-100 px-1 py-.5 flex items-center justify-center hover:text-blue-500 hover:underline"
                        aria-label="Toggle"
                        style={{ right: '-18px' }} // Move button further right
                    >
                        <BsThreeDots size={15} />
                    </button>
                )}
            </div>
        );
    };

    const calculateTotalCredits = (schedule) => {
        const allClasses = Object.values(schedule).flat();

        const filteredCourses = fetchedCourseData.filter(course =>
            allClasses.includes(course.courseInfo_courseNumber)
        );

        const totalCredits = filteredCourses.reduce((sum, course) => {
            return sum + parseInt(course.Credits, 10);
        }, 0);

        return totalCredits;
    }
    

    return (
        <div>
          <div className="w-1/5"> {/* Set a smaller width for the sidebar */}
            <CustomSidebar adjustment={"Student Profile"} />
          </div>
        {/* <ProfileJoyrideTour/> */}
        
        <Card className="w-full max-w-6xl px-4 bg-white-100 shadow-md ml-10 relative mt-20"> {/* Widen the card */}
            <div className='flex justify-between'>
            <div className="flex items-center mb-1">
                <img className="w-[160px] h-[160px] mb-4 mr-5 rounded-full" src={photoURL || '/default.jpg'} alt="Profile Picture" />
                <div className="flex-grow max-w-[calc(100%-100px)]">
                    <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>{userFullName()}</h1>
                    {currentStudent?.email && <p className="text-sm mb-1">{currentStudent.email}</p>}
                    {(currentStudent?.surveyAnswers?.Year || 'N/A') && (currentStudent?.surveyAnswers?.Faculties?.length > 0 || 'N/A') && (
                        <p className="text-sm mb-1">
                            {currentStudent?.surveyAnswers?.Year || 'N/A'} - {currentStudent?.surveyAnswers?.Faculties?.join(', ') || 'N/A'}
                        </p>
                    )}
                    {currentStudent?.surveyAnswers?.Majors?.length > 0 && (
                        <p className="text-sm">
                            <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                            {currentStudent?.surveyAnswers?.Majors?.length === 1 ? 'Major in' : 'Majors in'} {currentStudent?.surveyAnswers?.Majors?.join(', ')}
                        </p>
                    )}
                    {currentStudent?.surveyAnswers?.Minors?.length > 0 && (
                        <p className="text-sm mb-1">
                            <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                            {currentStudent.surveyAnswers.Minors.length === 1 ? 'Minor in' : 'Minors in'} {currentStudent.surveyAnswers.Minors.join(', ')}
                        </p>
                    )}
                    {currentStudent?.surveyAnswers?.Campus && (
                        <p className="text-sm mb-1">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> {currentStudent.surveyAnswers.Campus}
                        </p>
                    )}
                    {(() => {
                        const startMonth = currentStudent?.surveyAnswers?.SchoolStart?.month;
                        const startYear = currentStudent?.surveyAnswers?.SchoolStart?.year;
                        const endMonth = currentStudent?.surveyAnswers?.SchoolEnd?.month;
                        const endYear = currentStudent?.surveyAnswers?.SchoolEnd?.year;

                        const startDate = startMonth && startYear ? `${startMonth} ${startYear}` : 'N/A';
                        const endDate = endMonth && endYear ? `${endMonth} ${endYear}` : 'N/A';

                        if (startDate !== 'N/A' || endDate !== 'N/A') {
                            return (
                                <p className="text-sm mb-1">
                                    <FontAwesomeIcon icon={faGraduationCap} /> {startDate} - {endDate}
                                </p>
                            );
                        }

                        return null;
                    })()}
                    {currentStudent && (
                                <div
                                    onClick={handleGPATagClick}
                                    className="flex items-center text-sm cursor-pointer hover:text-purple-600 mt-1"
                                    title="Click to view GPA progression"
                                >
                                    <FontAwesomeIcon icon={faChartLine} className="mr-1" />
                                    GPA: {currentStudent.surveyAnswers.GPA}
                                </div>
                            )}
                    {/* Flex container for Pronouns and GPA */}
                    {(currentStudent?.surveyAnswers?.Pronouns || (Array.isArray(currentStudent?.surveyAnswers?.Tags) && currentStudent.surveyAnswers.Tags.length > 0)) && (
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                            {currentStudent?.surveyAnswers?.Pronouns && (
                                <span className="mr-2 mb-1">Pronouns: {currentStudent.surveyAnswers.Pronouns}</span>
                            )}
                            {/*<FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                            {authUser?.surveyAnswers?.GPA && (
                                <span className="mr-2 mb-1">GPA: {authUser.surveyAnswers.GPA}</span>
                            )}*/}
                            <TagList tags={currentStudent?.surveyAnswers?.Tags} />
                        </div>
                        )}
                    </div>
                </div>
                {/* Notes Card */}
                
                <Draggable handle=".notes-handle">
                <div 
                    className="fixed top-2 right-2 w-1/4 p-4 bg-gray-50 rounded-lg border border-gray-200 mb-10 z-50 cursor-move"
                    style={{ boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}
                >
                    {/* We add a handle for dragging */}
                    <div className="flex items-center justify-between mb-2 notes-handle cursor-move">
                    <h2 className="text-gray-700 font-semibold">Notes</h2>
                    <button
                        onClick={() => setShowNotes(!showNotes)}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        aria-label="Toggle notes visibility"
                    >
                        <FontAwesomeIcon icon={showNotes ? faChevronUp : faChevronDown} />
                    </button>
                    </div>

                    {/* New Note Input (always visible) */}
                    <div className="mb-4">
                    <textarea
                        className="w-full h-20 p-2 border border-gray-300 rounded resize-none"
                        placeholder="Add a new note..."
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                    />
                    <button
                        className="mt-2 w-full bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                        onClick={handleAddNote}
                    >
                        Add Note
                    </button>
                    </div>

                    {/* Notes List Container with transition */}
                    <div
                    className={`
                        overflow-hidden 
                        transition-all 
                        duration-500 
                        ease-in-out
                        ${showNotes ? 'max-h-[500px]' : 'max-h-0'}
                    `}
                    >
                    <ul className="space-y-2 mt-2">
                        {notes.length > 0 ? (
                        notes.map((note, index) => (
                            <li
                            key={index}
                            className="relative p-2 bg-yellow-100 text-yellow-800 rounded shadow-md"
                            >
                            <div>{note.text}</div>
                            {note.timestamp && note.timestamp.toDate && (
                                <div className="text-xs text-gray-600 mt-1">
                                {note.timestamp.toDate().toLocaleString()}
                                </div>
                            )}
                            <button
                                className="absolute top-1 right-1 text-red-500 hover:text-red-700"
                                onClick={() => handleDeleteNote(index)}
                            >
                                &times;
                            </button>
                            </li>
                        ))
                        ) : (
                        <p className="text-gray-500 text-sm">No notes yet.</p>
                        )}
                    </ul>
                    </div>
                    </div>
                    </Draggable>
                </div>
                
                <div className="flex justify-between ">
                <div className="mb-0">
                    <div className="flex items-center mb-2">
                        {surveyAnswers.Majors?.length > 1 ? (
                            <>
                                <h4>Progress Towards</h4>
                                <div className="relative">
                                    {/* Button that shows the major name and toggles dropdown */}
                                    <button
                                        className="ml-1 px-2 py-1 bg-gray-200 rounded transition-colors hover:bg-gray-300"
                                        onClick={handleSelectMajorButtonClick}
                                    >
                                        <span>{selectedMajor || 'N/A'}</span>
                                    </button>
                                    <span className="ml-1">Pathway</span>

                                    {/* Dropdown that appears when button is clicked */}
                                    {dropdownVisible && (
                                        <div className="absolute left-0 mt-1 bg-white border border-gray-200 rounded shadow-lg z-10">
                                            <ul className="py-1">
                                                {surveyAnswers.Majors.map((major, index) => (
                                                    <li key={index}>
                                                        <button
                                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                            onClick={() => handleMajorChange(major)}
                                                        >
                                                            {major}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <h4>
                                Progress Towards {surveyAnswers.Majors?.[0] || 'N/A'} Pathway
                            </h4>
                        )}
                    </div>
                    <div className="relative w-full max-w-[25vw] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                            className="absolute bg-green-500 h-2.5"
                            style={{
                                width: `${progressDone}%`,
                                left: '0',
                                top: 0,
                                borderTopLeftRadius: '9999px',
                                borderBottomLeftRadius: '9999px',
                                borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                                borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                            }}
                        ></div>
                        <div
                            className="absolute bg-yellow-300 h-2.5"
                            style={{
                                width: `${progressTaken}%`,
                                left: `${progressDone}%`,
                                top: 0,
                                borderTopRightRadius: '9999px',
                                borderBottomRightRadius: '9999px',
                                borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                                borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                            }}
                        ></div>
                    </div>
                    <div className="flex justify-between mt-2 space-x-10 w-full max-w-[25vw] items-center">
                        <div className="rounded bg-green-100 px-2 py-0.3">
                            <span className="text-green-600 font-semibold">{takenCoursesCount} Taken</span>
                        </div>
                        <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                            <span className="text-yellow-500 font-semibold">{planCoursesCount || 0} Planned</span>
                        </div>
                        <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                            <span className="text-gray-600 font-semibold">{remainingCourses || 0} Unplanned</span>
                        </div>
                    </div>

                    </div>
                    <div className="milestone-progress">
                        <h4 className="mb-2">Milestone Progress</h4>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${milestoneProgress || 0}%` }}></div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <div className="rounded bg-blue-100 px-2 py-0.3">
                                <span className="text-blue-600 font-semibold">{completedMilestones || 0} Completed</span>
                            </div>
                            <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                                <span className="text-gray-500 font-semibold">{(totalMilestones || 0) - (completedMilestones || 0)} Remaining</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-2 mt-1">
                    {/* Engagement Opportunities Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-700 font-semibold mb-2">Engagement Opportunities</h2>
                        {loadingEngagements ? (
                            <Spinner aria-label="Loading engagement opportunities" />
                        ) : applicableEngagements.length > 0 ? (
                            <ul className="space-y-2">
                                {applicableEngagements.map(resource => (
                                    <li key={resource.id}>
                                        <div className="bg-white mb-10 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md p-2 flex flex-col justify-between relative">
                                           <h5 className="text-xs font-medium text-gray-600 dark:text-gray-400 truncate mb-2">
                                            {resource.name}
                                            </h5>
                                            {/* Alert Icon */}
                                            <span
                                                className="absolute bottom-2 right-2 text-red-500 text-sm"
                                            >
                                                ⚠️
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-sm text-gray-500">No engagement opportunities applicable.</p>
                        )}
                    </div>

                    {/* Combined Personality Traits, Skills, and Interests Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-700 font-semibold mb-2">Traits, Skills, and Interests</h2>
                        <div className="overflow-y-auto max-h-60">
                            <div className="flex flex-wrap">

                                {/* Personality Traits Section */}
                                <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Personality Traits</h3>
                                {Array.isArray(currentStudent?.surveyAnswers?.RecPersonality) && currentStudent.surveyAnswers.RecPersonality.length > 0 ? (
                                    currentStudent.surveyAnswers.RecPersonality.map((trait, index) => (
                                        <span key={index} className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 mb-2">{trait}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Personality Traits</span>
                                )}

                                {/* Skills Section */}
                                <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Skills</h3>
                                {Array.isArray(currentStudent?.surveyAnswers?.RecSkills) && currentStudent.surveyAnswers.RecSkills.length > 0 ? (
                                    currentStudent.surveyAnswers.RecSkills.map((skill, index) => (
                                        <span key={index} className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400 mb-2">{skill}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Skills</span>
                                )}

                                {/* Interests Section */}
                                <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Interests</h3>
                                {Array.isArray(currentStudent?.surveyAnswers?.RecInterests) && currentStudent.surveyAnswers.RecInterests.length > 0 ? (
                                    currentStudent.surveyAnswers.RecInterests.map((interest, index) => (
                                        <span key={index} className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2">{interest}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Interests</span>
                                )}

                            </div>
                        </div>
                    </div>

                    {/* Advising Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200 flex flex-col">
                        <h2 className="text-gray-700 font-semibold mb-2">Advising Team</h2>

                        {/* Advisor Avatars with Scrollable Container */}
                        <div className="flex items-center space-x-4 mb-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300">
                            {(advisors || []).slice(0, 3).map((advisor, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <img
                                        className="w-12 h-12 rounded-full object-cover mb-1"
                                        src={advisor.profilePicture || '/default.jpg'}
                                        alt={`${advisor.firstName} ${advisor.lastName}`}
                                    />
                                    <span className="text-sm font-medium text-gray-800 dark:text-white">
                                        {`${advisor.firstName} ${advisor.lastName}`}
                                    </span>
                                </div>
                            ))}
                            {(advisors || []).length > 3 && (
                                <span className="text-sm text-blue-600 dark:text-blue-400">
                                    +{(advisors || []).length - 3} more
                                </span>
                            )}
                        </div>

                        {/* Combined Upcoming Meetings with Scroll */}
                        <div className="mb-2">
                            <h4 className="text-sm font-semibold text-gray-700 dark:text-gray-100 mb-1">
                                Upcoming Meetings
                            </h4>
                            {scheduledMeetings.length > 0 ? (
                                <ul className="space-y-1 max-h-24 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                                    {scheduledMeetings.slice(0, 5).map((meeting, index) => (
                                        <li key={index} className="text-sm text-gray-600 dark:text-gray-300">
                                            {new Date(meeting.datetime.seconds * 1000).toLocaleString('en-US', {
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                            })}{' '}
                                            with {meeting.advisorName}
                                        </li>
                                    ))}
                                    {scheduledMeetings.length > 5 && (
                                        <li className="text-sm text-blue-600 dark:text-blue-400">
                                            +{scheduledMeetings.length - 5} more...
                                        </li>
                                    )}
                                </ul>
                            ) : (
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    No upcoming meetings scheduled.
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                    <h2 className="text-gray-700 font-semibold">
                        Current Schedule ({currentStudent?.surveyAnswers?.Year || 'N/A'}) - Total Credits: {calculateTotalCredits(schedule)}
                    </h2>
                    {Object.keys(schedule).length > 0 ? (
                        <div className="flex justify-between">
                            <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}> {/* Reduced gap */}
                                <h3 className="text-lg text-gray-500 font-semibold mt-2">Fall Semester</h3>
                                <ul>
                                    {schedule.Fall.length > 0 ? (
                                        schedule.Fall.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Fall Semester
                                        </span>
                                    )}
                                </ul>
                            </div>

                            <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}> {/* Reduced gap */}
                                <h3 className="text-lg text-gray-500 font-semibold mt-2">Spring Semester</h3>
                                <ul>
                                    {schedule.Spring.length > 0 ? (
                                        schedule.Spring.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Spring Semester
                                        </span>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            No Planned Courses for Current Year
                        </span>
                    )}
                </div>

                {/* Recommended Resources Section */}
                <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                    <h2 className="text-gray-700 font-semibold mb-4">Recommended Resources</h2>
                    {resourcesLoading ? (
                        <div className="flex justify-center items-center">
                            <Spinner aria-label="Loading recommended resources" />
                        </div>
                    ) : resourcesError ? (
                        <div className="text-red-500">{resourcesError}</div>
                    ) : recommendedResources.length > 0 ? (
                        <div className="flex flex-col space-y-4">
                            {recommendedResources.map(resource => (
                                <div
                                    key={resource.id}
                                    className="flex flex-col sm:flex-row bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                                >
                                    {/* Resource Image */}
                                    <img
                                        className="w-48 h-48 sm:w-56 sm:h-56 flex-shrink-0 rounded-t-lg sm:rounded-l-lg object-cover"
                                        src={resource.image || 'https://via.placeholder.com/150'}
                                        alt={resource.name}
                                    />
                                    {/* Resource Details */}
                                    <div className="p-4 flex flex-col justify-between w-full">
                                        <div>
                                            <h5 className="text-lg font-bold text-gray-900 dark:text-white">{resource.name}</h5>
                                            <p className="text-sm text-gray-700 dark:text-gray-400 mt-1">{resource.description}</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">Department: {resource.department}</p>
                                        </div>
                                        {/* Learn More Button or No Link Message */}
                                        {resource.link ? (
                                            <Button
                                                color="gray"
                                                size="xs"
                                                className="mt-2 self-start"
                                                onClick={() => window.open(resource.link, '_blank')}
                                            >
                                                Learn More
                                            </Button>
                                        ) : (
                                            <span className="text-xs text-gray-500 mt-2">No link available</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-gray-500">No recommended resources available.</div>
                    )}
                </div>
                {showGPAGraph && (
                    <div className="z-10 absolute bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200 mt-4">
                        <h2 className="text-gray-700 font-semibold mb-4">GPA Progression</h2>
                        <GPAGraph gpaData={gpaData} />
                        <Button
                            color="red"
                            size="xs"
                            className="mt-4"
                            onClick={() => setShowGPAGraph(false)}
                        >
                            Close
                        </Button>
                    </div>
                )}
            </Card>
        </div>
    );    
};

export default AdvisorReviewStudentProfile;
