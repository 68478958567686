import React, { useState, useEffect, useMemo } from 'react';
import { db, auth } from '../../firebase';
import { TextInput, Spinner, Modal, Label, Select, Button  } from 'flowbite-react';
import CustomSidebar from '../Sidebar/Sidebar';
import { FaSearch, FaExternalLinkAlt, FaPlus, FaExclamationTriangle } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import StudentListing from '../Students/StudentListing/StudentListing';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  writeBatch,
  query,
  where,
  doc,
} from 'firebase/firestore';

const ResourcePage = ({ student }) => {
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [activeTab, setActiveTab] = useState('resources');
  const [newResource, setNewResource] = useState({
    name: '',
    description: '',
    image: '',
    department: '',
    link: '',
  });
  const [opportunityReports, setOpportunityReports] = useState([]);
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [newOpportunity, setNewOpportunity] = useState({
    name: '',
    field: '',
    operator: '',
    value: '',
    criticallyHigh: '',
    criticallyLow: '',
  });
  const [usersData, setUsersData] = useState([]);
  const [opportunityCounts, setOpportunityCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editResourceId, setEditResourceId] = useState(null);

  // Fetch students data
  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user found.');
          return;
        }

        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('role', '==', 'student'));
        const snapshot = await getDocs(q);

        const firestoreData = snapshot.docs.map((docSnap) => {
          const data = docSnap.data();
          const majors = data.surveyAnswers?.Majors || ['No Major'];
          const minors = data.surveyAnswers?.Minors || ['No Minor'];
          const planned = data.courseProgress?.planned || 0;
          const taken = data.courseProgress?.taken || 0;
          const total = data.courseProgress?.total || 0;

          return {
            id: docSnap.id,
            fromFirestore: true,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email || 'N/A',
            firstName: data.firstName,
            lastName: data.lastName,
            GPA: data.surveyAnswers?.GPA || 'N/A',
            surveyAnswers: data.surveyAnswers,
            resources: data.Resources || [],
            major: majors,
            minor: minors,
            planned: planned,
            taken: taken,
            total: total,
            classesTaken: data.classesTaken || 0,
            classesRegistered: data.classesRegistered || 0,
          };
        });

        setStudents(firestoreData);
        setFilteredStudents(firestoreData);
      } catch (error) {
        console.error('Error fetching students data:', error);
      }
    };

    fetchStudentsData();
  }, []);

    // Fetch users data for Engagement Opportunities
    useEffect(() => {
      const fetchUsersData = async () => {
        try {
          const usersRef = collection(db, 'users');
          const q = query(usersRef);
          const snapshot = await getDocs(q);
  
          const users = snapshot.docs.map((docSnap) => {
            const data = docSnap.data();
            return {
              id: docSnap.id,
              ...data,
            };
          });
          setUsersData(users);
        } catch (error) {
          console.error('Error fetching users data:', error);
        }
      };
  
      fetchUsersData();
    }, []);

  // Fetch resources from Firestore
  useEffect(() => {
    const fetchResources = async () => {
      try {
        const resourcesRef = collection(db, 'resources');
        const snapshot = await getDocs(resourcesRef);
        const resourcesData = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setResources(resourcesData);
        setFilteredResources(resourcesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

    fetchResources();
  }, []);

    // Fetch Opportunity Reports from Firestore
    useEffect(() => {
      const fetchOpportunityReports = async () => {
        try {
          const reportsRef = collection(db, 'engagementResources');
          const snapshot = await getDocs(reportsRef);
          const reportsData = snapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          setOpportunityReports(reportsData);
  
          // Extract counts for each report
          const counts = {};
          reportsData.forEach((report) => {
            counts[report.name] = report.matchingUsersCount || 0;
          });
          setOpportunityCounts(counts);
        } catch (error) {
          console.error('Error fetching opportunity reports:', error);
        }
      };
  
      fetchOpportunityReports();
    }, []);

  // Debounced search handler to optimize performance
  const handleSearch = debounce((term) => {
    if (term) {
      const filtered = resources.filter((res) =>
        res.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredResources(filtered);
    } else {
      setFilteredResources(resources);
    }
  }, 300);

  // Handle input change for search
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term);
  };

  // Toggle create form modal
  const handleToggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
    setIsEditing(false);
    setNewResource({
      name: '',
      description: '',
      image: '',
      department: '',
      link: '',
    });
  };

  // Handle input change for resource form
  const handleInputChange = (e) => {
    setNewResource({
      ...newResource,
      [e.target.name]: e.target.value,
    });
  };

  // Create a new resource in Firestore
  const handleCreateResource = async () => {
    if (
      !newResource.name ||
      !newResource.description ||
      !newResource.department
    ) {
      alert('Please fill out all required fields!');
      return;
    }
    setLoading(true);
    try {
      const resourcesRef = collection(db, 'resources');
      const docRef = await addDoc(resourcesRef, {
        ...newResource,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      const createdResource = { id: docRef.id, ...newResource };
      setResources((prev) => [...prev, createdResource]);
      setFilteredResources((prev) => [...prev, createdResource]);
      setShowCreateForm(false);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
      });
    } catch (error) {
      console.error('Error creating resource:', error);
      alert('Failed to create resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle editing a resource
  const handleEditResource = (resource) => {
    setIsEditing(true);
    setEditResourceId(resource.id);
    setNewResource({
      name: resource.name,
      description: resource.description,
      image: resource.image,
      department: resource.department,
      link: resource.link,
    });
    setShowCreateForm(true);
  };

  // Save edited resource to Firestore
  const handleSaveEditedResource = async () => {
    if (
      !newResource.name ||
      !newResource.description ||
      !newResource.department
    ) {
      alert('Please fill out all required fields!');
      return;
    }
    setLoading(true);
    try {
      const resourceRef = doc(db, 'resources', editResourceId);
      await updateDoc(resourceRef, {
        ...newResource,
        updatedAt: new Date(),
      });

      // Update the resource in local state
      setResources((prevResources) =>
        prevResources.map((res) =>
          res.id === editResourceId ? { id: res.id, ...newResource } : res
        )
      );
      setFilteredResources((prevResources) =>
        prevResources.map((res) =>
          res.id === editResourceId ? { id: res.id, ...newResource } : res
        )
      );
      setShowCreateForm(false);
      setIsEditing(false);
      setEditResourceId(null);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
      });
    } catch (error) {
      console.error('Error updating resource:', error);
      alert('Failed to update resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle deleting a resource
  const handleDeleteResource = async (resourceId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this resource? This action cannot be undone.'
    );
    if (!confirmDelete) return;

    setLoading(true);
    try {
      const resourceRef = doc(db, 'resources', resourceId);
      await deleteDoc(resourceRef);

      // Remove the resource from local state
      setResources((prevResources) =>
        prevResources.filter((res) => res.id !== resourceId)
      );
      setFilteredResources((prevResources) =>
        prevResources.filter((res) => res.id !== resourceId)
      );
    } catch (error) {
      console.error('Error deleting resource:', error);
      alert('Failed to delete resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle recommending a resource to a student
  const handleRecommend = (resource) => {
    setSelectedResource(resource);
    setShowAssignModal(true);
  };

  // Function to close modals
  const closeModal = () => {
    setShowAssignModal(false);
    setShowCreateForm(false);
    setSelectedResource(null);
    setIsEditing(false);
    setEditResourceId(null);
    setNewResource({
      name: '',
      description: '',
      image: '',
      department: '',
      link: '',
    });
    setNewOpportunity({
      name: '',
      field: '',
      operator: '',
      value: '',
      criticallyHigh: '',
      criticallyLow: '',
    });
  };

  // Handle recommendation success by updating Firestore
  const handleRecommendSuccess = async (selectedStudentIds) => {
    if (!selectedResource) return;

    setAssigning(true);
    try {
      const batch = writeBatch(db);

      selectedStudentIds.forEach((studentId) => {
        const studentRef = doc(db, 'users', studentId);
        batch.update(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
      });

      await batch.commit();

      alert(
        `Resource "${selectedResource.name}" recommended successfully to ${selectedStudentIds.length} student(s).`
      );
      closeModal();
    } catch (error) {
      console.error('Error recommending resources:', error);
      alert('Failed to recommend resource. Please try again.');
    } finally {
      setAssigning(false);
    }
  };

    // Handle creating a new Opportunity Report
    const handleCreateOpportunity = async () => {
      if (
        !newOpportunity.name ||
        !newOpportunity.field ||
        !newOpportunity.operator ||
        !newOpportunity.value ||
        !newOpportunity.criticallyHigh ||
        !newOpportunity.criticallyLow
      ) {
        alert('Please fill out all fields!');
        return;
      }
  
      setLoading(true);
      try {
        // Calculate matching users immediately
        const matchingUsers = usersData.filter((user) => {
          const userValue = getUserFieldValue(user, newOpportunity.field);
          if (userValue === undefined || userValue === null) return false;
  
          const filterValue = parseFloat(newOpportunity.value);
          const userNumberValue = parseFloat(userValue);
  
          switch (newOpportunity.operator) {
            case 'Equals':
              return userNumberValue === filterValue;
            case 'Greater Than':
              return userNumberValue > filterValue;
            case 'Less Than':
              return userNumberValue < filterValue;
            default:
              return false;
          }
        });
  
        // Include matchingUsersCount in the report data
        const reportData = {
          ...newOpportunity,
          matchingUsersCount: matchingUsers.length,
          createdAt: new Date(),
        };
  
        const reportsRef = collection(db, 'engagementResources');
        const docRef = await addDoc(reportsRef, reportData);
        const createdReport = { id: docRef.id, ...reportData };
        setOpportunityReports((prev) => [...prev, createdReport]);
  
        // Update counts
        setOpportunityCounts((prev) => ({
          ...prev,
          [createdReport.name]: createdReport.matchingUsersCount,
        }));
  
        setShowOpportunityModal(false);
  
        // Reset new opportunity data
        setNewOpportunity({
          name: '',
          field: '',
          operator: '',
          value: '',
          criticallyHigh: '',
          criticallyLow: '',
        });
      } catch (error) {
        console.error('Error creating opportunity report:', error);
        alert('Failed to create opportunity report. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    // Helper function to get nested user field value
    const getUserFieldValue = (user, fieldPath) => {
      const fields = fieldPath.split('.');
      let value = user;
      for (let field of fields) {
        if (value[field] !== undefined) {
          value = value[field];
        } else {
          return undefined;
        }
      }
      return value;
    };
  
    // Available fields for Opportunity Reports (from usersData)
    const availableFields = useMemo(() => {
      const fieldsSet = new Set();
  
      usersData.forEach((user) => {
        Object.keys(user).forEach((key) => {
          if (typeof user[key] !== 'object') {
            fieldsSet.add(key);
          } else if (typeof user[key] === 'object' && user[key] !== null) {
            // Handle subfields
            Object.keys(user[key]).forEach((subKey) => {
              fieldsSet.add(`${key}.${subKey}`);
            });
          }
        });
      });
  
      return Array.from(fieldsSet);
    }, [usersData]);

  return (
    <div className="flex min-h-screen ">
      {/* Sidebar with fixed position */}
      <CustomSidebar />
      <div
          className="fixed ml-8 top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 z-10 w-full"
        >
          <div className="flex justify-center">
            <ul
              className="flex space-x-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
              style={{ listStyleType: 'none' }}
            >
              {/* Resources Tab */}
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'resources'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="resources-tab"
                  type="button"
                  role="tab"
                  aria-controls="resources"
                  aria-selected={activeTab === 'resources'}
                  onClick={() => setActiveTab('resources')}
                >
                  Resources
                </button>
              </li>
              {/* Engagement Opportunities Tab */}
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'engagement'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="engagement-tab"
                  type="button"
                  role="tab"
                  aria-controls="engagement"
                  aria-selected={activeTab === 'engagement'}
                  onClick={() => setActiveTab('engagement')}
                >
                  Engagement Opportunities
                </button>
              </li>
            </ul>
          </div>
          <div className="border-b border-gray-200 dark:border-gray-700"></div>
        </div>

      {activeTab === "resources" && (
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Header Section */}
        <div className="text-center mb-4 mt-12">
          <h1 className="text-3xl font-bold mb-2 mt-3">Resources</h1>
          <p className="text-base text-gray-600 mb-2">
            Welcome to the University Resources Page, your go-to hub for
            accessing all available services on campus. Whether you’re seeking
            academic support, career guidance, or mental health resources, this
            page brings everything together in one place.
          </p>
        </div>

        {/* Search and Create Buttons */}
        <div className="mb-2 flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4">
          {/* Search Input */}
          <TextInput
            type="text"
            placeholder="Search resources..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full sm:w-1/3"
            icon={FaSearch}
          />

          {/* Create New Resource Button */}
          <button
            type="button"
            onClick={handleToggleCreateForm}
            className="text-blue-700 hover:!text-white border border-blue-700 hover:!bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:!text-white dark:hover:!bg-blue-500 dark:focus:ring-blue-800"
          >
            {showCreateForm && !isEditing ? 'Cancel' : 'New Resource'}
          </button>
        </div>

        {/* Resource Cards Display */}
        <div className="flex-1 overflow-y-auto">
          <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4">
            {filteredResources.length > 0 ? (
              filteredResources.map((resource) => (
                <div
                  key={resource.id}
                  className="bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full"
                >
                  <img
                    className="rounded-t-lg h-40 w-full object-cover"
                    src={resource.image || 'https://via.placeholder.com/300x200'}
                    alt={resource.name}
                  />
                  <div className="p-4 flex flex-col flex-grow">
                    <h5 className="mb-1 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                      {resource.name}
                    </h5>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400 flex-grow overflow-y-auto">
                      {resource.description}
                    </p>

                    {/* Footer Section */}
                    <div className="mt-auto">
                      {/* Visit Resource Link */}
                      {resource.link && (
                        <a
                          href={resource.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline mb-2"
                        >
                          Visit Resource
                        </a>
                      )}
                      {/* Department Info */}
                      <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                        Department: {resource.department}
                      </p>

                      {/* Centered Button Group */}
                      <div className="flex justify-center px-2">
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                          <button
                            type="button"
                            onClick={() => handleRecommend(resource)}
                            className="px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:!bg-gray-100 hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 dark:focus:ring-blue-500 dark:focus:!text-white"
                          >
                            Recommend
                          </button>
                          <button
                            type="button"
                            onClick={() => handleEditResource(resource)}
                            className="px-3 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:!bg-gray-100 hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 dark:focus:ring-blue-500 dark:focus:!text-white"
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDeleteResource(resource.id)}
                            className="px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:!bg-gray-100 hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 dark:focus:ring-blue-500 dark:focus:!text-white"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-500">
                {resources.length === 0 ? (
                  <Spinner aria-label="Loading" />
                ) : (
                  'No resources found.'
                )}
              </div>
            )}
          </div>
        </div>

        {/* Create/Edit Resource Form Modal */}
        {showCreateForm && (
          <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full overflow-y-auto overflow-x-hidden flex bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={closeModal}
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div
                className="relative bg-white rounded-lg shadow dark:bg-gray-700"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {isEditing ? 'Edit Resource' : 'Create New Resource'}
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={closeModal}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <form className="p-4 md:p-5">
                  <div className="grid gap-4 mb-4">
                    <div className="col-span-2">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Resource Name
                      </label>
                      <TextInput
                        name="name"
                        placeholder="Enter resource name"
                        value={newResource.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Description
                      </label>
                      <TextInput
                        name="description"
                        placeholder="Enter description"
                        value={newResource.description}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="image"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Image URL
                      </label>
                      <TextInput
                        name="image"
                        placeholder="Enter image URL"
                        value={newResource.image}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="department"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Department/School
                      </label>
                      <TextInput
                        name="department"
                        placeholder="Enter department or school"
                        value={newResource.department}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="link"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Resource Link
                      </label>
                      <TextInput
                        name="link"
                        placeholder="Enter resource link"
                        value={newResource.link}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={
                        isEditing
                          ? handleSaveEditedResource
                          : handleCreateResource
                      }
                    >
                      {isEditing ? 'Save Changes' : 'Create Resource'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {/* Assign Users Modal */}
        {showAssignModal && selectedResource && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={closeModal}
          >
            <div className="w-full mx-4 p-6 overflow-auto max-h-full">
              <div
                className="overflow-auto shadow-md"
                onClick={(e) => e.stopPropagation()}
              >
                <StudentListing
                  students={students}
                  onRecommendSuccess={handleRecommendSuccess}
                  selectedResource={selectedResource}
                  adjustment="Resources"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
        )}

        {/* Loading Indicator */}
        {(assigning || loading) && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col items-center">
              <Spinner aria-label="Processing" />
              <p className="mt-4 text-center">
                {assigning
                  ? 'Recommending resources to selected students...'
                  : 'Processing...'}
              </p>
            </div>
          </div>
        )}
      </div>
      )}
      {activeTab === 'engagement' && (
            <div
              className="p-1 rounded-lg ml-10 dark:bg-gray-800"
              id="engagement"
              role="tabpanel"
              aria-labelledby="engagement-tab"
            >
              {/* Engagement Opportunities Content */}
              <div className="text-center mb-4">
                <h1 className="text-3xl font-bold mb-2 mt-3">
                  Engagement Opportunities
                </h1>
                <p className="text-base text-gray-600 mb-2">
                  Create opportunity reports to identify students matching specific criteria.
                </p>
              </div>

              {/* Add Opportunity Report Button */}
              <div className="flex justify-center mb-4">
                <button
                  type="button"
                  onClick={() => setShowOpportunityModal(true)}
                  className="flex items-center space-x-2 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  <FaPlus />
                  <span>Add Opportunity Report</span>
                </button>
              </div>

              {/* Opportunity Reports Display */}
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {opportunityReports.map((report) => {
                const count = report.matchingUsersCount || 0;
                const isCriticalLow = count < parseInt(report.criticallyLow);
                const isCriticalHigh = count > parseInt(report.criticallyHigh);

                // Assuming you have a date or timestamp data that tracks the last update
                const lastUpdated = report.lastUpdated || "Unknown"; // Adjust based on your data

                return (
                  <div
                    key={report.id}
                    className="bg-white border border-gray-200 rounded-lg shadow-sm p-4 flex flex-col"
                  >
                    <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900">
                      {report.name}
                    </h5>

                    <div className="mt-auto flex items-center justify-between">
                      
                      {/* Critical Status Indicator */}
                      {(isCriticalLow || isCriticalHigh) && (
                        <FaExclamationTriangle
                          className="text-red-500"
                          title={
                            isCriticalLow
                              ? 'Below Critically Low Threshold'
                              : 'Above Critically High Threshold'
                          }
                        />
                      )}
                    </div>

                    {/* Show dynamic count and last updated */}
                    <div className="mt-2 text-xs text-gray-500">
                      <p>Updated: {lastUpdated}</p>
                      <p>Day-by-day Count: {count}</p> {/* Optional: Could show how this count is changing */}
                    </div>
                  </div>
                );
              })}
              </div>
            </div>
          )}
          {showOpportunityModal && (
          <Modal
            show={showOpportunityModal}
            size="md"
            popup={true}
            onClose={closeModal}
          >
            <Modal.Header />
            <Modal.Body>
              <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                  Create Opportunity Report
                </h3>
                <div>
                  <Label htmlFor="name">Report Name</Label>
                  <TextInput
                    name="name"
                    placeholder="Enter report name"
                    value={newOpportunity.name}
                    onChange={(e) =>
                      setNewOpportunity({ ...newOpportunity, name: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="field">Select Field</Label>
                  <Select
                    id="field"
                    value={newOpportunity.field}
                    onChange={(e) =>
                      setNewOpportunity({ ...newOpportunity, field: e.target.value })
                    }
                  >
                    <option value="">Select Field</option>
                    {availableFields.map((field, index) => (
                      <option key={index} value={field}>
                        {field.replace('.', ' > ')}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <Label htmlFor="operator">Operator</Label>
                  <Select
                    id="operator"
                    value={newOpportunity.operator}
                    onChange={(e) =>
                      setNewOpportunity({ ...newOpportunity, operator: e.target.value })
                    }
                  >
                    <option value="">Select Operator</option>
                    <option value="Equals">Equals</option>
                    <option value="Greater Than">Greater Than</option>
                    <option value="Less Than">Less Than</option>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="value">Value</Label>
                  <TextInput
                    name="value"
                    placeholder="Enter value"
                    value={newOpportunity.value}
                    onChange={(e) =>
                      setNewOpportunity({ ...newOpportunity, value: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="criticallyHigh">Critically High Threshold</Label>
                  <TextInput
                    name="criticallyHigh"
                    placeholder="Enter critically high threshold"
                    value={newOpportunity.criticallyHigh}
                    onChange={(e) =>
                      setNewOpportunity({ ...newOpportunity, criticallyHigh: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="criticallyLow">Critically Low Threshold</Label>
                  <TextInput
                    name="criticallyLow"
                    placeholder="Enter critically low threshold"
                    value={newOpportunity.criticallyLow}
                    onChange={(e) =>
                      setNewOpportunity({ ...newOpportunity, criticallyLow: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <Button color="gray" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button onClick={handleCreateOpportunity}>
                    Create
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

    </div>
  );
};

export default ResourcePage;