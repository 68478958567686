import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const EnrollmentLineGraph = ({ data, courseNumber }) => {
  if (!data) {
    return null;
  }

  // Group data by year and sum the enrollment across all terms for that year
  const yearlyData = {};
  data.forEach(item => {
    const term = item.term;
    const year = term.slice(-2); // Last two digits as the year, e.g. '24' in 'FA24'
    if (!yearlyData[year]) {
      yearlyData[year] = 0;
    }
    yearlyData[year] += item.Total;
  });

  // Convert yearlyData object into arrays for labels and values
  const sortedYears = Object.keys(yearlyData).sort((a, b) => parseInt(a) - parseInt(b));
  const labels = sortedYears.map(y => `20${y}`);
  const values = labels.map((_, i) => yearlyData[sortedYears[i]]);

  // Find and double the 2025 value
  const index2025 = labels.indexOf("2025");

  if (index2025 > -1) {
    // Find the first digit in courseNumber
    const match = courseNumber.match(/\d/);
    if (match) {
      const firstDigit = parseInt(match[0], 10);
      if (firstDigit < 5) {
        values[index2025] *= 1.8;
      } else {
        values[index2025] *= 2;
      }
    } else {
      // If no digit found, default to the original multiplier
      values[index2025] *= 1.8;
    }
  }


  // We'll also need the index of 2024 to create the dotted segment between 2024 and 2025
  const index2024 = labels.indexOf("2024");

  // Create the chart data structure
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Total Enrollment per Year",
        data: values,
        fill: false,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBackgroundColor: "#8884d8",
        pointBorderColor: "#8884d8",
        tension: 0.4, // Curve the line slightly
        // Use segment styling to make the line segment from 2024 to 2025 dotted
        segment: {
          borderDash: (ctx) => {
            if (index2024 > -1 && index2025 > -1) {
              if (ctx.p0DataIndex === index2024 && ctx.p1DataIndex === index2025) {
                return [5, 5];
              }
            }
            return undefined;
          },
        },
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
          },
        },
      },
      legend: {
        position: 'top',
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          generateLabels: () => [
            {
              text: 'Total Enrollment',
              fillStyle: '#8884d8',
              pointStyle: 'circle',
              strokeStyle: '#8884d8',
            }
          ],
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
        title: {
          display: true,
          text: 'Year',
        },
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Enrollment',
        },
      },
    },
  };

  return (
    <div
      className="w-full flex items-center justify-center"
      style={{ width: "900px", height: "350px" }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

export default EnrollmentLineGraph;
