import React, { useState, useEffect, useMemo } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import { FaDownload } from 'react-icons/fa';
import {
  Button,
  TextInput,
  Spinner,
  Label,
  Select,
  Checkbox,
  Modal,
} from 'flowbite-react';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  getDoc,
  doc,
  deleteDoc,
  query,
  orderBy,
} from 'firebase/firestore';
import { saveAs } from 'file-saver';
import MultiSelect from './MultiSelect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
} from 'react-table';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

// Import the components
import ScheduleReportModal from './ScheduleReportModal';
import ReportTemplates from './ReportTemplates';
import ExportReportModal from './ExportReportModal';

// Import lodash's isEqual for deep comparison
import isEqual from 'lodash/isEqual';

// Utility function to format Firestore Timestamps
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  }
  return 'N/A';
};

// Column Filter Component for React Table
const ColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <input
    value={filterValue || ''}
    onChange={(e) => setFilter(e.target.value || undefined)}
    placeholder={`Search...`}
    className="mt-1 p-1 border rounded w-full"
  />
);

// ReportTable Component using React Table
const ReportTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of rows, use page for pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200"
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps()
                  )}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  key={column.id}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                  <div>
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200"
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-700"
                    key={cell.column.id}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination mt-4 flex justify-between items-center">
        <div>
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            size="xs"
            color="light"
            className="mr-1"
          >
            {'<<'}
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            size="xs"
            color="light"
            className="mr-1"
          >
            {'<'}
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            size="xs"
            color="light"
            className="mr-1"
          >
            {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            size="xs"
            color="light"
          >
            {'>>'}
          </Button>
        </div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="border p-1 rounded"
        >
          {[10, 20, 30, 40, 50].map((pageSizeOption) => (
            <option key={pageSizeOption} value={pageSizeOption}>
              Show {pageSizeOption}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
};

const Reports = () => {
  const [reportModel, setReportModel] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [subFieldsMap, setSubFieldsMap] = useState({});
  const [fields, setFields] = useState([]);
  const [availableSubFieldsMap, setAvailableSubFieldsMap] = useState({});
  const [reportData, setReportData] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [reportName, setReportName] = useState('');
  const [exportFormat, setExportFormat] = useState('csv');
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [scheduleName, setScheduleName] = useState('');
  const [scheduleFrequency, setScheduleFrequency] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [aggregations, setAggregations] = useState({});
  const [visualizationsEnabled, setVisualizationsEnabled] = useState(false);
  const [templateDataToLoad, setTemplateDataToLoad] = useState(null);
  const [storedReports, setStoredReports] = useState([]);
  const [selectedStoredReportId, setSelectedStoredReportId] = useState(null);
  const [scheduledReportId, setScheduledReportId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const location = useLocation();
  const db = getFirestore();

  const reportModelOptions = [
    'advisors',
    'availability',
    'groups',
    'resources',
    'studentAdvisors',
    'users',
  ];

  // Fetch Templates on Component Mount
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, 'reportTemplates');
        const querySnapshot = await getDocs(templatesRef);
        const fetchedTemplates = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTemplates(fetchedTemplates);
      } catch (error) {
        console.error('Error fetching templates:', error);
        toast.error('Failed to fetch templates.');
      }
    };

    fetchTemplates();
  }, [db]);

  // Load report based on navigation state
  useEffect(() => {
    if (location.state) {
      const { scheduledReportId: navScheduledReportId, reportId: navReportId } =
        location.state;
      setScheduledReportId(navScheduledReportId);
      setReportId(navReportId);
    }
  }, [location.state]);

  // Fetch stored reports when a scheduled report is selected
  useEffect(() => {
    if (scheduledReportId) {
      const fetchStoredReports = async () => {
        try {
          const reportsRef = collection(
            db,
            'scheduledReports',
            scheduledReportId,
            'reports'
          );
          const reportsQuery = query(reportsRef, orderBy('createdAt', 'desc'));
          const querySnapshot = await getDocs(reportsQuery);
          const reportsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStoredReports(reportsData);

          // If a specific reportId is provided, load that report
          if (reportId) {
            loadStoredReport(reportId);
          }
        } catch (error) {
          console.error('Error fetching stored reports:', error);
          toast.error('Failed to fetch stored reports.');
        }
      };

      fetchStoredReports();
    }
  }, [scheduledReportId, reportId]);

  // Function to load a stored report
  const loadStoredReport = async (reportIdToLoad) => {
    if (!scheduledReportId || !reportIdToLoad) return;

    try {
      const reportDocRef = doc(
        db,
        'scheduledReports',
        scheduledReportId,
        'reports',
        reportIdToLoad
      );
      const reportDoc = await getDoc(reportDocRef);
      if (reportDoc.exists()) {
        const reportDataFromDB = reportDoc.data();
        setReportColumns(reportDataFromDB.columns);
        setReportData(reportDataFromDB.data);
        computeAggregations(reportDataFromDB.data, reportDataFromDB.columns);
        toast.success('Report loaded successfully!');
      } else {
        toast.error('Report not found.');
      }
    } catch (error) {
      console.error('Error loading stored report:', error);
      toast.error('Failed to load report.');
    }
  };

  // Function to handle stored report selection
  const handleStoredReportSelection = (e) => {
    const reportIdToLoad = e.target.value;
    setSelectedStoredReportId(reportIdToLoad);
    loadStoredReport(reportIdToLoad);
  };

  // Function to fetch fields from the selected collection
  const getFieldsFromCollection = async (collectionName) => {
    setLoadingFields(true);
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);

      const allFields = new Set();

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        Object.keys(docData).forEach((field) => {
          allFields.add(field);
        });
      });

      setFields(Array.from(allFields));
    } catch (error) {
      console.error('Error fetching fields from Firestore:', error);
      toast.error('Failed to fetch fields. Please try again.');
    } finally {
      setLoadingFields(false);
    }
  };

  // Function to fetch sub-fields for a given field
  const getSubFields = async (field, collectionName) => {
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);

      const allSubFields = new Set();

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (
          docData[field] &&
          typeof docData[field] === 'object' &&
          !Array.isArray(docData[field])
        ) {
          Object.keys(docData[field]).forEach((subField) => {
            allSubFields.add(subField);
          });
        }
      });

      setAvailableSubFieldsMap((prev) => ({
        ...prev,
        [field]: Array.from(allSubFields),
      }));
    } catch (error) {
      console.error('Error fetching sub-fields:', error);
      toast.error('Failed to fetch sub-fields. Please try again.');
    }
  };

  // Handle selection of fields
  const handleFieldSelection = (newSelectedFields) => {
    setSelectedFields(newSelectedFields);

    // Fetch sub-fields for all selected fields
    newSelectedFields.forEach((field) => {
      if (!subFieldsMap[field] && !availableSubFieldsMap[field]) {
        getSubFields(field, reportModel);
      }
    });

    // Remove sub-fields for unselected fields
    setSubFieldsMap((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((field) => {
        if (!newSelectedFields.includes(field)) {
          delete updated[field];
        }
      });
      return updated;
    });
  };

  // Handle selection of sub-fields
  const handleSubFieldSelection = (field, newSelectedSubFields) => {
    setSubFieldsMap({
      ...subFieldsMap,
      [field]: newSelectedSubFields,
    });
  };

  // Fetch fields when the report model changes
  useEffect(() => {
    if (reportModel) {
      getFieldsFromCollection(reportModel);
    }
  }, [reportModel]);

  // Handle report model change
  const handleReportModelChange = (newModel) => {
    setReportModel(newModel);
    // Reset selections when changing the model manually
    setSelectedFields([]);
    setAvailableSubFieldsMap({});
    setSubFieldsMap({});
    setReportData([]);
    setReportColumns([]);
    setAggregations({});
    setVisualizationsEnabled(false);
    setSelectedTemplateId(null);
  };

  // Load template data after fields are loaded
  useEffect(() => {
    if (templateDataToLoad && fields.length > 0) {
      // Ensure that fields include selectedFields
      const newFields = new Set([...fields, ...templateDataToLoad.selectedFields]);
      setFields(Array.from(newFields));

      setSelectedFields(templateDataToLoad.selectedFields);
      setSubFieldsMap(templateDataToLoad.subFieldsMap);

      // Fetch sub-fields for selected fields
      templateDataToLoad.selectedFields.forEach((field) => {
        if (!availableSubFieldsMap[field]) {
          getSubFields(field, templateDataToLoad.reportModel);
        }
      });
      setTemplateDataToLoad(null); // Reset
    }
  }, [fields, templateDataToLoad, availableSubFieldsMap]);

  // Function to generate the report
  const handleCreateReport = async () => {
    if (!reportModel) {
      toast.error('Please select a report model.');
      return;
    }
    if (selectedFields.length === 0) {
      toast.error('Please select at least one field.');
      return;
    }

    setGeneratingReport(true);
    try {
      const collectionRef = collection(db, reportModel);
      const querySnapshot = await getDocs(collectionRef);

      const reportRows = [];
      const columns = [];

      selectedFields.forEach((field) => {
        if (subFieldsMap[field] && subFieldsMap[field].length > 0) {
          subFieldsMap[field].forEach((subField) => {
            const uniqueColumn = `${field}_${subField}`; // Ensure uniqueness
            columns.push(uniqueColumn);
          });
        } else {
          columns.push(field);
        }
      });

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const row = {};

        selectedFields.forEach((field) => {
          if (
            subFieldsMap[field] &&
            subFieldsMap[field].length > 0 &&
            typeof docData[field] === 'object' &&
            docData[field] !== null &&
            !Array.isArray(docData[field])
          ) {
            subFieldsMap[field].forEach((subField) => {
              const value = docData[field][subField];
              let formattedValue = 'N/A'; // Default value

              if (Array.isArray(value)) {
                // Join array elements into a comma-separated string
                formattedValue = value.join(', ') || 'N/A';
              } else if (value && typeof value === 'object') {
                if ('seconds' in value && 'nanoseconds' in value) {
                  // Firestore Timestamp
                  formattedValue = formatTimestamp(value);
                } else {
                  // Other objects - customize as needed
                  formattedValue = JSON.stringify(value) || 'N/A';
                }
              } else if (typeof value === 'string') {
                formattedValue = value.trim() !== '' ? value : 'N/A';
              } else if (value !== null && value !== undefined) {
                // For numbers and other types
                formattedValue = value;
              }

              row[`${field}_${subField}`] = formattedValue;
            });
          } else {
            const value = docData[field];
            let formattedValue = 'N/A'; // Default value

            if (Array.isArray(value)) {
              // Join array elements into a comma-separated string
              formattedValue = value.join(', ') || 'N/A';
            } else if (value && typeof value === 'object') {
              if ('seconds' in value && 'nanoseconds' in value) {
                // Firestore Timestamp
                formattedValue = formatTimestamp(value);
              } else {
                // Other objects - customize as needed
                formattedValue = JSON.stringify(value) || 'N/A';
              }
            } else if (typeof value === 'string') {
              formattedValue = value.trim() !== '' ? value : 'N/A';
            } else if (value !== null && value !== undefined) {
              // For numbers and other types
              formattedValue = value;
            }

            row[field] = formattedValue;
          }
        });

        reportRows.push(row);
      });

      setReportColumns(columns);
      setReportData(reportRows);
      computeAggregations(reportRows, columns);
      toast.success('Report generated successfully!');
      // Enable visualization options after report is generated
      setVisualizationsEnabled(false);
    } catch (error) {
      console.error('Error generating report:', error);
      toast.error('Failed to generate report. Please try again.');
    } finally {
      setGeneratingReport(false);
    }
  };

  // Function to compute aggregations
  const computeAggregations = (data, columns) => {
    const aggregations = {};

    columns.forEach((col) => {
      const numericData = data
        .map((row) => parseFloat(row[col]))
        .filter((val) => !isNaN(val));
      if (numericData.length > 0) {
        aggregations[col] = {
          count: numericData.length,
          sum: numericData.reduce((a, b) => a + b, 0),
          average:
            numericData.reduce((a, b) => a + b, 0) / numericData.length,
        };
      }
    });

    setAggregations(aggregations);
  };

  // Export Report Function
  const exportReport = () => {
    if (!reportName.trim()) {
      toast.error('Please enter a valid report name.');
      return;
    }

    switch (exportFormat) {
      case 'csv':
        exportToCSV();
        break;
      case 'excel':
        exportToExcel();
        break;
      case 'pdf':
        exportToPDF();
        break;
      default:
        exportToCSV();
    }
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = reportColumns;
    const rows = reportData.map((row) =>
      headers.map((header) => `"${row[header]}"`)
    );

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((e) => e.join(','))].join('\n');

    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    saveAs(blob, `${reportName.trim()}.csv`);
    setExportModalOpen(false);
    setReportName('');
    toast.success('Report exported successfully as CSV!');
  };

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, `${reportName.trim()}.xlsx`);
    setExportModalOpen(false);
    setReportName('');
    toast.success('Report exported successfully as Excel!');
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text(reportName.trim(), 14, 16);
    const tableColumn = reportColumns.map((col) =>
      col.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())
    );
    const tableRows = reportData.map((row) =>
      reportColumns.map((col) => row[col])
    );

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [22, 160, 133] },
    });

    doc.save(`${reportName.trim()}.pdf`);
    setExportModalOpen(false);
    setReportName('');
    toast.success('Report exported successfully as PDF!');
  };

  // Save Template Function
  const saveTemplate = async () => {
    if (!templateName.trim()) {
      toast.error('Please enter a valid template name.');
      return;
    }

    const templateData = {
      name: templateName.trim(),
      reportModel,
      selectedFields,
      subFieldsMap,
      createdAt: new Date(),
    };

    try {
      await addDoc(collection(db, 'reportTemplates'), templateData);
      toast.success('Template saved successfully!');
      setTemplateName('');
      // Refresh templates list
      const templatesRef = collection(db, 'reportTemplates');
      const querySnapshot = await getDocs(templatesRef);
      const fetchedTemplates = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Failed to save template.');
    }
  };

  // Load Template Function
  const loadTemplate = async (templateId) => {
    if (!templateId) return;
    try {
      const templateDoc = await getDoc(doc(db, 'reportTemplates', templateId));
      if (templateDoc.exists()) {
        const templateData = templateDoc.data();
        setReportModel(templateData.reportModel);
        setTemplateDataToLoad(templateData);
        setSelectedTemplateId(templateId); // Keep track of the selected template
        toast.success(`Template "${templateData.name}" loaded.`);
      } else {
        toast.error('Template not found.');
      }
    } catch (error) {
      console.error('Error loading template:', error);
      toast.error('Failed to load template.');
    }
  };

  // Delete Template Function
  const deleteTemplate = async (templateId) => {
    if (!window.confirm('Are you sure you want to delete this template?'))
      return;

    try {
      await deleteDoc(doc(db, 'reportTemplates', templateId));
      toast.success('Template deleted successfully!');
      // Refresh templates list
      const templatesRef = collection(db, 'reportTemplates');
      const querySnapshot = await getDocs(templatesRef);
      const fetchedTemplates = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(fetchedTemplates);
      setSelectedTemplateId(null); // Reset selected template
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('Failed to delete template.');
    }
  };

  // Schedule Report Function
  const scheduleReport = async () => {
    if (!scheduleName.trim() || !scheduleFrequency || !scheduleTime) {
      toast.error('Please fill in all scheduling fields.');
      return;
    }

    const scheduleData = {
      name: scheduleName.trim(),
      frequency: scheduleFrequency,
      time: scheduleTime,
      reportModel,
      selectedFields,
      subFieldsMap,
      createdAt: new Date(),
      // Assuming user authentication is implemented and user ID is available
      // userId: currentUser.id,
    };

    try {
      const docRef = await addDoc(collection(db, 'scheduledReports'), scheduleData);
      toast.success('Report scheduled successfully!');
      setScheduleName('');
      setScheduleFrequency('');
      setScheduleTime('');
      setScheduleModalOpen(false);
      // Optionally, navigate to the scheduled report or refresh the list
    } catch (error) {
      console.error('Error scheduling report:', error);
      toast.error('Failed to schedule report.');
    }
  };

  // Check if the current configuration is already saved as a template
  const isConfigurationSaved = useMemo(() => {
    return templates.some((template) => {
      return (
        template.reportModel === reportModel &&
        isEqual(template.selectedFields, selectedFields) &&
        isEqual(template.subFieldsMap, subFieldsMap)
      );
    });
  }, [templates, reportModel, selectedFields, subFieldsMap]);

  // Determine whether to show the "Save Template" section
  const showSaveTemplate = reportData.length > 0 && !isConfigurationSaved;

  // Memoize columns and data for React Table
  const columns = useMemo(
    () =>
      reportColumns.map((col) => ({
        Header: col.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
        accessor: col,
        Filter: ColumnFilter,
      })),
    [reportColumns]
  );

  const data = useMemo(() => reportData, [reportData]);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <CustomSidebar />
      {/* Main content */}
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Header */}
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2 mt-3">Reports</h1>
          <p className="text-base text-gray-600 mb-2">
            Generate custom reports by selecting a model, fields, and sub-fields.
            View and export the data in a structured table with visual insights.
          </p>
        </div>
        {/* Main Layout */}
        <div className="flex flex-col md:flex-row">
          {/* Sidebar Filters */}
          <div className="md:w-1/4 md:pr-6 mb-6 md:mb-0">
            <div className="bg-white p-4 rounded-lg shadow">
              {/* Report Model Selection */}
              <div className="mb-4">
                <Label
                  htmlFor="reportModel"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Report Model
                </Label>
                <select
                  id="reportModel"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={reportModel}
                  onChange={(e) => handleReportModelChange(e.target.value)}
                >
                  <option value="">Select Model</option>
                  {reportModelOptions.map((collection) => (
                    <option key={collection} value={collection}>
                      {collection.charAt(0).toUpperCase() + collection.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select Fields */}
              <div className="mb-2">
                <Label className="block text-gray-700 font-semibold mb-2">
                  Select Fields
                </Label>
                {loadingFields ? (
                  <div className="flex items-center">
                    <Spinner size="sm" />
                    <span className="ml-2">Loading fields...</span>
                  </div>
                ) : (
                  <MultiSelect
                    suggestions={fields}
                    value={selectedFields}
                    onChange={handleFieldSelection}
                    placeholder="Type to search..."
                    required={false}
                    unselectable={[]}
                  />
                )}
              </div>

              {/* Select Sub-Fields */}
              {selectedFields.map(
                (field) =>
                  availableSubFieldsMap[field] &&
                  availableSubFieldsMap[field].length > 0 && (
                    <div className="mb-2" key={field}>
                      <Label className="block text-gray-700 font-semibold mb-2">
                        Select Sub-Fields for{' '}
                        <span className="text-blue-600">{field}</span>
                      </Label>
                      <MultiSelect
                        suggestions={availableSubFieldsMap[field]}
                        value={subFieldsMap[field] || []}
                        onChange={(newSubFields) =>
                          handleSubFieldSelection(field, newSubFields)
                        }
                        placeholder="Type to search..."
                        required={false}
                        unselectable={[]}
                      />
                    </div>
                  )
              )}

              {/* Generate Report Button */}
              <div>
                <Button
                  onClick={handleCreateReport}
                  disabled={generatingReport}
                  className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-small rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 w-full"
                >
                  {generatingReport ? (
                    <>
                      <Spinner size="sm" light={true} className="mr-2" />
                      Generating...
                    </>
                  ) : (
                    'Generate Report'
                  )}
                </Button>
              </div>

              {/* Stored Reports */}
              {storedReports.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-xl font-semibold mb-2">Stored Reports</h2>
                  <Select
                    onChange={handleStoredReportSelection}
                    value={selectedStoredReportId || ''}
                    className="mt-2 mb-2"
                  >
                    <option value="" disabled>
                      Select a Report
                    </option>
                    {storedReports.map((report) => (
                      <option key={report.id} value={report.id}>
                        {format(
                          report.createdAt.toDate(),
                          'yyyy-MM-dd HH:mm:ss'
                        )}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
          </div>

          {/* Main Content */}
          <div className="md:w-3/4">
            <div className="bg-white p-4 rounded-lg shadow overflow-x-auto">
              {reportData.length > 0 ? (
                <div>
                  {/* Summary Statistics */}
                  {Object.keys(aggregations).length > 0 && (
                    <div className="bg-gray-100 p-4 rounded-lg mb-6">
                      <h3 className="text-xl font-medium mb-2">
                        Summary Statistics
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {Object.keys(aggregations).map((col, index) => (
                          <div
                            key={index}
                            className="p-4 bg-white rounded shadow"
                          >
                            <h4 className="text-lg font-semibold mb-1">
                              {col
                                .replace(/_/g, ' ')
                                .replace(/\b\w/g, (l) => l.toUpperCase())}
                            </h4>
                            <p>Count: {aggregations[col].count}</p>
                            <p>
                              Average: {aggregations[col].average.toFixed(2)}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Report Table */}
                  <ReportTable columns={columns} data={data} />

                  {/* Action Buttons */}
                  <div className="mt-4 flex flex-wrap gap-2">
                    <Button
                      onClick={() => setExportModalOpen(true)}
                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                    >
                      Export Report
                    </Button>
                    <Button
                      onClick={() => setScheduleModalOpen(true)}
                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                    >
                      Schedule Report
                    </Button>
                  </div>

                  {/* Toggle Visualizations */}
                  <div className="mt-6 flex items-center">
                    <Checkbox
                      id="toggleVisualizations"
                      checked={visualizationsEnabled}
                      onChange={(e) =>
                        setVisualizationsEnabled(e.target.checked)
                      }
                    />
                    <Label htmlFor="toggleVisualizations" className="ml-2">
                      Add Visualizations
                    </Label>
                  </div>

                  {/* Visualizations */}
                  {visualizationsEnabled && (
                    <div className="mt-6">
                      <h2 className="text-2xl font-semibold mb-4">
                        Visualizations
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Bar Chart */}
                        <div className="bg-white p-4 rounded-lg shadow">
                          <h3 className="text-xl font-medium mb-2">
                            Bar Chart
                          </h3>
                          <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={reportData}>
                              {reportColumns.map((col, index) => {
                                const isNumeric = reportData.every((row) =>
                                  !isNaN(parseFloat(row[col]))
                                );
                                return isNumeric ? (
                                  <Bar
                                    key={col}
                                    dataKey={col}
                                    fill="#8884d8"
                                  />
                                ) : null;
                              })}
                              <XAxis dataKey={reportColumns[0]} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>

                        {/* Line Chart */}
                        <div className="bg-white p-4 rounded-lg shadow">
                          <h3 className="text-xl font-medium mb-2">
                            Line Chart
                          </h3>
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={reportData}>
                              {reportColumns.map((col, index) => {
                                const isNumeric = reportData.every((row) =>
                                  !isNaN(parseFloat(row[col]))
                                );
                                return isNumeric ? (
                                  <Line
                                    key={col}
                                    type="monotone"
                                    dataKey={col}
                                    stroke="#82ca9d"
                                  />
                                ) : null;
                              })}
                              <XAxis dataKey={reportColumns[0]} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex justify-center items-center h-64">
                  {generatingReport ? (
                    <div className="flex items-center">
                      <Spinner aria-label="Generating report" />
                      <span className="ml-2">Generating report...</span>
                    </div>
                  ) : (
                    <p className="text-gray-500">
                      No report generated. Select fields and click "Generate
                      Report".
                    </p>
                  )}
                </div>
              )}
            </div>

            {/* Report Templates */}
            <div className="mt-6">
              <ReportTemplates
                reportData={reportData}
                templates={templates}
                templateName={templateName}
                setTemplateName={setTemplateName}
                saveTemplate={saveTemplate}
                loadTemplate={loadTemplate}
                deleteTemplate={deleteTemplate}
                templateDataToLoad={templateDataToLoad}
                selectedTemplateId={selectedTemplateId} // Pass the selected template ID
                setSelectedTemplateId={setSelectedTemplateId} // Pass the setter function
                showSaveTemplate={showSaveTemplate} // Pass the flag
              />
            </div>
          </div>
        </div>

        {/* Export Report Modal */}
        {exportModalOpen && (
          <ExportReportModal
            exportModalOpen={exportModalOpen}
            setExportModalOpen={setExportModalOpen}
            reportName={reportName}
            setReportName={setReportName}
            exportFormat={exportFormat}
            setExportFormat={setExportFormat}
            exportReport={exportReport}
          />
        )}

        {/* Schedule Report Modal */}
        {scheduleModalOpen && (
          <ScheduleReportModal
            scheduleModalOpen={scheduleModalOpen}
            setScheduleModalOpen={setScheduleModalOpen}
            scheduleName={scheduleName}
            setScheduleName={setScheduleName}
            scheduleFrequency={scheduleFrequency}
            setScheduleFrequency={setScheduleFrequency}
            scheduleTime={scheduleTime}
            setScheduleTime={setScheduleTime}
            scheduleReport={scheduleReport}
          />
        )}
      </div>
    </div>
  );
};

export default Reports;
