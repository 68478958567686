import React, { useState } from "react";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";

const MajorRequirementsRendering = ({ courseList, plannedCourses }) => {
  // Utility to check if a course is in plannedCourses
  const findPlannedCourse = (possibilities, usedCourses) => {
    for (const year in plannedCourses) {
      const semesters = plannedCourses[year];
      for (const semester in semesters) {
        const courses = semesters[semester];
        for (const plannedCourse of courses) {
          if (
            possibilities.includes(plannedCourse.courseInfo_courseNumber) &&
            !usedCourses.has(plannedCourse.courseInfo_courseNumber)
          ) {
            return plannedCourse.courseInfo_courseNumber;
          }
        }
      }
    }
    return null;
  };

  // Set to track already used courses
  const usedCourses = new Set();

  // State to manage expanded course view
  const [expandedCourses, setExpandedCourses] = useState({});

  // Toggle expanded state of a course
  const toggleExpand = (courseNumber) => {
    setExpandedCourses((prevState) => ({
      ...prevState,
      [courseNumber]: !prevState[courseNumber],
    }));
  };

  return courseList.length > 0 ? (
    <ul className="space-y-2 ml-4 pl-4 border-l-2 border-gray-400">
      {courseList.map((course, index) => {
        const possibilities = course.Possibilities
          ? course.Possibilities.split(",").map((p) => p.trim())
          : [];
        const matchedCourse = findPlannedCourse(possibilities, usedCourses);

        // Mark this course as used if it was matched
        if (matchedCourse) {
          usedCourses.add(matchedCourse);
        }

        const isExpanded = expandedCourses[course.courseInfo_courseNumber];

        return (
          <li
            key={index}
            className={`p-2 border rounded-lg shadow-sm hover:shadow-md transition-shadow flex flex-col ${
              index === 0 ? "mt-2" : ""
            }`}
          >
            {/* Left side: Requirements */}
            <div
              onClick={() => toggleExpand(course.courseInfo_courseNumber)}
              className="cursor-pointer flex justify-between items-center"
            >
              <h3 className="text-md font-semibold text-left">
                {course.Requirements}
              </h3>
              <div className="flex items-center space-x-2">
                {matchedCourse ? (
                  <>
                    <span className="text-green-600 font-medium">
                      Satisfied with {matchedCourse}
                    </span>
                    <BsCheckSquareFill className="text-green-600 h-5 w-5" />
                  </>
                ) : (
                  <>
                    <span className="text-red-600 font-medium">Not In Plan</span>
                    <BsSquare className="text-gray-400 h-5 w-5" />
                  </>
                )}
              </div>
            </div>

            {/* Expanded Possibilities Section */}
            {isExpanded && (
              <div className="mt-2.5">
                <div className="flex flex-wrap gap-1.5">
                  <p className="font-medium text-md">Possibilities:</p>
                  {possibilities.map((possibility, i) => (
                    <span
                      key={i}
                      className={`text-xs font-medium px-2.5 py-0.5 rounded border ${
                        possibility === matchedCourse
                          ? "bg-green-100 text-green-800 border-green-500"
                          : "bg-gray-100 text-gray-800 border-gray-500"
                      }`}
                    >
                      {possibility}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  ) : (
    <p className="text-gray-500">No requirements available.</p>
  );
};

export default MajorRequirementsRendering;
