import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase"; // Make sure to import your firebase configuration
import EnrollmentBarChart from "./Charts/EnrollmentBarChart"; // Import the bar chart component
import EnrollmentLineGraph from "./Charts/EnrollmentLineGraph";
import ThirdChart from "./Charts/ThirdChart";
import InsightsComponent from "./InsightsComponent";

const CourseAnalyticsModal = ({ isOpen, onClose, classData }) => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [currentChart, setCurrentChart] = useState("bar");
  const [nextChart, setNextChart] = useState(null); 
  const [transitionDirection, setTransitionDirection] = useState(""); 

  // Fetch analytics data from Firestore when the modal opens
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (isOpen && classData && classData.courseInfo_courseNumber) {
        try {
          const courseRef = db.collection("courses").doc(classData.courseInfo_courseNumber);
          const docSnapshot = await courseRef.get();

          if (docSnapshot.exists) {
            setAnalyticsData(docSnapshot.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching Firestore data:", error);
        }
      }
    };

    fetchAnalyticsData();
  }, [isOpen, classData]);

  if (!isOpen || !analyticsData) return null;

  // const chartOrder = ["bar", "line", "third"];

  const chartOrder = ["bar", "line"];

  const toggleChart = (direction) => {
    setTransitionDirection(direction);

    // Determine the next chart
    const currentIndex = chartOrder.indexOf(currentChart);
    const nextIndex =
      direction === "right"
        ? (currentIndex + 1) % chartOrder.length
        : (currentIndex - 1 + chartOrder.length) % chartOrder.length;
    const newChart = chartOrder[nextIndex];
    setNextChart(newChart);

    // After animation completes, finalize the switch
    setTimeout(() => {
      setCurrentChart(newChart);
      setNextChart(null);
      setTransitionDirection("");
    }, 300); // match the CSS animation duration
  };

  const getChartComponent = (chartKey) => {
    switch (chartKey) {
      case "bar":
        return <EnrollmentBarChart data={analyticsData?.enrollmentData} />;
      case "line":
        return <EnrollmentLineGraph data={analyticsData?.enrollmentData} courseNumber = {analyticsData?.courseNumber} />;
      case "third":
        return <ThirdChart data={analyticsData?.enrollmentData} />;
      default:
        return null;
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="rounded-md bg-white p-4 w-full max-w-5xl max-h-[95vh]"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">
            {analyticsData?.courseNumber} - {analyticsData?.courseName} Analytics
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path 
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        </div>

        {/* Summary Card Section */}
        <InsightsComponent analyticsData={analyticsData}/>

        {/* Chart Section */}
        <div className="w-full h-180 p-2.5 rounded-md border-2 border-gray-200 mb-3 flex flex-col items-center relative">
          <h3 className="text-lg font-semibold text-gray-700 w-full mb-2">
            Enrollment + Forecasted Demand
          </h3>
          {/* Left Arrow */}
          <button
            onClick={() => toggleChart("left")}
            className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-700 bg-gray-50 hover:bg-blue-300 font-medium rounded-lg text-sm p-2.5 inline-flex items-center z-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path 
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2" 
                d="M15 19l-7-7 7-7" 
              />
            </svg>
          </button>

          {/* Right Arrow */}
          <button
            onClick={() => toggleChart("right")}
            className="
              absolute right-4 top-1/2 -translate-y-1/2 text-gray-600 bg-gray-50 hover:bg-blue-300 font-medium rounded-lg text-sm p-2.5 inline-flex items-center z-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path 
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2" 
                d="M9 5l7 7-7 7" 
              />
            </svg>
          </button>
          {/* Chart Container */}
          <div className="relative w-full h-[350px] overflow-hidden">
            {/* Current Chart (will slide out) */}
            <div
              className={`absolute inset-0 flex transition-transform duration-300 ${
                transitionDirection === "right"
                  ? "translate-x-full"    // exit to the right
                  : transitionDirection === "left"
                  ? "-translate-x-full"   // exit to the left
                  : "translate-x-0"
              }`}
            >
              {getChartComponent(currentChart)}
            </div>

            {/* Next Chart (will slide in) */}
            {nextChart && (
              <div
                className={`absolute inset-0 flex transition-transform duration-300 ${
                  transitionDirection === "right"
                    ? "-translate-x-full" // enter from the left
                    : transitionDirection === "left"
                    ? "translate-x-full"  // enter from the right
                    : "translate-x-0"
                }`}
              >
                {getChartComponent(nextChart)}
              </div>
            )}
          </div>

          {/* Dots Navigation */}
          <div className="flex justify-center mt-2">
            {chartOrder.map((chart) => (
              <span
                key={chart}
                className={`h-2 w-2 mx-1 rounded-full ${
                  currentChart === chart ? "bg-blue-500" : "bg-gray-300"
                }`}
              ></span>
            ))}
          </div>
        </div>

        {/* Trends Section */}
        <div className="grid grid-cols-3 gap-4">
          {['fall', 'spring', 'summer'].map((key) => {
            const keyLower = key.toLowerCase();

            return (
              <div key={key} className="px-3 py-2 border-2 border-gray-200 rounded-md">
                <h3 className="text-lg font-semibold capitalize mb-2">
                  {key} Enrollment Trends
                </h3>
                <div className="space-y-3 text-md mb-2">
                  <div className="flex items-center">
                    <span
                      className={`${
                        analyticsData?.trends?.[key]?.historical >= 0
                          ? "bg-green-100 text-green-500"
                          : "bg-red-100 text-red-500"
                      } font-bold rounded-md text-sm w-[45px] py-0.5 text-center mr-2`}
                    >
                      {analyticsData?.trends?.[key]?.historical >= 0 ? "+" : ""}
                      {analyticsData?.trends?.[key]?.historical}%
                    </span>
                    <p className="text-gray-600 text-sm">
                      Historical Trend (
                      {keyLower === "fall"
                        ? "FA21 - FA23"
                        : keyLower === "spring"
                        ? "SP21 - SP24"
                        : keyLower === "summer"
                        ? "SU21 - SU24"
                        : "Unknown"})
                    </p>
                  </div>
                  <div className="flex items-center">
                    <span
                      className={`${
                        analyticsData?.trends?.[key]?.forecasted >= 0
                          ? "bg-green-100 text-green-500"
                          : "bg-red-100 text-red-500"
                      } font-bold rounded-md text-sm w-[45px] py-0.5 text-center mr-2`}
                    >
                      {analyticsData?.trends?.[key]?.forecasted >= 0 ? "+" : ""}
                      {analyticsData?.trends?.[key]?.forecasted}%
                    </span>
                    <p className="text-gray-600 text-sm">
                      Forecasted Trend (
                      {keyLower === "fall"
                        ? "FA24"
                        : keyLower === "spring"
                        ? "SP25"
                        : keyLower === "summer"
                        ? "SU25"
                        : "Unknown"})
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourseAnalyticsModal;
