import React from 'react';

const InsightsComponent = ({ analyticsData }) => {
  const fall = analyticsData?.trends?.fall?.historical ?? 0;
  const spring = analyticsData?.trends?.spring?.historical ?? 0;
  const summer = analyticsData?.trends?.summer?.historical ?? 0;
  const totalWeight = 1 + 1 + 0.2; // 2.2

  const weightedSum = (fall * 1) + (spring * 1) + (summer * 0.2);
  const historicalAverage = weightedSum / totalWeight;

  let historicalTrend = "";
  if (historicalAverage >= 3) historicalTrend = "trending up";
  else if (historicalAverage >= 0) historicalTrend = "trending slightly up";
  else if (historicalAverage <= -3) historicalTrend = "trending down";
  else historicalTrend = "trending slightly down";

  const forecastedSemesters = [
    { key: "Fall", year: 2024 },
    { key: "Spring", year: 2025 },
    { key: "Summer", year: 2025 },
  ];

  const forecastedTrends = forecastedSemesters
    .map(({ key, year }) => {
      const forecastedValue =
        analyticsData?.trends?.[key.toLowerCase()]?.forecasted ?? null;
      if (forecastedValue === 0 || forecastedValue === null) {
        return null;
      }
      return {
        key,
        year,
        trend: forecastedValue > 0 ? "increase" : "decrease",
      };
    })
    .filter(Boolean);

  const groupedTrends = forecastedTrends.reduce((acc, { trend, key, year }) => {
    acc[trend] = acc[trend] || [];
    acc[trend].push(`${key} ${year}`);
    return acc;
  }, {});

  const increaseTrends = groupedTrends["increase"]?.join(" and ") ?? null;
  const decreaseTrends = groupedTrends["decrease"]?.join(" and ") ?? null;

  return (
    <div className="w-full p-2.5 mb-3 rounded-md border-2 border-gray-200">
      <p className="text-gray-800">
        <span className="font-semibold">Overall enrollment:</span>{" "}
        <>
          Enrollment has been{" "}
          <span
            className={`${
              historicalAverage > 0 ? "text-green-600" : "text-red-600"
            } font-bold`}
          >
            {historicalTrend}
          </span>{" "}
          over the past 3 years.
          {increaseTrends || decreaseTrends ? (
            <>
              {" "}Predicted to{" "}
              {increaseTrends && (
                <>
                  <span className="text-green-600 font-bold">increase</span>{" "}
                  in {increaseTrends}
                </>
              )}
              {increaseTrends && decreaseTrends && " but "}
              {decreaseTrends && (
                <>
                  <span className="text-red-600 font-bold">decrease</span>{" "}
                  in {decreaseTrends}
                </>
              )}
              .
            </>
          ) : (
            "."
          )}
        </>
      </p>
    </div>
  );
};

export default InsightsComponent;
