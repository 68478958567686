import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomSidebar from '../Sidebar/Sidebar';
import { Button } from 'flowbite-react';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import Charts from './Charts';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';

const Dashboard = () => {
  const navigate = useNavigate();
  const [opportunityReports, setOpportunityReports] = useState([]);
  const [scheduledReports, setScheduledReports] = useState([]);
  const db = getFirestore();

  // Fetch engagement opportunities and scheduled reports from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch engagement opportunities
        const opportunitiesSnapshot = await getDocs(
          collection(db, 'engagementResources')
        );
        const opportunities = opportunitiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOpportunityReports(opportunities);

        // Hardcoded scheduled reports data
        const scheduledReportsData = [
          {
            id: 'report1',
            name: 'Student Planning Progress',
            latestReport: {
              id: 'latest1',
              createdAt: new Date(),
              summaryStatistics: {
                Credits: {
                  count: 200,
                  sum: null,
                },
              },
            },
            previousReport: {
              id: 'prev1',
              createdAt: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
              summaryStatistics: {
                Credits: {
                  count: 180,
                  sum: null,
                },
              },
            },
          },
          {
            id: 'report2',
            name: 'Weekly Grades Report',
            latestReport: {
              id: 'latest2',
              createdAt: new Date(),
              summaryStatistics: {
                GPA: {
                  count: null,
                  sum: 3.6,
                },
              },
            },
            previousReport: {
              id: 'prev2',
              createdAt: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
              summaryStatistics: {
                GPA: {
                  count: null,
                  sum: 3.4,
                },
              },
            },
          },
          {
            id: 'report3',
            name: 'Daily Activity Report',
            latestReport: {
              id: 'latest3',
              createdAt: new Date(),
              summaryStatistics: {
                Uses: {
                  count: 50,
                  sum: null,
                },
              },
            },
            previousReport: {
              id: 'prev3',
              createdAt: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
              summaryStatistics: {
                Uses: {
                  count: 45,
                  sum: null,
                },
              },
            },
          },
        ];

        setScheduledReports(scheduledReportsData);

        // Commented out the original code for fetching scheduled reports
        /*
        // Fetch scheduled reports
        const scheduledReportsSnapshot = await getDocs(
          collection(db, 'scheduledReports')
        );
        const scheduledReportsData = [];

        for (const scheduledReportDoc of scheduledReportsSnapshot.docs) {
          const scheduledReport = {
            id: scheduledReportDoc.id,
            ...scheduledReportDoc.data(),
          };

          // Fetch the two latest reports for this scheduled report
          const reportsRef = collection(
            db,
            'scheduledReports',
            scheduledReport.id,
            'reports'
          );
          const latestReportsQuery = query(
            reportsRef,
            orderBy('createdAt', 'desc'),
            limit(2)
          );
          const latestReportsSnapshot = await getDocs(latestReportsQuery);

          const reports = latestReportsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (reports.length > 0) {
            scheduledReport.latestReport = reports[0];
            if (reports.length > 1) {
              scheduledReport.previousReport = reports[1];

              // Compute percentage changes
              if (
                scheduledReport.latestReport.summaryStatistics &&
                scheduledReport.previousReport.summaryStatistics
              ) {
                scheduledReport.percentageChanges = computePercentageChange(
                  scheduledReport.latestReport.summaryStatistics,
                  scheduledReport.previousReport.summaryStatistics
                );
              }
            }
          }

          scheduledReportsData.push(scheduledReport);
        }

        setScheduledReports(scheduledReportsData);
        */
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Failed to fetch data.');
      }
    };

    fetchData();
  }, [db]);

  const handleViewReport = (scheduledReportId, reportId) => {
    navigate('/reports', {
      state: { scheduledReportId, reportId },
    });
  };

  function computePercentageChange(latestSummary, previousSummary) {
    const percentageChanges = {};

    for (const key in latestSummary) {
      if (previousSummary[key]) {
        const latestValue =
          latestSummary[key].sum || latestSummary[key].count || 0;
        const previousValue =
          previousSummary[key].sum || previousSummary[key].count || 0;

        if (previousValue !== 0) {
          const change = ((latestValue - previousValue) / previousValue) * 100;
          percentageChanges[key] = change.toFixed(2);
        } else {
          percentageChanges[key] = null; // Cannot compute percentage change
        }
      } else {
        percentageChanges[key] = null; // No previous value to compare
      }
    }

    return percentageChanges;
  }

  return (
    <div className="flex w-full">
      <CustomSidebar />
      <div className="w-full p-4 ml-20">
        <div className="w-full mt-10">
          {/* Engagement Opportunities Section */}
          <h2 className="w-full text-2xl font-semibold mb-4">
            Engagement Opportunities
          </h2>
          <div className="overflow-x-auto whitespace-nowrap">
            <div className="flex space-x-4">
              {opportunityReports.map((opportunity) => {
                const count = opportunity.matchingUsersCount || 0;
                const criticallyLow = parseInt(opportunity.criticallyLow, 10);
                const criticallyHigh = parseInt(opportunity.criticallyHigh, 10);
                const previousCount = opportunity.previousCount || 0; // Assuming previous count is fetched
                const countChange = count - previousCount;

                return (
                  <div
                    key={opportunity.id}
                    className="bg-white mb-10 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md p-2 w-1/4 flex flex-col justify-between relative"
                  >
                    {/* Title */}
                    <h5 className="text-xs font-medium text-gray-600 dark:text-gray-400 truncate mb-2">
                      {opportunity.name}
                    </h5>

                    {/* Count and Change Indicator */}
                    <div className="flex items-center text-sm text-gray-900 dark:text-white mt-auto">
                      <p className="text-sm font-bold">{count}</p>
                      <span className="ml-1 text-gray-500">students</span>

                      {/* Count Change Indicator */}
                      {countChange !== 0 && (
                        <span
                          className={`ml-2 text-xs font-medium ${
                            countChange > 0 ? 'text-green-500' : 'text-red-500'
                          }`}
                        >
                          {countChange > 0 ? '↑' : '↓'} {Math.abs(countChange)}
                        </span>
                      )}
                    </div>

                    {/* Warning Symbol */}
                    {(count < criticallyLow || count > criticallyHigh) && (
                      <span
                        className="absolute bottom-2 right-2 text-red-500 text-sm"
                        title={
                          count < criticallyLow
                            ? 'Below Critical Threshold'
                            : 'Above Critical Threshold'
                        }
                      >
                        ⚠️
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Charts Section */}
          <Charts />

          {/* Scheduled Reports Section */}
          <div className="mt-3">
            <h2 className="text-2xl font-semibold mb-4">Scheduled Reports</h2>
            {scheduledReports.length > 0 ? (
              <div className="flex space-x-4 overflow-x-auto">
                {scheduledReports.map((report) => {
                  const lastGeneratedAt = report.latestReport?.createdAt;
                  const timeSinceLastReport = lastGeneratedAt
                    ? formatDistanceToNow(lastGeneratedAt, { addSuffix: true })
                    : 'No reports yet';

                  // Determine the key column to display
                  const summaryKeys = report.latestReport?.summaryStatistics
                    ? Object.keys(report.latestReport.summaryStatistics)
                    : [];
                  const primaryKey = summaryKeys[0]; // For simplicity, pick the first key
                  const latestStats =
                    report.latestReport?.summaryStatistics[primaryKey];
                  const previousStats =
                    report.previousReport?.summaryStatistics[primaryKey];

                  // Get the value to display (e.g., count or sum)
                  const latestValue = latestStats
                    ? latestStats.sum || latestStats.count || 0
                    : 0;
                  const previousValue = previousStats
                    ? previousStats.sum || previousStats.count || 0
                    : 0;

                  // Compute percentage change
                  let percentageChange = null;
                  if (previousValue !== 0) {
                    percentageChange =
                      ((latestValue - previousValue) / previousValue) * 100;
                  }

                  // Format percentage change
                  const formattedPercentageChange =
                    percentageChange !== null
                      ? percentageChange.toFixed(2)
                      : null;

                  return (
                    <div
                      key={report.id}
                      className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md p-4 w-1/4 flex flex-col h-full relative"
                    >
                      {/* Title */}
                      <h5 className="text-sm font-medium text-gray-600 dark:text-gray-400 truncate mb-2">
                        {report.name}
                      </h5>

                      {/* Value and Change Indicator */}
                      <div className="flex items-center text-sm text-gray-900 dark:text-white mt-auto">
                        <p className="text-lg font-bold">{latestValue}</p>
                        <span className="ml-1 text-gray-500">
                          {primaryKey}
                        </span>

                        {/* Percentage Change Indicator */}
                        {formattedPercentageChange !== null && (
                          <span
                            className={`ml-2 text-xs font-medium ${
                              percentageChange >= 0
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                          >
                            {percentageChange >= 0 ? '↑' : '↓'}{' '}
                            {Math.abs(formattedPercentageChange)}%
                          </span>
                        )}
                      </div>

                      {/* Badge indicating last report generation time */}
                      {lastGeneratedAt && (
                        <span className="text-gray-500 text-xs mt-1">
                          {timeSinceLastReport}
                        </span>
                      )}

                      {/* View Report Button */}
                      <div className="mt-auto">
                        <Button
                          size="sm"
                          onClick={() =>
                            handleViewReport(
                              report.id,
                              report.latestReport?.id
                            )
                          }
                          className="w-full text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1 mt-2 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                        >
                          View Report
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center py-10">
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  You have no scheduled reports.
                </p>
                <Button
                  className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                  onClick={() => navigate('/reports')}
                >
                  Create a Recurring Report
                </Button>
              </div>
            )}
          </div>

          {/* Other Dashboard Sections */}
          {/* You can include other sections here as needed */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
