// GPAGraph.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const GPAGraph = ({ gpaData }) => {
    const data = {
        labels: gpaData.map((dataPoint) => dataPoint.month),
        datasets: [
            {
                label: 'GPA',
                data: gpaData.map((dataPoint) => dataPoint.gpa),
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
                tension: 0.4, // Smooth curves
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'GPA Progression',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 4.0,
                ticks: {
                    stepSize: 0.5,
                },
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default GPAGraph;
