import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import ErrorDisplay from './ErrorDisplay'; // Ensure this import is correct
import { debounce } from '@mui/material';

// Function to calculate background gradient based on score
const getBackgroundGradient = (score) => {
  const green = [144, 238, 144]; // RGB for light green
  const yellow = [255, 255, 224]; // RGB for light yellow
  const red = [255, 182, 193]; // RGB for light red

  let startColor, endColor;
  let percentage;

  if (score >= 50) {
    startColor = yellow;
    endColor = green;
    percentage = (score - 50) / 50;
  } else {
    startColor = red;
    endColor = yellow;
    percentage = score / 50;
  }

  const interpolateColor = (start, end, factor) =>
    start.map((startComponent, index) =>
      Math.round(startComponent + factor * (end[index] - startComponent))
    );

  const [r, g, b] = interpolateColor(startColor, endColor, percentage);

  return `rgb(${r}, ${g}, ${b})`;
};

// Component to display autofill information
const AutofillDisplay = React.memo(({ course, position }) => {
  if (!course || !position) return null;

  const dynamicStyles = {
    position: 'fixed',
    top: position.top - 50, // Adjust to position lower on the page
    left: position.left + position.width / 2, // Center horizontally
    transform: 'translate(-50%, 0%)', // Center without moving above
    zIndex: 1000, // High z-index to ensure it's on top
    backgroundColor: getBackgroundGradient(course.recScore), // Set background color based on score
  };

  return ReactDOM.createPortal(
    <div
      style={{
        ...dynamicStyles,
        boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)', // Adds a subtle blur effect
      }}
      className="autofill-display p-2 border border-gray-200 rounded-lg shadow-2xl"
    >
      <h3 className="text-sm font-semibold mb-2 text-center">
        Autofill Info for {course.courseInfo_courseNumber}
      </h3>
      <p className="text-xs text-left">
        {course.autofillType === 'Elective'
          ? `This course was automatically selected as an elective: ${course.electiveFullName}`
          : course.autofillType === 'Requirement'
          ? `This course was automatically selected to fulfill the requirement: ${course.requirementName}`
          : course.autofillType === 'Major Course'
          ? 'Required Major Course'
          : ''}
      </p>
      <p className="text-xs text-left mt-1">
        Recommendation Score: {course.recScore}/100
      </p>
    </div>,
    document.getElementById('portal-root') // Render in portal root
  );
});

// Component to display duplicate course information
const DuplicateCourseDisplay = React.memo(({ course, position }) => {
  if (!course || !position) return null;

  const dynamicStyles = {
    position: 'fixed',
    top: position.top - 50, // Adjust position as needed
    left: position.left + position.width / 2, // Center horizontally
    transform: 'translate(-50%, 0%)',
    zIndex: 1000, // High z-index to ensure it's on top
    backgroundColor: '#FFCCCC', // Light red background
  };

  return ReactDOM.createPortal(
    <div
      style={{
        ...dynamicStyles,
        boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
      }}
      className="duplicate-course-display p-2 border border-gray-200 rounded-lg shadow-2xl"
    >
      <h3 className="text-sm font-semibold mb-2 text-center">
        Duplicate Course
      </h3>
      <p className="text-xs text-left">
        The course {course.courseInfo_courseNumber} is already scheduled in another semester.
      </p>
    </div>,
    document.getElementById('portal-root') // Render in portal root
  );
});

// Main Component
const CourseRendering = ({
  finalizedCourses,
  onRemove,
  onUpdateCourse,
  semester,
  isCompleted,
  onEvaluate,
  viewMode,
  isDragging,
  onAddPrerequisiteCourse, // New prop
  yearNumber, // New prop
  hideIcons,
}) => {
  const { fetchedPrereqData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [prerequisites, setPrerequisites] = useState([]);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]); // State for the last clicked course's prerequisites
  const [selectedPosition, setSelectedPosition] = useState(null); // State for the position of the clicked course
  const [selectedCourse, setSelectedCourse] = useState(null); // New state for the selected course

  const [selectedAutofillCourse, setSelectedAutofillCourse] = useState(null); // State for the last clicked autofill course
  const [selectedAutofillPosition, setSelectedAutofillPosition] = useState(null); // State for the position of the clicked autofill icon

  const [selectedDuplicateCourse, setSelectedDuplicateCourse] = useState(null);
  const [selectedDuplicatePosition, setSelectedDuplicatePosition] = useState(null);

  // Handler to set duplicate course and position
  const handleDuplicateClick = useCallback(
    (course, position) => {
      if (
        selectedDuplicateCourse === course &&
        JSON.stringify(position) === JSON.stringify(selectedDuplicatePosition)
      ) {
        setSelectedDuplicateCourse(null);
        setSelectedDuplicatePosition(null);
      } else {
        setSelectedDuplicateCourse(course);
        setSelectedDuplicatePosition(position);
      }
    },
    [selectedDuplicateCourse, selectedDuplicatePosition]
  );

  // Close the DuplicateCourseDisplay when clicking outside
  useEffect(() => {
    const handleClickOutsideDuplicate = (event) => {
      if (
        !event.target.closest('.duplicate-course-display') &&
        !event.target.closest('.duplicate-icon')
      ) {
        if (selectedDuplicateCourse || selectedDuplicatePosition) {
          setSelectedDuplicateCourse(null);
          setSelectedDuplicatePosition(null);
        }
      }
    };

    if (selectedDuplicateCourse) {
      window.addEventListener('click', handleClickOutsideDuplicate);
    }

    return () => {
      window.removeEventListener('click', handleClickOutsideDuplicate);
    };
  }, [selectedDuplicateCourse, selectedDuplicatePosition]);

  useEffect(() => {
    const fetchPrerequisites = async () => {
      try {
        const fetchedPrerequisites = fetchedPrereqData;
        setPrerequisites(fetchedPrerequisites || []);
      } catch (error) {
        console.error('Error fetching prerequisites:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrerequisites();
  }, [fetchedPrereqData]);

  // Handler to set prerequisites, position, and selected course
  const handlePrereqClick = useCallback(
    (course, prereqs, position) => {
      // If the same course is clicked again, toggle the display
      if (
        JSON.stringify(prereqs) === JSON.stringify(selectedPrerequisites) &&
        JSON.stringify(position) === JSON.stringify(selectedPosition) &&
        selectedCourse === course
      ) {
        setSelectedPrerequisites([]);
        setSelectedPosition(null);
        setSelectedCourse(null);
      } else {
        setSelectedPrerequisites(prereqs);
        setSelectedPosition(position);
        setSelectedCourse(course);
      }
    },
    [selectedPrerequisites, selectedPosition, selectedCourse]
  );

  // Handler to set autofill course and position
  const handleAutofillClick = useCallback(
    (course, position) => {
      // If the same course is clicked again, toggle the display
      if (
        selectedAutofillCourse === course &&
        JSON.stringify(position) === JSON.stringify(selectedAutofillPosition)
      ) {
        setSelectedAutofillCourse(null);
        setSelectedAutofillPosition(null);
      } else {
        setSelectedAutofillCourse(course);
        setSelectedAutofillPosition(position);
      }
    },
    [selectedAutofillCourse, selectedAutofillPosition]
  );

  // Close the AutofillDisplay when clicking outside
  useEffect(() => {
    const handleClickOutsideAutofill = (event) => {
      if (
        !event.target.closest('.autofill-display') &&
        !event.target.closest('.autofill-icon')
      ) {
        if (selectedAutofillCourse || selectedAutofillPosition) {
          setSelectedAutofillCourse(null);
          setSelectedAutofillPosition(null);
        }
      }
    };

    if (selectedAutofillCourse) {
      window.addEventListener('click', handleClickOutsideAutofill);
    }

    return () => {
      window.removeEventListener('click', handleClickOutsideAutofill);
    };
  }, [selectedAutofillCourse, selectedAutofillPosition]);

  // Function to close the PrerequisiteDisplay
  const onCloseErrorDisplay = () => {
    setSelectedPrerequisites([]);
    setSelectedPosition(null);
    setSelectedCourse(null);
  };

  // Inner Component for each course
  const FinalizedCourse = React.memo(
    ({
      course,
      onRemove,
      isAutoFill,
      isCompleted,
      onEvaluate,
      semester,
      viewMode,
      isDragging,
      onPrereqClick, // New prop
      onAutofillClick, // New prop
      onDuplicateClick, // New prop
      onAddPrerequisiteCourse,
    }) => {
      const [isHovered, setIsHovered] = useState(false);
      const [showTooltip, setShowTooltip] = useState(false);
      const courseRef = useRef(null); // Ref to the course card

      if (course.courseInfo_courseNumber != "Placeholder") {
        course.hasError = !course.Offering.includes(semester) || !course.isPrereqMet;
      }
      
      const debounceHover = useCallback(
        debounce((value) => setIsHovered(value), 75),
        []
      );
      
      const handleHover = (value) => {
        debounceHover(value);
      };

      const handleIconClick = useCallback(
        (e) => {
          e.stopPropagation();
          if (courseRef.current) {
            const rect = courseRef.current.getBoundingClientRect();
            const position = {
              top: rect.top + window.scrollY,
              left: rect.left + window.scrollX,
              width: rect.width,
            };
            onPrereqClick(course, course.Prerequisites, position);
          }
        },
        [onPrereqClick, course]
      );

      const handleDuplicateIconClick = useCallback(
        (e) => {
          e.stopPropagation();
          if (courseRef.current) {
            const rect = courseRef.current.getBoundingClientRect();
            const position = {
              top: rect.top + window.scrollY,
              left: rect.left + window.scrollX,
              width: rect.width,
            };
            onDuplicateClick(course, position);
          }
        },
        [onDuplicateClick, course]
      );

      const handleAutofillIconClick = useCallback(
        (e) => {
          e.stopPropagation();
          if (courseRef.current) {
            const rect = courseRef.current.getBoundingClientRect();

            const position = {
              top: rect.top + window.scrollY + (viewMode === 'horizontal' ? -30 : 0), // Adjust top position slightly if horizontal
              left: rect.left + window.scrollX,
              width: rect.width,
            };

            onAutofillClick(course, position);
          }
        },
        [onAutofillClick, course, viewMode]
      );

      const VerticalView = () => (
        <div
          className="flex flex-col justify-between"
          style={{ padding: '2px 6px', height: 'auto' }}
        >
          <div className="flex items-center" style={{ marginBottom: '2px' }}>
            <p className="text-xs font-bold">{course.courseInfo_courseNumber}</p>
            <span className="credits ml-auto flex justify-end bg-blue-100 text-blue-800 text-[9px] font-medium px-1 py-0.25 rounded-full dark:bg-blue-900 dark:text-blue-300">
              {course.Credits} Credits
            </span>
          </div>
          <div style={{ marginTop: '2px' }}>
            <p className="text-xs text-left">{course.courseInfo_courseName}</p>
          </div>
        </div>
      );

      const HorizontalView = () => (
        <div className="course-info">
          <div className="course-column">
            <p className="text-md font-bold">{course.courseInfo_courseNumber}</p>
            <p>
              <span className="bg-blue-100 text-blue-800 text-[10px] font-medium px-1.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
                {course.Credits} credits
              </span>
            </p>
          </div>
          <div className="course-column mt-2">
            <p className={isAutoFill ? 'mt-2' : ''}>
              {course.courseInfo_courseName}
            </p>
          </div>
        </div>
      );

      return (
        <div
          ref={courseRef} // Attach ref to the course card
          className="relative p-2 border rounded shadow-sm overflow-hidden course-card w-full text-xs"
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          style={{
            backgroundColor: '#F9FAFB',
            minHeight: viewMode === 'horizontal' ? '100px' : 'auto', // Adjust height for horizontal view
          }}
        >
          {/* Error Icon */}
          {viewMode === 'vertical' && course.hasError && (
            <button
              onClick={handleIconClick}
              className={`requisite-button absolute ${
                course.isAutofill ? 'right-6' : 'right-1.5'
              } ${
                course.isAutofill ? 'bottom-2' : 'bottom-1.5'
              } text-orange-500 hover:text-orange-700 cursor-pointer bg-transparent border-none p-0`}
            >
              <FaExclamationTriangle />
            </button>
          )}

          {viewMode === 'horizontal' && course.hasError && (
            <button
              onClick={handleIconClick}
              className={`requisite-button absolute ${
                course.isAutofill ? 'right-6' : 'right-1.5'
              } ${
                course.isAutofill ? 'top-2' : 'top-1.5'
              } text-orange-500 hover:text-orange-700 cursor-pointer bg-transparent border-none p-0`}
            >
              <FaExclamationTriangle />
            </button>
          )}

          {/* Autofill Icon */}
          {viewMode === 'horizontal' && isAutoFill && (
            <button
              onClick={handleAutofillIconClick}
              className="autofill-icon remove-button absolute top-1.5 right-1.5 text-purple-500 hover:text-purple-800 cursor-pointer bg-transparent border-none p-0"
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </button>
          )}

          {viewMode === 'vertical' && isAutoFill && (
            <button
              onClick={handleAutofillIconClick}
              className="autofill-icon remove-button absolute bottom-1.5 right-1.5 text-purple-500 hover:text-purple-800 cursor-pointer bg-transparent border-none p-0"
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </button>
          )}

          {/* Conditional Rendering of Icons */}
          {!hideIcons && (
            <>
              {viewMode === 'vertical' && course.hasError && (
                <button
                  onClick={handleIconClick}
                  className={`requisite-button absolute ${
                    course.isAutofill ? 'right-6' : 'right-1.5'
                  } ${
                    course.isAutofill ? 'bottom-2' : 'bottom-1.5'
                  } text-orange-500 hover:text-orange-700 cursor-pointer bg-transparent border-none p-0`}
                >
                  <FaExclamationTriangle />
                </button>
              )}

              {viewMode === 'horizontal' && course.hasError && (
                <button
                  onClick={handleIconClick}
                  className={`requisite-button absolute ${
                    course.isAutofill ? 'right-6' : 'right-1.5'
                  } ${
                    course.isAutofill ? 'top-2' : 'top-1.5'
                  } text-orange-500 hover:text-orange-700 cursor-pointer bg-transparent border-none p-0`}
                >
                  <FaExclamationTriangle />
                </button>
              )}

              {/* Autofill Icon */}
              {viewMode === 'horizontal' && isAutoFill && (
                <button
                  onClick={handleAutofillIconClick}
                  className="autofill-icon remove-button absolute top-1.5 right-1.5 text-purple-500 hover:text-purple-800 cursor-pointer bg-transparent border-none p-0"
                >
                  <FontAwesomeIcon icon={faWandMagicSparkles} />
                </button>
              )}

              {viewMode === 'vertical' && isAutoFill && (
                <button
                  onClick={handleAutofillIconClick}
                  className="autofill-icon remove-button absolute bottom-1.5 right-1.5 text-purple-500 hover:text-purple-800 cursor-pointer bg-transparent border-none p-0"
                >
                  <FontAwesomeIcon icon={faWandMagicSparkles} />
                </button>
              )}
            </>
          )}

          {/* Course Info */}
          {viewMode === 'vertical' ? <VerticalView /> : <HorizontalView />}

          {/* Hover Buttons */}
          {viewMode === 'horizontal' && isHovered && !isDragging && (
            <div className="absolute bottom-1 right-2 flex space-x-2">
              {isCompleted ? (
                <button
                  className="remove-button text-xs text-blue-500 hover:text-black"
                  onClick={() => onEvaluate(course)}
                >
                  Evaluate
                </button>
              ) : (
                <button
                  className="remove-button text-xs text-red-500 hover:text-black"
                  onClick={() => onRemove(course)}
                >
                  Remove
                </button>
              )}
            </div>
          )}

          {viewMode === 'vertical' && isHovered && !isDragging && (
            <div className="absolute top-0.5 right-0.5 flex space-x-2">
              {isCompleted ? (
                <div className="relative inline-block">
                  <button
                    className="remove-button text-xs text-blue-500 hover:text-black flex items-center justify-center p-0.5 rounded-full hover:bg-gray-200 relative"
                    onClick={() => onEvaluate(course)}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              ) : (
                <div className="relative inline-block">
                  <button
                    className="remove-button text-xs text-red-500 hover:text-black flex items-center justify-center p-0.5 rounded-full hover:bg-gray-200 relative"
                    onClick={() => onRemove(course)}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  );

  if (loading) {
    // return <p>Loading courses...</p>;
  }

  if (!finalizedCourses || finalizedCourses.length === 0) {
    return <p>No finalized courses available</p>;
  }

  return (
    <div style={{ textAlign: 'center', marginTop: 'auto', position: 'relative' }}>
      {finalizedCourses.map((course, index) => (
        <FinalizedCourse
          key={course.id || index}
          course={course}
          onRemove={onRemove}
          onUpdateCourse={onUpdateCourse}
          isAutoFill={course.isAutofill}
          isPrereqMet={course.isPrereqMet}
          isCompleted={isCompleted}
          onEvaluate={onEvaluate}
          semester={semester}
          viewMode={viewMode}
          isDragging={isDragging}
          onPrereqClick={handlePrereqClick}
          onAutofillClick={handleAutofillClick}
          onDuplicateClick={handleDuplicateClick} // Pass the handler
          onAddPrerequisiteCourse={onAddPrerequisiteCourse}
        />
      ))}

      {/* Render PrerequisiteDisplay if any course has selectedPrerequisites */}
      <ErrorDisplay
        prerequisites={selectedPrerequisites}
        position={selectedPosition}
        selectedCourse={selectedCourse}
        semester={semester}
        yearNumber={yearNumber}
        onAddPrerequisiteCourse={onAddPrerequisiteCourse}
        onClose={onCloseErrorDisplay} // Pass the onClose function
      />

      {/* Render AutofillDisplay if a course is selected */}
      <AutofillDisplay
        course={selectedAutofillCourse}
        position={selectedAutofillPosition}
      />

      {/* Render DuplicateCourseDisplay if a course is selected */}
      <DuplicateCourseDisplay
        course={selectedDuplicateCourse}
        position={selectedDuplicatePosition}
      />
    </div>
  );
};

export default CourseRendering;
