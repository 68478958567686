import React, { useState } from 'react';
import { toast } from 'react-toastify'; // Make sure you have this import if you're using react-toastify


const ClassCart = ({ classesInCart, onRemoveClass, onAddCourses, yearNumber, semester, surveyAnswers }) => {
  const startYear = parseInt(surveyAnswers?.SchoolStart?.year);
  const specificYear = semester === 'Fall' ? startYear + yearNumber - 1 : startYear + yearNumber;
  
  const [selectedYear, setSelectedYear] = useState(specificYear || '');
  const [selectedSemester, setSelectedSemester] = useState(semester || '');

  const handleAddCoursesRegular = () => {
    const targetSemester =  semester === 'Fall' ? 'S1' : 'S2';
    const targetYear = yearNumber;
    
    onAddCourses(targetYear, targetSemester);
  };

  const handleAddCoursesSelected = () => {
    if (!selectedSemester || !selectedYear) {
      toast.warning("Please select the year and semester to which you'd like to add the courses.");
      return; // Prevent further execution if the condition is not met
    }

    const targetYear =  selectedSemester === 'Fall' ? selectedYear - startYear + 1 : selectedYear - startYear;
    const targetSemester = selectedSemester === 'Fall' ? 'S1' : 'S2';

    onAddCourses(targetYear, targetSemester);
  };

  return (
    <div className={`py-3 ${!semester || semester.trim() === "" ? "px-2" : "px-3"} bg-gray-50 shadow-md border rounded-lg w-full min-h-200 mb-20`}>
      <h5 className="text-lg text-center font-bold mb-4">Class Cart</h5>
      {classesInCart.length === 0 ? (
        <p className="text-md text-center">Add classes to the cart</p>
      ) : (
        <div className="max-h-96 overflow-y-auto">
          {classesInCart.map((classData, index) => (
            <ClassCartItem
              key={index}
              classData={classData}
              onRemoveClass={onRemoveClass}
            />
          ))}
        </div>
      )}
      <div className="flex justify-center mt-2">
      {!semester || semester.trim() === "" ? (
        <button
          className="w-full bg-blue-500 hover:bg-blue-700 text-white text-xs py-2 rounded mt-1 flex items-center justify-center space-x-2"
          onClick={handleAddCoursesSelected}
        >
          <span>Add to</span>

          {/* Semester Dropdown with Placeholder */}
          <select
            value={selectedSemester}
            onChange={(e) => setSelectedSemester(e.target.value)}
            className="bg-gray-100 text-gray-800 text-xs border-none outline-none appearance-none w-24 rounded px-2 py-1 text-left"
            onClick={(e) => e.stopPropagation()}
          >
            <option value="" disabled hidden>
              Semester
            </option>
            <option value="Fall">Fall</option>
            <option value="Spring">Spring</option>
          </select>

          {/* Year Dropdown with Placeholder */}
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            className="bg-gray-100 text-gray-800 text-xs border-none outline-none appearance-none w-16 rounded px-2 py-1 text-left"
            onClick={(e) => e.stopPropagation()}
          >
            <option value="" disabled hidden>
              Year
            </option>
            {[...Array(5)].map((_, idx) => (
              <option key={idx} value={startYear + idx}>
                {startYear + idx}
              </option>
            ))}
          </select>
        </button>
      ) : (
        <button
          className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-1 rounded mt-1 flex items-center justify-center space-x-2"
          onClick={handleAddCoursesRegular}
        >
          <span>Add to {semester} {specificYear}</span>
        </button>
      )}
      </div>
    </div>
  );      
};



const ClassCartItem = ({ classData, onRemoveClass }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (value) => {
    setIsHovered(value);
  };

  return (
    <div
      className="class-cart-item bg-white mb-2 rounded p-2 relative shadow-none"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <p className="font-bold">{classData.courseInfo_courseNumber}</p>
      {/* Add more details if needed */}
      {isHovered && (
        <div className="absolute bottom-2 right-3">
          <button
            className="text-xs text-red-500 hover:text-black"
            onClick={() => onRemoveClass(classData)}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default ClassCart;
