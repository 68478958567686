import React from 'react';
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs';

const ElectivesRendering = ({ courseList, majorCourses, plannedCourses }) => {
  // Utility to find a matching planned course for a given elective requirement
  const majorCourseNumbers = new Set(
    majorCourses.map((course) => course.courseInfo_courseNumber)
  );

  const findElectiveMatch = (possibility, usedCourses) => {
    for (const year in plannedCourses) {
      const semesters = plannedCourses[year];
      for (const semester in semesters) {
        const courses = semesters[semester];
        for (const plannedCourse of courses) {
          if (
            typeof plannedCourse.Elective_fulfillment === 'string' &&
            plannedCourse.Elective_fulfillment.includes(possibility) &&
            !usedCourses.has(plannedCourse.courseInfo_courseNumber) &&
            !majorCourseNumbers.has(plannedCourse.courseInfo_courseNumber) // Exclude major courses
          ) {
            return plannedCourse.courseInfo_courseNumber;
          }
        }
      }
    }
    return null;
  };

  // Set to track already used courses
  const usedCourses = new Set();

  return courseList.length > 0 ? (
    <ul className="space-y-2 ml-4 pl-4 border-l-2 border-gray-400">
      {courseList.map((course, index) => {
        const possibility = course.ElectiveRequirement?.trim() || ''; // Single possibility
        const matchedCourse = findElectiveMatch(possibility, usedCourses);

        // Mark the course as used if it was matched
        if (matchedCourse) {
          usedCourses.add(matchedCourse);
        }

        return (
            <li
                key={index}
                className={`p-2 border rounded-lg shadow-sm hover:shadow-md transition-shadow flex justify-between items-center ${index === 0 ? 'mt-2' : ''}`}
            >
            {/* Left side: Requirements */}
            <h3 className="text-md font-semibold text-left">
              {course.Requirements} Elective
            </h3>

            {/* Right side: Status */}
            <div className="flex items-center space-x-2">
              {matchedCourse ? (
                <>
                  <span className="text-green-600 font-medium">
                    Satisfied with {matchedCourse}
                  </span>
                  <BsCheckSquareFill className="text-green-600 h-5 w-5" />
                </>
              ) : (
                <>
                  <span className="text-red-600 font-medium">Not In Plan</span>
                  <BsSquare className="text-gray-400 h-5 w-5" />
                </>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  ) : (
    <p className="text-gray-500">No electives available.</p>
  );
};

export default ElectivesRendering;
