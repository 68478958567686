import React, { useState } from 'react';
import CourseAnalyticsModal from './DetailsModal/CourseAnalyticsModal';

const AnalyticsClassSearch = ({ classData  }) => {
  const [isHovered, setIsHovered] = useState(false); // State for hover effect
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from toggling collapse
    setIsModalOpen(true); // Open modal on click
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };
  

  return (
    <div className="border bg-white rounded-lg p-1.5 mb-2">
      <div className="grid grid-cols-12 items-center">
        <div className="text-center font-bold col-span-2">{classData.courseInfo_courseNumber}</div>
        <div className="text-center col-span-5">{classData.courseInfo_courseName}</div>
        <div className="text-center col-span-2">
          <div className="text-center font-semibold text-sm">
            {classData.SectionsOffered} sections
          </div>
          <div className="text-center text-xs">
            {classData.TotalSeatsOffered} seats
          </div>
        </div>
        <div className="mr-2 text-center col-span-2">
          <div className="text-center font-semibold text-sm">
            {classData.SectionsNeeded} sections
          </div>
          <div className="text-center text-xs">
            {classData.TotalSeatsNeeded} seats
          </div>
        </div>
        <div className="text-right col-span-1 mr-1">
          <button
            onClick={handleButtonClick}
            className={`text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded text-xs px-2 py-1 transition-all duration-300`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Details
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <CourseAnalyticsModal 
          isOpen={isModalOpen} 
          onClose={handleCloseModal} 
          classData={classData} 
        />
      )}
    </div>
  );
};

export default AnalyticsClassSearch;
