import React from 'react';
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs';

const MajorCoursesRendering = ({ courseList, plannedCourses, fetchedCourseData }) => {
  const isCourseInPlannedCourses = (courseNumber) => {
    for (const year in plannedCourses) {
      const semesters = plannedCourses[year];
      for (const semester in semesters) {
        const courses = semesters[semester];
        if (courses.some((plannedCourse) => plannedCourse.courseInfo_courseNumber === courseNumber)) {
          return true;
        }
      }
    }
    return false;
  };

  return courseList.length > 0 ? (
    <ul className="space-y-2 ml-4 pl-4 border-l-2 border-gray-400"> {/* Added border-l-4 for the vertical line */}
        {courseList.map((course, index) => {
            const courseName = fetchedCourseData.find(
            (data) => data.courseInfo_courseNumber === course.courseInfo_courseNumber
            )?.courseInfo_courseName || "Course Name Not Found";

            const inPlan = isCourseInPlannedCourses(course.courseInfo_courseNumber);

            return (
            <li
                key={index}
                className={`p-2 border rounded-lg shadow-sm hover:shadow-md transition-shadow flex justify-between items-center ${index === 0 ? 'mt-2' : ''}`}
            >
                <div>
                <h3 className="text-md font-semibold">
                    {course.courseInfo_courseNumber} - {courseName}
                </h3>
                </div>
                <div className="flex items-center space-x-2">
                {inPlan ? (
                    <>
                    <span className="text-green-600 font-medium">In Plan</span>
                    <BsCheckSquareFill className="text-green-600 h-5 w-5" />
                    </>
                ) : (
                    <>
                    <span className="text-red-600 font-medium">Not In Plan</span>
                    <BsSquare className="text-gray-400 h-5 w-5" />
                    </>
                )}
                </div>
            </li>
            );
        })}
        </ul>
  ) : (
    <p className="text-gray-500">No major courses available.</p>
  );
};

export default MajorCoursesRendering;
