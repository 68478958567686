import React, { useState, useEffect } from 'react';
import CoursesAnalytics from './CoursesAnalytics';
import StudentsAnalytics from './StudentsAnalytics';
import AuditAnalytics from './AuditAnalytics';
import CustomSidebar from '../Sidebar/Sidebar';
import { useAuth } from '../../../contexts/AuthContext'; // Adjust the import path if necessary
// import { coursesdataDB } from '../../firebase'; // Import only the necessary function

const Analytics = () => {
  const [activeTab, setActiveTab] = useState('courses');
  const { currentUser } = useAuth();
  // const [coursesData, setCoursesData] = useState([]);

  // useEffect(() => {
  //   const fetchCoursesData = async () => {
  //     if (activeTab === 'courses') {
  //       if (currentUser) {
  //         try {
  //           // Call coursesdataDB to fetch and process the 'courses' collection
  //           const courses = await coursesdataDB('courses');
  //           console.log('Courses Data from coursesdataDB:', courses);
  //           setCoursesData(courses || []);
  //         } catch (error) {
  //           console.error('Error fetching courses data:', error);
  //         }
  //       } else {
  //         console.log('User is not authenticated');
  //       }
  //     }
  //   };

  //   fetchCoursesData();
  // }, [activeTab, currentUser]);

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-0">
        <div
          className="fixed ml-8 top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 z-10 w-full"
        >
          <div className="flex justify-center">
            <ul
              className="flex space-x-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
              style={{ listStyleType: 'none' }}
            >
              {/* Tab Buttons */}
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'courses'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="courses-tab"
                  type="button"
                  role="tab"
                  aria-controls="courses"
                  aria-selected={activeTab === 'courses'}
                  onClick={() => setActiveTab('courses')}
                >
                  Courses
                </button>
              </li>
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'students'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="students-tab"
                  type="button"
                  role="tab"
                  aria-controls="students"
                  aria-selected={activeTab === 'students'}
                  onClick={() => setActiveTab('students')}
                >
                  Students
                </button>
              </li>
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'audit'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="audit-tab"
                  type="button"
                  role="tab"
                  aria-controls="audit"
                  aria-selected={activeTab === 'audit'}
                  onClick={() => setActiveTab('audit')}
                >
                  Audit
                </button>
              </li>
            </ul>
          </div>
          <div className="border-b border-gray-200 dark:border-gray-700"></div>
        </div>

        <div className="ml-20" style={{ marginTop: '20px' }}>
          {activeTab === 'courses' && (
            <div
              className="p-1 rounded-lg dark:bg-gray-800"
              id="courses"
              role="tabpanel"
              aria-labelledby="courses-tab"
            >
              <CoursesAnalytics />
            </div>
          )}
          {activeTab === 'students' && (
            <div
              className="p-3 rounded-lg dark:bg-gray-800"
              id="students"
              role="tabpanel"
              aria-labelledby="students-tab"
            >
              <StudentsAnalytics />
            </div>
          )}
          {activeTab === 'audit' && (
            <div
              className="p-3 rounded-lg dark:bg-gray-800"
              id="audit"
              role="tabpanel"
              aria-labelledby="audit-tab"
            >
              <AuditAnalytics />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
