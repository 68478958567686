import React from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import calculateAnalyticsData from './CalculateAnalytics';
import CustomSidebar from '../../Sidebar/Sidebar';
import { toast } from 'react-toastify'; // Import toast from react-toastify

const RunCalculateAnalytics = () => {

  const { fetchedCourseData } = useAuth();

  const handleRunAnalytics = async () => {
    // Show loading toast
    const toastId = toast.loading("Calculating analytics...");

    try {
      // Run the analytics function
      await calculateAnalyticsData(fetchedCourseData);
      
      // Show success toast after function runs successfully
      toast.update(toastId, {
        render: "Analytics calculated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      // Show error toast if there is a failure
      toast.update(toastId, {
        render: "Error running analytics!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  return (
    <div className="assign-role p-6 bg-white rounded-lg shadow-md">
      <CustomSidebar />
      <h2 className="text-xl font-semibold mb-4">Run Analytics</h2> {/* Title added here */}
      <button 
        onClick={handleRunAnalytics} 
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Run Analytics
      </button>
    </div>
  );
};

export default RunCalculateAnalytics;
