import React, { useState, useEffect } from 'react';
import { TextInput, Select } from 'flowbite-react';
import Autocomplete from './Autocomplete';
import MultiSelect from './MultiSelect';
import NumericalInput from './NumericalInput';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';


const Question = ({ question, questionIndex, selectedAnswers, handleAnswerChange }) => {
  const { faculties, fetchedMajors, fetchedMinors } = useAuth();
  const [otherText, setOtherText] = useState('');

  const majorQuestionIndex = 4;

  const parsedFaculties = faculties.map((item) => item.Schools);
  const parsedMajors = Object.keys(fetchedMajors).filter(
    major => major !== "Pathway Groupings" && major !== "Electives"
  );
  
  const parsedMinors = Object.keys(fetchedMinors).filter(
    minor => minor !== "Pathway Groupings" && minor !== "Electives"
  );
  

  useEffect(() => {
    // Initialize the "Other" text with the value from selectedAnswers if the user previously selected "Other"
    if (
      selectedAnswers[questionIndex] &&
      !Object.values(question).includes(selectedAnswers[questionIndex])
    ) {
      setOtherText(selectedAnswers[questionIndex]);
    }
  }, [selectedAnswers, questionIndex, question]);

  const handleOtherTextChange = (e) => {
    const newText = e.target.value;
    setOtherText(newText);
    if (newText === '') {
      // Keep 'Other' selected even if the text is empty
      handleAnswerChange('Other', questionIndex);
    } else {
      handleAnswerChange(newText, questionIndex); // Update state immediately
    }
  };
  
  const handleOptionSelect = (option) => {
    if (option === 'Other') {
      // Set the otherText if previously set or 'Other' if not
      handleAnswerChange(otherText || 'Other', questionIndex);
    } else {
      handleAnswerChange(option, questionIndex);
      setOtherText(''); // Reset the other text when another option is selected
    }
  };
  
const handleCheckboxChange = (option) => {
    const maxChoices = 10; // Can be changed

    const previousSelections = selectedAnswers[questionIndex] || [];
    
    // If the option is already selected, remove it from the selections
    if (Array.isArray(previousSelections) && previousSelections.includes(option)) {
        handleAnswerChange(
            previousSelections.filter((item) => item !== option),
            questionIndex
        );
    } else {
        // If the selection limit is reached, show a warning
        if (previousSelections.length >= maxChoices) {
            toast.warning(`You can select a maximum of ${maxChoices} options.`);
            return;
        }
        // Add the option to the selections if within the limit
        handleAnswerChange(
            [...previousSelections, option],
            questionIndex
        );
    }
  };


  const handleSliderChange = (value) => {
    handleAnswerChange(value, questionIndex);
  };

  const handleCalendarChange = (e, type) => {
    const currentSelection = selectedAnswers[questionIndex] || { month: '', year: '' };
    handleAnswerChange({ ...currentSelection, [type]: e.target.value }, questionIndex);
  };

  const renderCalendarSelection = (future = true) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const years = future
      ? Array.from({ length: 8 }, (_, i) => new Date().getFullYear() + i)
      : Array.from({ length: 8 }, (_, i) => new Date().getFullYear() - i);

    return (
      <div className="flex space-x-2">
        <Select
          value={selectedAnswers[questionIndex]?.month || ''}
          onChange={(e) => handleCalendarChange(e, 'month')}
          placeholder="Select Month"
        >
          <option value="" disabled>
            Select Month
          </option>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </Select>
        <Select
          value={selectedAnswers[questionIndex]?.year || ''}
          onChange={(e) => handleCalendarChange(e, 'year')}
          placeholder="Select Year"
        >
          <option value="" disabled>
            Select Year
          </option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </div>
    );
  };

  return (
    <div className="w-full">
      <h3 className="text-lg font-semibold">
        {questionIndex + 1}. {question.Question}
        {question.Required && <span className="text-red-500">*</span>}
      </h3>
      <div className="mt-2">
      {question.QuestionType === 'Multiple Choice' ? (
          <div>
            {Object.entries(question)
              .filter(([key, value]) => key.startsWith('Option') && value && value !== 'Other')
              .map(([key, answer], answerIndex) => (
                <div
                  key={answerIndex}
                  className="flex items-center mt-2 cursor-pointer"
                  role="radio"
                  aria-checked={selectedAnswers[questionIndex] === answer}
                  onClick={() => handleOptionSelect(answer)}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') handleOptionSelect(answer);
                  }}
                >
                  <span
                    className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                      selectedAnswers[questionIndex] === answer
                        ? 'bg-blue-600 border-blue-600'
                        : 'border-gray-400'
                    }`}
                  >
                    {selectedAnswers[questionIndex] === answer && (
                      <span className="w-1.5 h-1.5 bg-white rounded-full" />
                    )}
                  </span>
                  <span
                    className={`text-sm ${
                      selectedAnswers[questionIndex] === answer ? 'text-blue-600' : 'text-black'
                    }`}
                  >
                    {answer}
                  </span>
                </div>
              ))}
            {/* Single "Other" option at the end */}
            {Object.values(question).includes('Other') && (
              <div
              className="flex items-center mt-2 cursor-pointer"
              role="radio"
              aria-checked={selectedAnswers[questionIndex] === 'Other' || otherText}
              onClick={() => handleOptionSelect('Other')}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') handleOptionSelect('Other');
              }}
            >
              <span
                className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                  selectedAnswers[questionIndex] === 'Other' || otherText
                    ? 'bg-blue-600 border-blue-600'
                    : 'border-gray-400'
                }`}
              >
                {(selectedAnswers[questionIndex] === 'Other' || otherText) && (
                  <span className="w-1.5 h-1.5 bg-white rounded-full" />
                )}
              </span>
              <span
                className={`text-sm ${
                  selectedAnswers[questionIndex] === 'Other' || otherText ? 'text-blue-600' : 'text-black'
                }`}
              >
                Other
              </span>
            </div>
          )}
            {(selectedAnswers[questionIndex] === 'Other' || otherText) && (
              <TextInput
                type="text"
                placeholder="Please specify"
                value={otherText}
                onChange={handleOtherTextChange}
                className="bg-gray-200 text-black mt-2"
              />
            )}
          </div>
        ) : question.QuestionType === 'Multi Select' ? (
          question.Question === 'Which faculty are you a part of?' ? (
            <MultiSelect
              suggestions={parsedFaculties}
              value={selectedAnswers[questionIndex] || ''}
              onChange={(e) => handleAnswerChange(e.target.value, questionIndex)}
              placeholder="Search for your faculty"
              required={true}
            />
          ) : question.Question === 'What is your intended major(s)?' ? (
            <MultiSelect
              suggestions={parsedMajors}
              value={selectedAnswers[questionIndex] || ''}
              onChange={(e) => handleAnswerChange(e.target.value, questionIndex)}
              placeholder="Search for your major"
              required={true}
            />
          ) : question.Question === 'If applicable, what is your intended minor(s)?' ? (
            <MultiSelect
              suggestions={parsedMinors}
              value={selectedAnswers[questionIndex] || ''}
              onChange={(e) => handleAnswerChange(e.target.value, questionIndex)}
              placeholder="Search for your minor"
              required={false}
              unselectable={selectedAnswers[majorQuestionIndex]}
            />
          ) : (
            <TextInput
              type="text"
              placeholder="Type your answer"
              value={selectedAnswers[questionIndex] || ''}
              onChange={(e) => handleAnswerChange(e.target.value, questionIndex)}
              className="bg-gray-200 text-black"
            />
          )
        ) : question.QuestionType === 'Text Input' ? (
          question.Question === 'What is your current GPA?' ? (
            <NumericalInput
              value={selectedAnswers[questionIndex] || ''}
              onChange={(value) => handleAnswerChange(value, questionIndex)}
              placeholder="Type your answer"
            />
          ) : (
            <TextInput
              type="text"
              placeholder="Type your answer"
              value={selectedAnswers[questionIndex] || ''}
              onChange={(e) => handleAnswerChange(e.target.value, questionIndex)}
              className="bg-gray-200 text-black"
            />
          )
        ) : question.QuestionType === 'Select All That Apply' ? (
          <div className="max-h-64 overflow-y-auto flex flex-wrap">
            {Object.entries(question)
              .filter(([key, value]) => key.startsWith('Option') && value)
              .sort((a, b) => a[1].localeCompare(b[1])) // Sort options alphabetically by value
              .map(([key, answer], answerIndex) => (
                <div
                  key={answerIndex}
                  className={`m-1 px-2 py-1 text-sm rounded-full cursor-pointer ${
                    Array.isArray(selectedAnswers[questionIndex]) &&
                    selectedAnswers[questionIndex].includes(answer)
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-200 text-black'
                  }`}
                  onClick={() => handleCheckboxChange(answer)}
                >
                  {answer}
                </div>
              ))}
          </div>
        ) : question.QuestionType === 'Preference Scale' ? (
          <div className="flex flex-col items-center mt-4">
            <div className="flex justify-between w-full mb-2 text-sm font-medium text-gray-700">
              <span>{question.Option1}</span>
              <span>{question.Option2}</span>
            </div>
            <input
              type="range"
              min="-5"
              max="5"
              step="1"
              value={selectedAnswers[questionIndex] || 0}
              onChange={(e) => handleSliderChange(parseFloat(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              style={{
                background: `linear-gradient(to right, #3b82f6 0%, #3b82f6 ${(selectedAnswers[questionIndex] + 5) * 10}%, #e5e7eb ${(selectedAnswers[questionIndex] + 5) * 10}%, #e5e7eb 100%)`
              }}
            />
          </div>
        ) : question.QuestionType === 'Calendar Selection - Future' ? (
          renderCalendarSelection(true)
        ) : question.QuestionType === 'Calendar Selection - Past' ? (
          renderCalendarSelection(false)
        ) : null}
      </div>
    </div>
  );
};

export default Question;
