import React, { useState } from 'react';
import { TextInput } from 'flowbite-react';

const NumericalInput = ({ value, onChange, placeholder }) => {
  const [warning, setWarning] = useState('');

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Check if length is greater than 10
    if (inputValue.length > 10) {
      setWarning('Input cannot exceed 10 characters.');
      return;
    }

    // Check if input only contains valid characters: numbers, decimal, and optional negative sign
    const isValidInput = /^[\d.-]*$/.test(inputValue);

    // Validate that the input is a valid number (including decimals and negative numbers)
    const isValidNumber = /^-?\d*\.?\d+$/.test(inputValue) || inputValue === '';

    if (isValidInput && isValidNumber) {
      setWarning('');
      onChange(inputValue);
    } else {
      setWarning('Please enter a valid number.');
    }
  };

  return (
    <div>
      <TextInput
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className="bg-gray-200 text-black rounded"
        onFocus={() => setWarning('')}
      />
      {warning && <p className="text-red-500 mt-1">{warning}</p>}
    </div>
  );
};

export default NumericalInput;
