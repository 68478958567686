import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

const creditsData = [
  { name: 'No Credits', value: 1, color: '#e65100' },         // Darker Blue
  { name: '0 - 3 Credits', value: 0, color: '#00695c' },     // Dark Teal
  { name: '4 - 6 Credits', value: 0, color: '#2e7d32' },     // Dark Green
  { name: '7 - 9 Credits', value: 0, color: '#6a1b9a' },     // Dark Purple
  { name: '10 - 12 Credits', value: 5, color: '#003f8a' },  // Dark Orange
  { name: '13 - 15 Credits', value: 0, color: '#f57c00' },   // Darker Orange
  { name: '16+ Credits', value: 0, color: '#fbc02d' },      // Gold
];

const planData = [
  { name: 'Students with a plan', value: 10, color: '#003f8a' },
  { name: 'Students without a plan', value: 4, color: '#00695c' },
];

const renderCenterText = (total) => (
    <text
      x="20%"  
      y="50%"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{ fill: '#333' }}
    >
      {/* First line: Total Number */}
      <tspan 
        x="22%" 
        dy="-0.6em" 
        style={{ fontSize: '23px', fontWeight: 'bold' }}
        className="mb-6"
      >
        {total}
      </tspan>
      
      {/* Second line: Label */}
      <tspan 
        x="22%" 
        dy="1.2em" 
        style={{ fontSize: '14px', fontWeight: '500' }}
      >
        Total Students
      </tspan>
    </text>
  );
  

const Charts = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Credits Planned Chart */}
      <div className="bg-white dark:bg-gray-800 border border-gray-200 rounded-lg shadow-md p-3">
        <h3 className="text-lg font-medium">Credits Planned for Fall 2024</h3>
        <div className="w-full flex items-center">
          <ResponsiveContainer height={300}>
            <PieChart>
              <Pie
                data={creditsData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={100}
                label={false}
              >
                {creditsData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              {renderCenterText(6)} {/* Total number of students */}
              <Legend
                layout="vertical"
                verticalAlign="middle"
                align="right"
                wrapperStyle={{
                  paddingLeft: 177, // Increased padding to move legend further right
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Plan Creation Chart */}
      <div className="bg-white dark:bg-gray-800 border border-gray-200 rounded-lg shadow-md p-3">
        <h3 className="text-lg font-medium">Plan Creation</h3>
        <div className="w-full flex items-center">
          <ResponsiveContainer height={300}>
            <PieChart>
              <Pie
                data={planData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={100}
                label={false}
              >
                {planData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              {renderCenterText(14)} {/* Total number of students */}
              <Legend
                layout="vertical"
                verticalAlign="middle"
                align="right"
                wrapperStyle={{
                  paddingLeft: 110, // Increased padding to move legend further right
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Charts;
