import { db } from '../../../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

// Helper function to find the first digit in the course number
const findFirstDigit = (courseNumber) => {
  for (let i = 0; i < courseNumber.length; i++) {
    if (/\d/.test(courseNumber.charAt(i))) {
      return parseInt(courseNumber.charAt(i));
    }
  }
  return 0; // Return 0 if no digit is found
};

// Function to calculate random enrollment based on mean and standard deviation
const randomizeEnrollment = (mean, stdDev) => {
  const rand = Math.max(0, Math.round(mean + (Math.random() - 0.5) * 2 * stdDev));
  return rand;
};

// Helper function to generate enrollment data for a course based on its number
const calculateEnrollmentTotal = (courseNumber) => {
  const firstDigit = findFirstDigit(courseNumber);

  let meanSpringFall = 0;
  let meanSummer = 0;
  let stdDevSpringFall = 0;
  let stdDevSummer = 0;

  switch (firstDigit) {
    case 1:
      meanSpringFall = 200;
      stdDevSpringFall = 15;
      meanSummer = 80;
      stdDevSummer = 5;
      break;
    case 2:
      meanSpringFall = 150;
      stdDevSpringFall = 10;
      meanSummer = 60;
      stdDevSummer = 3;
      break;
    case 3:
      meanSpringFall = 100;
      stdDevSpringFall = 10;
      meanSummer = 40;
      stdDevSummer = 2;
      break;
    case 4:
      meanSpringFall = 50;
      stdDevSpringFall = 5;
      meanSummer = 20;
      stdDevSummer = 2;
      break;
    default:
      meanSpringFall = 20;
      stdDevSpringFall = 3;
      meanSummer = 0;
      stdDevSummer = 0;
  }

  return [
    { term: "SP21", Total: randomizeEnrollment(meanSpringFall, stdDevSpringFall)},
    { term: "SU21", Total: randomizeEnrollment(meanSummer, stdDevSummer)},
    { term: "FA21", Total: randomizeEnrollment(meanSpringFall, stdDevSpringFall)},
    { term: "SP22", Total: randomizeEnrollment(meanSpringFall + (3/firstDigit), stdDevSpringFall)},
    { term: "SU22", Total: randomizeEnrollment(meanSummer, stdDevSummer)},
    { term: "FA22", Total: randomizeEnrollment(meanSpringFall + (3/firstDigit), stdDevSpringFall)},
    { term: "SP23", Total: randomizeEnrollment(meanSpringFall + (6/firstDigit), stdDevSpringFall)},
    { term: "SU23", Total: randomizeEnrollment(meanSummer, stdDevSummer)},
    { term: "FA23", Total: randomizeEnrollment(meanSpringFall + (6/firstDigit), stdDevSpringFall)},
    { term: "SP24", Total: randomizeEnrollment(meanSpringFall + (9/firstDigit), stdDevSpringFall)},
    { term: "SU24", Total: randomizeEnrollment(meanSummer, stdDevSummer)},
    { term: "FA24", Total: randomizeEnrollment(meanSpringFall + (9/firstDigit), stdDevSpringFall)},
    { term: "SP25", Total: randomizeEnrollment(meanSpringFall + (12/firstDigit), stdDevSpringFall)},
    { term: "SU25", Total: randomizeEnrollment(meanSummer, stdDevSummer)},
  ];
};

const calculateAnalyticsData = async (fetchedCourseData) => {
  // Get the first 20 courses from the fetchedCourseData array
  const first20Courses = fetchedCourseData.slice(0, 50);

  // Loop through the first 20 courses
  for (const course of fetchedCourseData) {
    const { courseInfo_courseNumber, courseInfo_courseName } = course;

    // Get reference to the course document by courseNumber
    const courseRef = doc(db, 'courses', courseInfo_courseNumber);

    // Generate enrollment data for the course
    const enrollmentData = calculateEnrollmentTotal(courseInfo_courseNumber);

    // Calculate historical change percentage
    const calculatePercentageChange = (oldValue, newValue) => {
      if (oldValue === 0) return 0; // Avoid division by zero
      return Math.round(((newValue - oldValue) / oldValue) * (100/2));
    };

    const findEnrollmentTotal = (term) => {
        const termData = enrollmentData.find(data => data.term === term);
        return termData ? termData.Total : 0; // Return 0 if the term is not found
    };
    
    const fallHistorical = calculatePercentageChange(findEnrollmentTotal('FA21'), findEnrollmentTotal('FA23'));
    const springHistorical = calculatePercentageChange(findEnrollmentTotal('SP21'), findEnrollmentTotal('SP24'));
    const summerHistorical = calculatePercentageChange(findEnrollmentTotal('SU21'), findEnrollmentTotal('SU24'));
    

    const fallForecasted = calculatePercentageChange(findEnrollmentTotal('FA23'), findEnrollmentTotal('FA24'));
    const springForecasted = calculatePercentageChange(findEnrollmentTotal('SP24'), findEnrollmentTotal('SP25'));
    const summerForecasted = calculatePercentageChange(findEnrollmentTotal('SU24'), findEnrollmentTotal('SU25'));

    const trends = {
      fall: { historical: fallHistorical, forecasted: fallForecasted },
      spring: { historical: springHistorical, forecasted: springForecasted },
      summer: { historical: summerHistorical, forecasted: summerForecasted },
    };

    // If the document doesn't exist, create it
    await setDoc(courseRef, {
      courseNumber: courseInfo_courseNumber,
      courseName: courseInfo_courseName,
      enrollmentData: enrollmentData,
      currentEnrollments: randomizeEnrollment(findEnrollmentTotal('FA24') - 20, 20), // Randomize current enrollment for FA24
      trends: trends, // Add trends data
    });

    console.log(`Course ${courseInfo_courseNumber} created with enrollment data and trends.`);
  }
};

export default calculateAnalyticsData;
