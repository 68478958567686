import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../../../firebase'; // Adjust the path as necessary
import { FaPaperPlane, FaTimes } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import {
  collection,
  addDoc,
  query,
  orderBy,
  where,
  getDocs,
  writeBatch,
  serverTimestamp,
  doc,
  getDoc,
  onSnapshot,
} from 'firebase/firestore';

const AdvisorChat = ({ studentId, activeTab, onClose }) => {
  const advisorId = auth.currentUser.uid;
  const chatId = activeTab && studentId
    ? `${activeTab}_${studentId}_${advisorId}`
    : null; // Unique chat ID with plan-specific

  const messagesRef = chatId ? collection(db, 'chats', chatId, 'messages') : null;
  const q = messagesRef ? query(messagesRef, orderBy('timestamp')) : null;

  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const chatBoxRef = useRef(null);

  const [studentData, setStudentData] = useState(null);
  const [advisorData, setAdvisorData] = useState(null);
  const [formValue, setFormValue] = useState('');

  // Fetch student and advisor details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Fetch Student Data
        const studentDocRef = doc(db, 'users', studentId);
        const studentDoc = await getDoc(studentDocRef);
        if (studentDoc.exists()) {
          setStudentData({
            id: studentDoc.id,
            name: `${studentDoc.data().firstName} ${studentDoc.data().lastName}`,
            photoURL: studentDoc.data().profilePicture || '/default-student.jpg',
          });
        } else {
          console.error('No such student!');
        }

        // Fetch Advisor Data
        const advisorDocRef = doc(db, 'advisors', advisorId);
        const advisorDoc = await getDoc(advisorDocRef);
        if (advisorDoc.exists()) {
          setAdvisorData({
            id: advisorDoc.id,
            name: `${advisorDoc.data().firstName} ${advisorDoc.data().lastName}`,
            photoURL: advisorDoc.data().profilePicture || '/default-advisor.jpg',
          });
        } else {
          console.error('No such advisor!');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    if (studentId && activeTab) {
      fetchUserDetails();
    }
  }, [advisorId, studentId, activeTab]);

  // Listen to messages in real-time
  useEffect(() => {
    if (!studentId || !activeTab || !q) {
      setMessages([]);
      return;
    }

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const msgs = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          if (data.senderId === advisorId && advisorData) {
            data.senderName = advisorData.name;
            data.senderPhotoURL = advisorData.photoURL;
            data.senderType = 'advisor';
          } else if (data.senderId === studentId && studentData) {
            data.senderName = studentData.name;
            data.senderPhotoURL = studentData.photoURL;
            data.senderType = 'student';
          } else {
            // Fallback in case data isn't loaded yet
            data.senderName = 'Unknown';
            data.senderPhotoURL = '/default-user.jpg';
            data.senderType = 'unknown';
          }
          msgs.push(data);
        });
        setMessages(msgs);

        // Scroll to bottom
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      },
      (error) => {
        console.error('Error fetching messages:', error);
      }
    );

    return () => unsubscribe();
  }, [q, advisorId, studentId, advisorData, studentData, activeTab]);

  // Mark messages as read when the advisor opens the chat
  const handleMarkMessagesAsRead = async () => {
    if (!advisorId || !studentId || !activeTab || !chatId) return;

    try {
      const unreadQuery = query(
        messagesRef,
        where('senderId', '==', studentId),
        where('read', '==', false)
      );
      const unreadSnapshot = await getDocs(unreadQuery);
      const batch = writeBatch(db);

      unreadSnapshot.forEach((docSnap) => {
        batch.update(docSnap.ref, { read: true });
      });

      await batch.commit();
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  useEffect(() => {
    // Once chatId is known and component is mounted, mark messages as read
    if (chatId) {
      handleMarkMessagesAsRead();
    }
  }, [chatId]);

  // Mark messages as read when the chat is opened
  useEffect(() => {
    if (chatId) {
      handleMarkMessagesAsRead();
    }
  }, [chatId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!formValue.trim() || !chatId) return;

    try {
      await addDoc(messagesRef, {
        content: formValue,
        senderId: advisorId,
        senderType: 'advisor',
        timestamp: serverTimestamp(),
        read: false,
      });
      setFormValue('');
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Handle closing the chatbox with Esc key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  // Close chatbox when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatBoxRef.current &&
        !chatBoxRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // Group messages
  const groupedMessages = [];
  let lastGroup = null;
  const MAX_TIME_DIFF = 2 * 60 * 1000; // 2 minutes

  messages.forEach((msg) => {
    const msgTime = msg.timestamp?.toDate ? msg.timestamp.toDate().getTime() : Date.now();
    if (!lastGroup) {
      lastGroup = {
        senderId: msg.senderId,
        senderName: msg.senderName,
        senderPhotoURL: msg.senderPhotoURL,
        messages: [msg],
        lastTimestamp: msgTime,
      };
    } else {
      const timeDiff = msgTime - lastGroup.lastTimestamp;
      if (msg.senderId === lastGroup.senderId && timeDiff < MAX_TIME_DIFF) {
        lastGroup.messages.push(msg);
        lastGroup.lastTimestamp = msgTime;
      } else {
        groupedMessages.push(lastGroup);
        lastGroup = {
          senderId: msg.senderId,
          senderName: msg.senderName,
          senderPhotoURL: msg.senderPhotoURL,
          messages: [msg],
          lastTimestamp: msgTime,
        };
      }
    }
  });

  if (lastGroup) {
    groupedMessages.push(lastGroup);
  }

  return (
    <div
      className="fixed bottom-4 right-4 min-w-[300px] min-h-[400px] w-80 md:w-96 max-h-[500px] bg-white rounded-lg shadow-lg flex flex-col z-50"
      ref={chatBoxRef}
    >
      {/* Header */}
      <div className="flex justify-between items-center p-3 bg-blue-600 rounded-t-lg">
        <div>
          <h2 className="text-sm font-semibold text-white">
            Plan: {activeTab}
          </h2>
          <h3 className="text-xs text-white">
            {studentData
              ? `Chat with ${studentData.name}`
              : 'Chat with Student'}
          </h3>
        </div>
        <button
          onClick={onClose}
          className="text-white hover:text-gray-200"
          aria-label="Close chat"
        >
          <FaTimes size={16} />
        </button>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-3 bg-gray-100 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        {messages.length === 0 && chatId && (
          <p className="text-center text-gray-500 text-sm">No messages yet. Start the conversation!</p>
        )}

        {groupedMessages.map((group, gIndex) => {
          const isCurrentUser = group.senderId === advisorId;
          const lastMsg = group.messages[group.messages.length - 1];
          const timestamp = lastMsg.timestamp?.toDate
            ? lastMsg.timestamp.toDate()
            : new Date();
          const timeAgo = timestamp
            ? formatDistanceToNow(timestamp, { addSuffix: true })
            : '';

          return (
            <div key={gIndex} className={`mb-4 flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}>
              {!isCurrentUser && (
                <img
                  src={group.senderPhotoURL || '/default-user.jpg'}
                  alt={group.senderName}
                  className="w-8 h-8 rounded-full mr-2 self-end"
                />
              )}

              <div className="max-w-[70%] flex flex-col space-y-1">
                {group.messages.map((msg, mIndex) => (
                  <div
                    key={mIndex}
                    className={`px-3 py-2 rounded-lg text-sm break-words ${
                      isCurrentUser
                        ? 'bg-blue-500 text-white rounded-br-none'
                        : 'bg-white text-gray-800 rounded-bl-none shadow'
                    }`}
                  >
                    {msg.content}
                  </div>
                ))}
                <p
                  className={`text-xs mt-1 ${isCurrentUser ? 'text-right text-gray-400' : 'text-gray-400'}`}
                >
                  {timeAgo}
                </p>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Form */}
      <form onSubmit={handleSendMessage} className="p-2 bg-white border-t flex items-center">
        <input
          type="text"
          className="flex-1 border border-gray-300 rounded-full px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Type your message..."
          disabled={!chatId}
        />
        <button
          type="submit"
          className={`ml-2 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ${
            !chatId ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          aria-label="Send message"
          disabled={!chatId}
        >
          <FaPaperPlane size={14} />
        </button>
      </form>
    </div>
  );
};

export default AdvisorChat;
