// src/components/Student/ScheduleMeetingModal.jsx

import React, { useState, useEffect } from 'react';
import { Button, Spinner, Label, Textarea, Select, TextInput } from 'flowbite-react';
import { db } from '../../firebase';
import CustomModal from '../../Shared/CustomModal';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import PropTypes from 'prop-types';

const ScheduleMeetingModal = ({ isOpen, onClose, advisor, student }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [meetingNotes, setMeetingNotes] = useState('');
  const [meetingDuration, setMeetingDuration] = useState('30'); // Default duration in minutes
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [meetingLink, setMeetingLink] = useState('');
  const [slotId, setSlotId] = useState(null); // Store the slot ID

  useEffect(() => {
    const fetchAdvisorLink = async () => {
      try {
        const advisorDoc = await db.collection('advisors').doc(advisor.uid).get();
        if (advisorDoc.exists) {
          setMeetingLink(advisorDoc.data().meetingLink || '');
        }
      } catch (error) {
        console.error('Error fetching meeting link:', error);
        toast.error('Failed to retrieve meeting link.');
      }
    };

    if (advisor && advisor.uid) {
      fetchAdvisorLink();
    }
  }, [advisor]);

  const generateTimeOptions = () => {
    const times = [];
    const start = 9 * 60;
    const end = 17 * 60;
    for (let minutes = start; minutes <= end; minutes += 15) {
      const hour = Math.floor(minutes / 60);
      const minute = minutes % 60;
      const meridiem = hour >= 12 ? 'PM' : 'AM';
      const displayHour = hour % 12 === 0 ? 12 : hour % 12;
      const displayMinute = minute.toString().padStart(2, '0');
      times.push(`${displayHour}:${displayMinute} ${meridiem}`);
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setSelectedTime('');
    setError('');
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
    setError('');
  };

  const handleDurationChange = (e) => {
    setMeetingDuration(e.target.value);
  };

  const handlePurposeChange = (e) => {
    setMeetingNotes(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDate) {
      setError('Please select a date.');
      return;
    }
    if (!selectedTime) {
      setError('Please select a time slot.');
      return;
    }
    if (!meetingNotes.trim()) {
      setError('Please provide the purpose of the meeting.');
      return;
    }

    const [time, meridiem] = selectedTime.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (meridiem === 'PM' && hours !== 12) hours += 12;
    if (meridiem === 'AM' && hours === 12) hours = 0;

    const [year, month, day] = selectedDate.split('-').map(Number);
    const meetingDate = new Date(year, month - 1, day, hours, minutes, 0, 0);

    const now = new Date();
    if (meetingDate <= now) {
      setError('Cannot schedule a meeting in the past.');
      return;
    }

    if (hours < 9 || hours >= 17) {
      setError('Meetings must be scheduled between 9:00 AM and 5:00 PM.');
      return;
    }

    const meetingEnd = new Date(meetingDate);
    meetingEnd.setMinutes(meetingEnd.getMinutes() + parseInt(meetingDuration, 10));

    if (meetingEnd.getHours() > 17 || (meetingEnd.getHours() === 17 && meetingEnd.getMinutes() > 0)) {
      setError('Meeting end time must be before 5:00 PM.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Step 1: Find the corresponding availability slot
      const formattedDate = selectedDate; // 'YYYY-MM-DD'
      const availabilityDoc = await db.collection('availability').doc(advisor.uid).get();

      if (!availabilityDoc.exists) {
        setError('No availability slots found for the advisor.');
        setLoading(false);
        return;
      }

      const availableSlots = availabilityDoc.data().availableSlots || [];

      // Find the slot that matches the selected date and time
      const matchingSlot = availableSlots.find(slot => {
        if (slot.date !== formattedDate) return false;
        return slot.startTime === selectedTime.split(' ')[0];
      });

      if (!matchingSlot) {
        setError('Selected time slot is not available.');
        setLoading(false);
        return;
      }

      if (matchingSlot.isBooked) {
        setError('Selected time slot is already booked.');
        setLoading(false);
        return;
      }

      // Step 2: Update the availability slot to mark it as booked
      await db.collection('availability').doc(advisor.uid).update({
        availableSlots: availableSlots.map(slot => 
          slot.id === matchingSlot.id ? { ...slot, isBooked: true } : slot
        ),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Step 3: Create the meeting document with a reference to the slotId
      const meetingRef = await db.collection('meetings').add({
        studentId: student.id,
        studentName: `${student.firstName} ${student.lastName}`,
        advisorId: advisor.uid,
        advisorName: `${advisor.firstName} ${advisor.lastName}`,
        meetingLink, // Add the advisor's meeting link here
        datetime: firebase.firestore.Timestamp.fromDate(meetingDate),
        status: 'Scheduled',
        meetingNotes: meetingNotes.trim(),
        meetingDuration: parseInt(meetingDuration, 10),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        slotId: matchingSlot.id, // Reference to the availability slot
      });

      toast.success('Meeting scheduled successfully!');
      onClose();
    } catch (err) {
      console.error('Error scheduling meeting:', err);
      toast.error('Failed to schedule meeting. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Schedule Meeting with ${student ? `${student.firstName} ${student.lastName}` : ''}`}
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Date Selection */}
        <div>
          <Label htmlFor="datepicker" value="Select Date" />
          <TextInput
            type="date"
            id="datepicker"
            value={selectedDate}
            onChange={handleDateChange}
            min={new Date().toISOString().split('T')[0]}
            required
            helperText="Choose a date for the meeting."
            className={`mt-1 block w-full ${
              !selectedDate && error.includes('date') ? 'border-red-500' : ''
            }`}
          />
          {!selectedDate && error.includes('date') && (
            <p className="mt-1 text-xs text-red-500">Please select a date.</p>
          )}
        </div>

        {/* Time Selection */}
        <div>
          <Label htmlFor="timepicker" value="Select Time" />
          <Select
            id="timepicker"
            value={selectedTime}
            onChange={handleTimeChange}
            required
            disabled={!selectedDate}
            className={`mt-1 block w-full ${
              !selectedTime && error.includes('time') ? 'border-red-500' : ''
            }`}
          >
            <option value="">Select a time slot...</option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </Select>
          {!selectedTime && error.includes('time') && (
            <p className="mt-1 text-xs text-red-500">Please select a time slot.</p>
          )}
        </div>

        {/* Meeting Duration Selection */}
        <div>
          <Label htmlFor="duration" value="Meeting Duration (Minutes)" />
          <Select
            id="duration"
            value={meetingDuration}
            onChange={handleDurationChange}
            className="mt-1 block w-full"
          >
            <option value="15">15 Minutes</option>
            <option value="30">30 Minutes</option>
            <option value="45">45 Minutes</option>
            <option value="60">1 Hour</option>
          </Select>
        </div>

        {/* Meeting Purpose */}
        <div>
          <Label htmlFor="purpose" value="Purpose of Meeting" />
          <Textarea
            id="purpose"
            placeholder="e.g., Discuss course selection, Review progress"
            value={meetingNotes}
            onChange={handlePurposeChange}
            required
            helperText="Provide a brief description of the meeting's purpose."
            className={`mt-1 block w-full ${
              !meetingNotes && error.includes('purpose') ? 'border-red-500' : ''
            }`}
          />
          {!meetingNotes && error.includes('purpose') && (
            <p className="mt-1 text-xs text-red-500">Please provide the purpose of the meeting.</p>
          )}
        </div>

        {/* Display form validation errors */}
        {error && !(
          (!selectedDate && error.includes('date')) ||
          (!selectedTime && error.includes('time')) ||
          (!meetingNotes && error.includes('purpose'))
        ) && (
          <div className="text-red-500 text-sm">
            {error}
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2">
          <Button color="failure" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button color="success" type="submit" disabled={loading}>
            {loading ? <Spinner size="sm" /> : 'Confirm'}
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

ScheduleMeetingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  advisor: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
};

export default ScheduleMeetingModal;
