// src/utils/fetchCourses.js

import { getDatabase, ref, get } from 'firebase/database';

/**
 * Saves course data to localStorage.
 *
 * @param {string} major - The major name.
 * @param {Array} courseData - The array of course data to save.
 */
export const saveCourseDataToLocalStorage = (major, courseData) => {
  try {
    const courseDataJSON = JSON.stringify(courseData);
    localStorage.setItem(`courseData-${major}`, courseDataJSON);
  } catch (error) {
    console.error('Error saving course data to local storage:', error);
  }
};

/**
 * Retrieves course data from localStorage.
 *
 * @param {string} major - The major name.
 * @returns {Array|null} - Returns the course data array or null if not found.
 */
export const getCourseDataFromLocalStorage = (major) => {
  try {
    const courseDataJSON = localStorage.getItem(`courseData-${major}`);
    return courseDataJSON ? JSON.parse(courseDataJSON) : null;
  } catch (error) {
    console.error('Error retrieving course data from local storage:', error);
    return null;
  }
};

/**
 * Fetches courses for a given major from Firebase Realtime Database.
 *
 * @param {string} major - The major to fetch courses for.
 * @param {Array} fetchedCourseData - Array of fetched course data for lookups.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of course objects.
 */
export const fetchCoursesFromDatabase = async (major, fetchedCourseData) => {
  try {
    const courseDataFromLocalStorage = getCourseDataFromLocalStorage(major);
    if (courseDataFromLocalStorage) {
      console.log('Retrieved course data from localStorage.');
      return courseDataFromLocalStorage;
    }

    const courseIds = [];

    // Define the Firebase Realtime Database path for the major
    const projectAndCollectionId = `1D2GTM7xtDQT7Uc-gZf_5hBSvfsOAErXtGeL37Tn21fQ/${major}`;
    const dbRef = ref(getDatabase(), projectAndCollectionId);

    // Fetch data from Firebase
    const coursesSnapshot = await get(dbRef);

    coursesSnapshot.forEach((courseSnapshot) => {
      const courseData = courseSnapshot.val();

      // Helper functions to extract course details
      const findCredits = (course) => {
        if (course) {
          const match = fetchedCourseData.find(
            (fetchedCourse) =>
              fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
          );
          return match ? parseFloat(match.Credits) || 0 : 0;
        }
        return 0;
      };

      const findName = (course) => {
        if (course) {
          if (course.Name) {
            return course.Name;
          }
          const match = fetchedCourseData.find(
            (fetchedCourse) =>
              fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
          );
          return match ? match.courseInfo_courseName : 'Unnamed Course';
        }
        return 'Unnamed Course';
      };

      const findDescription = (course) => {
        if (course) {
          const match = fetchedCourseData.find(
            (fetchedCourse) =>
              fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
          );
          return match ? match.Description : 'No Description Available';
        }
        return 'No Description Available';
      };

      // Push the formatted course object into courseIds
      courseIds.push({
        CourseSem: courseData.CourseSem,
        Credits: findCredits(courseData),
        cal_name: courseData.cal_name,
        courseCat: courseData.courseCat,
        courseInfo_courseName: findName(courseData),
        courseInfo_courseNumber: courseData.courseInfo_courseNumber,
        courseYear: courseData.courseYear,
        max_capacity: courseData.max_capacity,
        sectionInfo_sectionNumber: courseData.sectionInfo_sectionNumber,
        sectionInfo_teacherDisplay: courseData.sectionInfo_teacherDisplay,
        isAutoFill: courseData.isAutoFill,
        isPrereqMet: true, // Assuming prerequisites are met by default
        Prerequisites: '',
        Corequisites: '',
        Instructor: courseData.Instructor,
        Major: courseData.Major,
        Possibilities: courseData.Possibilities,
        Description: findDescription(courseData),
        Requirements: courseData.Requirements,
        TransferPlan: courseData.TransferPlan,
        Name: courseData.Name,
        courseType: courseData.courseType,
        degreeName: major,
      });
    });

    // Save fetched courses to localStorage for future use
    saveCourseDataToLocalStorage(major, courseIds);
    console.log('Fetched and saved course data to localStorage.');

    return courseIds;
  } catch (error) {
    console.error('Error fetching course IDs from the Realtime Database:', error);
    return [];
  }
};
