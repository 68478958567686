import { db } from './firebase';
import { toast } from 'react-toastify';


const handleAutofillCourse = async (
    allCourses,
    yearNumber,
    semester,
    userId,
    templateName,
    numYearsNew,
    fetchedMajorElectives,
    fetchedMajorRequirements,
    fetchedMajorCourses,
  ) => {
    const autofillUser = userId;
    const newScheduleName = templateName;
    const loadingToastId = 'autofill-course-toast';

    try {
      if (autofillUser) {
        const userRef = db.collection('users').doc(userId);
        const userDoc = await userRef.get();
        await db.runTransaction(async (transaction) => {

            let userData = userDoc.data()

            if (userDoc.exists) {
                userData = userDoc.data(); // Retrieve the document data
                console.log("User Data:", userData); // Log or use the retrieved data
            } else {
                console.log("No such document!");
            }

          let semesterCode = semester === 'Fall' ? 'S1' : 'S2';

          const isComplete = userData?.completedSemesters?.[`year${yearNumber}`]?.[semesterCode]

          // Case in which the semetser is completed, take data from first other schedule
          if (isComplete) {
            if (Object.keys(userData?.schedules).length > 0) {
              const firstScheduleName = Object.keys(userData?.schedules)[0];
              const completedSemesterData = userData?.schedules?.[firstScheduleName]?.[`year${yearNumber}`]?.[semesterCode].courses;
              transaction.update(userRef, {
                [`schedules.${newScheduleName}.year${yearNumber}.${semesterCode}.courses`]:
                  completedSemesterData,
                });
              return
            } else {
              return
            }
          }

          console.log(userData.schedules['asdfasdf'], userData.schedules, userData, 'hihi')


          const previousFinalizedSchedule =
            userData.schedules[newScheduleName] || {};

          const updateObj = previousFinalizedSchedule
            ? { ...previousFinalizedSchedule }
            : {};

          if (!updateObj[`year${yearNumber}`]) {
            updateObj[`year${yearNumber}`] = {};
          }
          if (!updateObj[`year${yearNumber}`][semesterCode]) {
            updateObj[`year${yearNumber}`][semesterCode] = { courses: [] };
          }

          const existingFinalizedSchedule =
            updateObj[`year${yearNumber}`][semesterCode].courses;

          const allFinalizedCourses = new Set();
        //   for (const year in finalizedOrganizedCourses) {
        //     for (const sem in finalizedOrganizedCourses[year]) {
        //       finalizedOrganizedCourses[year][sem].forEach((course) => {
        //         allFinalizedCourses.add(course.courseInfo_courseNumber);
        //       });
        //     }
        //   }

          let allClassNumbers = [];

          for (const year in updateObj) {
            for (const semester in updateObj[year]) {
              allClassNumbers = allClassNumbers.concat(
                updateObj[year][semester].courses
              );
            }
          }

          const allAutofillableCourses = allCourses.filter(
            (course) =>
              course.courseYear <= 4 &&
              course.Offering.includes(semester) &&
              !allFinalizedCourses.has(course.courseInfo_courseNumber) &&
              course.isPrereqMet
          );

          // Major Courses
          const combinedSemesterMajorCourses = [];

          Object.entries(fetchedMajorCourses).forEach(([majorName, courseInfo]) => {
            /*const organizedMajorCourses = organizeMajorCoursesByYearAndSemester(
              courseInfo
            );*/
            const organizedMajorCourses = courseInfo;
            if (organizedMajorCourses && organizedMajorCourses[`year${yearNumber}`]) {
              combinedSemesterMajorCourses.push(
                ...(organizedMajorCourses[`year${yearNumber}`][semester] || [])
              );
            }
          });

          let semesterMajorCourses =
            combinedSemesterMajorCourses.length > 0
              ? combinedSemesterMajorCourses
              : [];

          const semesterMajorCoursesWithAutofill = semesterMajorCourses.map(
            (course) => ({
              ...course,
              autofillType: 'Major Course',
            })
          );

          // Major Electives
          let combinedElectiveCourses = [];

          Object.entries(fetchedMajorElectives).forEach(
            ([majorName, electivesByMajor]) => {
              if (electivesByMajor && electivesByMajor[`year${yearNumber}`]) {
                combinedElectiveCourses.push(
                  ...electivesByMajor[`year${yearNumber}`][semester]
                );
              }
            }
          );

          const electiveNameStrings = combinedElectiveCourses.map(
            (course) => course.Possibilities
          );

          const semesterElectiveRequirements = electiveNameStrings.reduce(
            (acc, name) => {
              if (!acc[name]) {
                const course = combinedElectiveCourses.find(
                  (course) => course.Possibilities === name
                );
                const electiveFullName = course ? course.Requirements : '';
                acc[name] = {
                  quantity: 0,
                  electiveFullName: electiveFullName,
                };
              }
              acc[name].quantity += 1;

              return acc;
            },
            {}
          );

          let autofillElectiveCourses = [];

          Object.entries(semesterElectiveRequirements).forEach(
            ([requirementName, { quantity, electiveFullName }]) => {
              const existingCourses = existingFinalizedSchedule
                .map((courseNumber) =>
                  allCourses.find(
                    (course) =>
                      course.courseInfo_courseNumber === courseNumber
                  )
                )
                .filter(
                  (course) =>
                    course &&
                    course.Elective_fulfillment.includes(requirementName)
                );

              const remainingQuantity = quantity - existingCourses.length;

              if (remainingQuantity > 0) {
                const filteredCourses = allAutofillableCourses.filter(
                  (course) =>
                    course.Elective_fulfillment.includes(requirementName) &&
                    !allClassNumbers.includes(course.courseInfo_courseNumber)
                );

                const sortedCourses = filteredCourses.sort(
                  (a, b) => b.recScore - a.recScore
                );

                const topCourses = sortedCourses
                  .slice(0, remainingQuantity)
                  .map((course) => ({
                    ...course,
                    electiveRequirement: requirementName,
                    electiveFullName,
                  }));

                autofillElectiveCourses.push(...topCourses);
              }
            }
          );

          const autofillElectiveCoursesWithAutofill =
            autofillElectiveCourses.map((course) => ({
              ...course,
              autofillType: 'Elective',
            }));


          // Major Requirements
          let combinedRequirementCourses = [];

          Object.entries(fetchedMajorRequirements).forEach(
            ([majorName, requirementsByMajor]) => {
              if (
                requirementsByMajor &&
                requirementsByMajor[`year${yearNumber}`]
              ) {
                combinedRequirementCourses.push(
                  ...requirementsByMajor[`year${yearNumber}`][semester]
                );
              }
            }
          );

          const requirementNameStrings = combinedRequirementCourses.map(
            (course) => course.Requirements
          );

          const semesterRequirementDetails = requirementNameStrings.reduce(
            (acc, name) => {
              if (!acc[name]) {
                const course = combinedRequirementCourses.find(
                  (course) => course.Requirements === name
                );
                const possibleCourses = course
                  ? course.Possibilities.split(',').map((course) =>
                      course.trim()
                    )
                  : [];
                acc[name] = {
                  quantity: 0,
                  possibleCourses: possibleCourses,
                };
              }
              acc[name].quantity += 1;

              return acc;
            },
            {}
          );

          let autofillRequirementCourses = [];

          Object.entries(semesterRequirementDetails).forEach(
            ([requirementName, { quantity, possibleCourses }]) => {
              const commonElementsCount = possibleCourses.filter((course) =>
                existingFinalizedSchedule.includes(course)
              ).length;

              const remainingQuantity = quantity - commonElementsCount;

              if (remainingQuantity > 0) {
                const filteredCourses = possibleCourses
                  .map((courseNumber) =>
                    allAutofillableCourses.find(
                      (course) =>
                        course.courseInfo_courseNumber === courseNumber &&
                        !allClassNumbers.includes(courseNumber)
                    )
                  )
                  .filter((course) => course !== undefined);

                const sortedCourses = filteredCourses.sort(
                  (a, b) => b.recScore - a.recScore
                );

                const topCourses = sortedCourses
                  .slice(0, remainingQuantity)
                  .map((course) => ({
                    ...course,
                    requirementName: requirementName,
                  }));

                autofillRequirementCourses.push(...topCourses);
              }
            }
          );

          const autofillRequirementCoursesWithAutofill =
            autofillRequirementCourses.map((course) => ({
              ...course,
              autofillType: 'Requirement',
            }));

          // Combine all autofill courses, excluding minor courses
          const semesterAutofillCourses = [
            ...semesterMajorCoursesWithAutofill,
            ...autofillElectiveCoursesWithAutofill,
            ...autofillRequirementCoursesWithAutofill,
            // Minor courses are excluded
          ];

          const uniqueAutofillCourses = semesterAutofillCourses.reduce(
            (acc, current) => {
              const x = acc.find(
                (item) =>
                  item.courseInfo_courseNumber ===
                  current.courseInfo_courseNumber
              );
              if (!x) {
                acc.push(current);
              }
              return acc.filter(
                (course) =>
                  !allClassNumbers.includes(course.courseInfo_courseNumber)
              );
            },
            []
          );

          const semesterAutofillCoursesWithStatus = uniqueAutofillCourses.map(
            (course) => ({
              ...course,
              isAutofill: true,
            })
          );

          const semesterAutofillCourseIds = semesterAutofillCoursesWithStatus.map(
            (course) => course.courseInfo_courseNumber
          );
          const updatedFinalizedSchedule = [
            ...new Set([
              ...existingFinalizedSchedule,
              ...semesterAutofillCourseIds,
            ]),
          ];
          updateObj[`year${yearNumber}`][semesterCode].courses =
            updatedFinalizedSchedule;
            updateObj.numYears = numYearsNew;
            console.log('Numyears: ', numYearsNew, updateObj);
            

          transaction.update(userRef, {
            [`schedules.${newScheduleName}.year${yearNumber}.${semesterCode}.courses`]:
              updatedFinalizedSchedule,
              [`schedules.${newScheduleName}.numYears`]: numYearsNew
          });

        //   setFinalizedOrganizedCourses((prev) => {
        //     const newCourses = { ...prev };

        //     const existingCourseNumbers =
        //       newCourses?.[`year${yearNumber}`]?.[semester]?.map(
        //         (course) => course?.courseInfo_courseNumber
        //       ) ?? [];

        //     const filteredCourses = semesterAutofillCoursesWithStatus.filter(
        //       (course) =>
        //         !existingCourseNumbers.includes(course.courseInfo_courseNumber)
        //     );

        //     // Ensure newCourses[`year${yearNumber}`] is defined
        //     newCourses[`year${yearNumber}`] =
        //       newCourses[`year${yearNumber}`] ?? {};

        //     // Ensure newCourses[`year${yearNumber}`][semester] is defined
        //     newCourses[`year${yearNumber}`][semester] =
        //       newCourses[`year${yearNumber}`][semester] ?? [];

        //     // Now safely update newCourses[`year${yearNumber}`][semester]
        //     newCourses[`year${yearNumber}`][semester] = [
        //       ...new Set([
        //         ...newCourses[`year${yearNumber}`][semester],
        //         ...filteredCourses,
        //       ]),
        //     ];

        //     const updatedCourses = recheckPrerequisites(newCourses);

        //     return updatedCourses;
        //   });

          console.log('Courses Autofilled Successfully.');
          // Update the toast to success status
          setTimeout(() => {
            toast.update(loadingToastId, {
              render: 'Applied Template',
              type: 'success',
              autoClose: 1000,
              isLoading: false,
              hideProgressBar: true,
              style: {
                width: '12rem',
              },
            });
          }, 500); // Delay the transition by 500ms to keep the loading state visible
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      // Show an error toast if the operation fails
      setTimeout(() => {
        toast.update(loadingToastId, {
          render: 'Failed apply template.',
          type: 'error',
          autoClose: 3000,
          isLoading: false,
          style: {
            width: '20rem',
          },
        });
      }, 500);
      console.error('Error autofilling courses:', error);
    }
  };

export default handleAutofillCourse
