import React, { useState, useEffect, useCallback } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import { Spinner, Card, Button, Tooltip } from 'flowbite-react';
import {
  getFirestore,
  collection,
  getDocs,
} from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
} from 'recharts';
import { FaSyncAlt, FaDownload, FaInfoCircle } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import debounce from 'lodash.debounce';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

// **Define metricCollections outside the component to stabilize dependencies**
const metricCollections = [
  { key: 'applications', label: 'Applications', icon: '📄' },
  { key: 'completedApplications', label: 'Completed Applications', icon: '✅' },
  { key: 'admits', label: 'Admits', icon: '🎓' },
  { key: 'campusVisits', label: 'Campus Visits', icon: '🏫' },
  { key: 'fafsaSubmissions', label: 'FAFSA Submissions', icon: '💰' },
];

// A simple linear regression function
// Given data points of {x, y}, returns {slope, intercept}
function linearRegression(data) {
  const n = data.length;
  if (n < 2) return null;

  const sumX = data.reduce((acc, val) => acc + val.x, 0);
  const sumY = data.reduce((acc, val) => acc + val.y, 0);
  const sumXY = data.reduce((acc, val) => acc + val.x * val.y, 0);
  const sumX2 = data.reduce((acc, val) => acc + val.x * val.x, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
}

// Predict next year's count based on linear regression
function predictNextYear(data) {
  if (data.length < 2) {
    // Not enough data for regression; fallback to last known value
    return data.length > 0 ? data[data.length - 1].y : 0;
  }
  const { slope, intercept } = linearRegression(data);
  const maxYear = Math.max(...data.map(d => d.x));
  const nextYear = maxYear + 1;
  const prediction = slope * nextYear + intercept;
  return Math.round(prediction);
}

// Helper function to assign consistent colors to lines based on metric key
const getColor = (key) => {
  const colors = {
    applications: '#3182CE', // Blue
    completedApplications: '#38A169', // Green
    admits: '#D69E2E', // Yellow
    campusVisits: '#DD6B20', // Orange
    fafsaSubmissions: '#E53E3E', // Red
  };
  return colors[key] || '#8884d8';
};

// **Helper Functions for Actionable Insights**
// These functions are defined outside the component to ensure they are initialized before usage.

const getNegativeActions = (metric, current, predicted) => {
  switch (metric) {
    case 'Applications':
      return [
        'Enhance targeted outreach and marketing campaigns to attract more applicants.',
        'Simplify the application process to reduce barriers for prospective students.',
        'Implement referral programs to encourage current students to refer new applicants.',
      ];
    case 'Completed Applications':
      return [
        'Provide clear guidance and support throughout the application process.',
        'Implement follow-up communications to encourage completion of applications.',
        'Offer incentives for completing applications, such as early decision benefits.',
      ];
    case 'Admits':
      return [
        'Review and refine admission criteria to attract a diverse and qualified applicant pool.',
        'Enhance relationships with feeder schools and communities to increase admit rates.',
        'Analyze and address potential bottlenecks in the admission process.',
      ];
    case 'Campus Visits':
      return [
        'Organize engaging campus events and tours to attract prospective students.',
        'Leverage virtual tours and webinars to reach a broader audience.',
        'Collaborate with high schools and community organizations to promote campus visits.',
      ];
    case 'FAFSA Submissions':
      return [
        'Increase awareness campaigns about financial aid opportunities.',
        'Simplify the FAFSA submission process with step-by-step guides and support.',
        'Offer workshops or webinars to assist students in completing their FAFSA applications.',
      ];
    default:
      return [];
  }
};

const getPositiveActions = (metric, current, predicted) => {
  switch (metric) {
    case 'Applications':
      return [
        'Allocate resources to maintain and enhance successful recruitment strategies.',
        'Analyze successful outreach channels to replicate their effectiveness.',
        'Invest in digital marketing to sustain application growth.',
      ];
    case 'Completed Applications':
      return [
        'Maintain support systems that aid applicants in completing their applications.',
        'Gather feedback to understand what is working and continue those practices.',
        'Expand successful initiatives to further increase completion rates.',
      ];
    case 'Admits':
      return [
        'Continue refining admission strategies that are yielding positive results.',
        'Invest in outreach programs that attract high-quality applicants.',
        'Strengthen partnerships with educational institutions to sustain admit growth.',
      ];
    case 'Campus Visits':
      return [
        'Enhance campus visit experiences to capitalize on increased interest.',
        'Use successful engagement strategies as a model for future events.',
        'Expand virtual visit offerings to maintain and grow visitor numbers.',
      ];
    case 'FAFSA Submissions':
      return [
        'Sustain awareness campaigns to ensure continued FAFSA submissions.',
        'Provide ongoing support and resources to assist students with financial aid applications.',
        'Evaluate successful financial aid initiatives and consider scaling them.',
      ];
    default:
      return [];
  }
};

const getNeutralActions = (metric, current) => {
  return [
    'Maintain current strategies to ensure stability in this metric.',
    'Monitor trends closely to quickly identify and address future changes.',
    'Continue supporting existing successful initiatives.',
  ];
};

const EnrollmentDashboard = () => {
  const db = getFirestore();

  const [metrics, setMetrics] = useState({
    applications: null,
    completedApplications: null,
    admits: null,
    campusVisits: null,
    fafsaSubmissions: null,
  });

  const [loading, setLoading] = useState(true);

  // **Fetch historical data from each metric's subcollection**
  const fetchMetrics = useCallback(async () => {
    setLoading(true);
    try {
      const newMetrics = {};

      for (const m of metricCollections) {
        const colRef = collection(db, 'enrollmentData', m.key, m.key);
        const snapshot = await getDocs(colRef);
        const dataPoints = [];
        snapshot.forEach((doc) => {
          const { year, count } = doc.data();
          if (typeof year === 'number' && typeof count === 'number') {
            dataPoints.push({ x: year, y: count });
          }
        });
        dataPoints.sort((a, b) => a.x - b.x);

        // Current count is last year's count
        const currentCount = dataPoints.length > 0 ? dataPoints[dataPoints.length - 1].y : 0;
        const prediction = predictNextYear(dataPoints);

        newMetrics[m.key] = {
          label: m.label,
          dataPoints,
          currentCount,
          prediction
        };
      }

      setMetrics(newMetrics);
    } catch (error) {
      console.error('Error fetching enrollment metrics:', error);
      toast.error('Failed to fetch enrollment metrics.');
    } finally {
      setLoading(false);
    }
  }, []);

  // **Debounced version of fetchMetrics to prevent rapid calls**
  const debouncedFetchMetrics = useCallback(debounce(fetchMetrics, 300), [fetchMetrics]);

  useEffect(() => {
    fetchMetrics();
    // Cleanup debounce on unmount
    return () => {
      debouncedFetchMetrics.cancel();
    };
  }, [fetchMetrics, debouncedFetchMetrics]);

  // **Export Data as CSV**
  const exportData = () => {
    const exportMetrics = metricCollections.map(m => {
      const data = metrics[m.key];
      return {
        Metric: data.label,
        'Current Count': data.currentCount,
        'Predicted Count': data.prediction,
      };
    });

    const csv = Papa.unparse(exportMetrics);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'enrollment_metrics.csv');
  };

  if (loading) {
    return (
      <div className="flex min-h-screen bg-white">
        <CustomSidebar />
        <div className="flex-1 p-4 ml-20 flex flex-col justify-center items-center">
          <Spinner aria-label="Loading metrics" />
          <p className="text-gray-500 mt-2">Loading enrollment data...</p>
        </div>
        <ToastContainer />
      </div>
    );
  }

  // **Prepare data for charts - Bar Chart**
  const barChartData = metricCollections.map((m) => ({
    name: m.label,
    Current: metrics[m.key].currentCount,
    Predicted: metrics[m.key].prediction
  }));

  // **Prepare historical data for line charts**
  const historicalChartData = {};
  metricCollections.forEach(m => {
    metrics[m.key].dataPoints.forEach(dp => {
      if (!historicalChartData[dp.x]) {
        historicalChartData[dp.x] = { year: dp.x };
      }
      historicalChartData[dp.x][m.label] = dp.y;
    });
  });
  const sortedYears = Object.keys(historicalChartData).sort((a, b) => a - b);
  const lineChartData = sortedYears.map(year => historicalChartData[year]);

  // **Actionable slides array**
  const actionableSlides = [];

  metricCollections.forEach(m => {
    const { currentCount, prediction, label } = metrics[m.key];
    const isDeclining = prediction < currentCount;
    const isIncreasing = prediction > currentCount;

    if (isDeclining) {
      actionableSlides.push({
        type: 'Decline',
        metric: label,
        description: `Projected decline from ${currentCount} to ${prediction}.`,
        actions: getNegativeActions(label, currentCount, prediction),
        color: 'red',
      });
    }

    if (isIncreasing) {
      actionableSlides.push({
        type: 'Growth',
        metric: label,
        description: `Projected increase from ${currentCount} to ${prediction}.`,
        actions: getPositiveActions(label, currentCount, prediction),
        color: 'green',
      });
    }

    // If prediction equals current, display a neutral insight
    if (prediction === currentCount) {
      actionableSlides.push({
        type: 'Stable',
        metric: label,
        description: `No change projected for ${label} (${currentCount}).`,
        actions: getNeutralActions(label, currentCount),
        color: 'blue',
      });
    }
  });

  return (
    <div className="flex min-h-screen bg-white">
      <CustomSidebar />
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Header */}
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2 mt-3 text-gray-800">Enrollment Health Dashboard</h1>
          <p className="text-base text-gray-600 mb-2">
            This tool continuously monitors the health of your enrollment pipeline, predicts future trends, and provides actionable steps to mitigate the enrollment cliff. By focusing on key metrics—applications, completed applications, admits, campus visits, and FAFSA completions—and layering predictive analytics on top, you can strategically allocate resources and keep enrollments robust.
          </p>
        </div>

        {/* Line Chart for Historical Trends */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Historical Trends</h2>
          <ResponsiveContainer height={400}>
            <LineChart data={lineChartData}>
              <XAxis dataKey="year" stroke="#A0AEC0" />
              <YAxis stroke="#A0AEC0" />
              <RechartsTooltip />
              <Legend />
              {metricCollections.map(m => (
                <Line
                  key={m.key}
                  type="monotone"
                  dataKey={m.label}
                  stroke={getColor(m.key)}
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Combined Bar Chart */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Current vs. Predicted Metrics</h2>
          <ResponsiveContainer height={400}>
            <BarChart data={barChartData}>
              <XAxis dataKey="name" stroke="#A0AEC0" />
              <YAxis stroke="#A0AEC0" />
              <RechartsTooltip />
              <Legend />
              <Bar dataKey="Current" fill="#3182CE" />
              <Bar dataKey="Predicted" fill="#38A169" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Summary Cards */}
        <div className="grid grid-cols-5 gap-2 mb-8">
          {metricCollections.map((m) => {
            const { currentCount, prediction, label } = metrics[m.key];
            const isDeclining = prediction < currentCount;
            const isIncreasing = prediction > currentCount;
            const isStable = prediction === currentCount;

            let borderColor = 'border-green-500';
            if (isDeclining) borderColor = 'border-red-500';
            if (isStable) borderColor = 'border-blue-500';

            return (
              <Card key={m.key} className={`flex flex-col justify-center items-center text-center border-l-4 ${borderColor}`}>
                <div className="text-3xl mb-2">{m.icon}</div>
                <div className="flex items-center space-x-1">
                  <h2 className="text-xl font-semibold text-gray-700">{label}</h2>
                </div>
                <p className="text-lg text-gray-600">Current: <span className="font-bold">{currentCount}</span></p>
                <p className={`text-lg ${isDeclining ? 'text-red-500' : isIncreasing ? 'text-green-500' : 'text-blue-500'}`}>
                  Predicted: <span className="font-bold">{prediction}</span>
                </p>
              </Card>
            );
          })}
        </div>

        {/* Actionable Insights */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Actionable Insights</h2>
          <Carousel
            showArrows
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            autoPlay
            interval={5000}
            transitionTime={500}
            swipeable
            emulateTouch
          >
            {actionableSlides.map((slide, index) => (
              <div key={index} className={`p-4 rounded-md ${slide.color === 'red' ? 'bg-red-50 border-l-4 border-red-500' : slide.color === 'green' ? 'bg-green-50 border-l-4 border-green-500' : 'bg-blue-50 border-l-4 border-blue-500'}`}>
                <h3 className="text-xl font-semibold mb-2">{slide.metric} - {slide.type}</h3>
                <p className="text-gray-700 mb-2">{slide.description}</p>
                {slide.actions.length > 0 && (
                  <ul className="list-disc list-inside text-gray-700">
                    {slide.actions.map((action, i) => (
                      <li key={i}>{action}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </Carousel>
        </div>

        {/* Export and Refresh Buttons at the Bottom */}
        <div className="flex justify-end space-x-4 mt-auto">
          <Button onClick={exportData} color="success" size="md" className="flex items-center space-x-2">
            <FaDownload />
            <span>Export CSV</span>
          </Button>
          <Button onClick={debouncedFetchMetrics} color="gray" size="md" className="flex items-center space-x-2">
            <FaSyncAlt />
            <span>Refresh Data</span>
          </Button>
        </div>

        {/* Toast Notifications */}
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    </div>
  );
};

export default EnrollmentDashboard;
