// src/components/Admin/AssignRole.jsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import firebase from 'firebase/compat/app';
import { db } from '../../firebase'; // Adjust the import path based on your project structure
import { Button, TextInput, Spinner, Select, Label, Checkbox } from 'flowbite-react';
import { toast } from 'react-toastify';
import CustomSidebar from '../Sidebar/Sidebar';

const AssignRole = () => {
  const { currentUser } = useAuth();
  
  // States for Role Assignment
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // States for Assigning Students to Advisors
  const [students, setStudents] = useState([]);
  const [advisorsList, setAdvisorsList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [selectedAdvisors, setSelectedAdvisors] = useState([]); // Changed to array for multiple advisors
  const [assignMessage, setAssignMessage] = useState('');
  const [assignLoading, setAssignLoading] = useState(false);

  // State for Meeting Notes (Optional for Future Enhancements)
  const [meetingNotes, setMeetingNotes] = useState('');

  // Fetch Students and Advisors when Component Mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch Students
        const studentsSnapshot = await db.collection('users').where('role', '==', 'student').get();
        const studentsData = studentsSnapshot.docs.map(doc => ({
          uid: doc.id,
          email: doc.data().email,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
        }));
        setStudents(studentsData);

        // Fetch Advisors
        const advisorsSnapshot = await db.collection('users').where('role', '==', 'advisor').get();
        const advisorsData = advisorsSnapshot.docs.map(doc => ({
          uid: doc.id,
          email: doc.data().email,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
        }));
        setAdvisorsList(advisorsData);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch users.');
      }
    };

    // Only Admins and Super Admins Can Assign Roles and Assign Students to Advisors
    if (currentUser && (currentUser.role === 'admin' || currentUser.role === 'super_admin')) {
      fetchUsers();
    }
  }, [currentUser]);

  // Function to Assign Role to User
  const assignRole = async () => {
    // Input Validation
    if (!email || !role || !firstName || !lastName) {
      setMessage('Please fill in all fields.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      // Call the Firebase Cloud Function to set custom claims
      const setCustomClaims = firebase.functions().httpsCallable('setCustomClaims');
      const result = await setCustomClaims({ email, role, firstName, lastName });
      setMessage(result.data.message);

      // Force token refresh to apply new claims
      await firebase.auth().currentUser.getIdToken(true);

      // If role is 'advisor', create a document in 'advisors' collection
      if (role === 'advisor') {
        // Fetch the user by email to get their UID
        const usersSnapshot = await db.collection('users').where('email', '==', email).get();
        if (!usersSnapshot.empty) {
          const userDoc = usersSnapshot.docs[0];
          const advisorUID = userDoc.id;

          // Check if advisor document already exists
          const advisorDocRef = db.collection('advisors').doc(advisorUID);
          const advisorDoc = await advisorDocRef.get();

          if (!advisorDoc.exists) {
            // Create a new advisor document
            await advisorDocRef.set({
              advisorId: advisorUID,
              firstName: firstName,
              lastName: lastName,
              email: email,
              assignedStudents: [], // Initialize with empty array
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            toast.success('Advisor added to advisors collection successfully.');
          } else {
            toast.info('Advisor already exists in advisors collection.');
          }
        } else {
          toast.error('User not found in users collection.');
        }
      }

      // Reset form fields after successful assignment
      setEmail('');
      setRole('');
      setFirstName('');
      setLastName('');
    } catch (error) {
      console.error('Error assigning role:', error);
      setMessage(`Error assigning role: ${error.message}`);
      toast.error(`Error assigning role: ${error.message}`);
    }

    setLoading(false);
  };

  // Function to Assign Students to Advisors
  const assignStudentToAdvisors = async () => {
    // Input Validation
    if (!selectedStudent || selectedAdvisors.length === 0) {
      setAssignMessage('Please select a student and at least one advisor.');
      return;
    }

    setAssignLoading(true);
    setAssignMessage('');

    try {
      // Reference to the studentAdvisors document
      const mappingDocRef = db.collection('studentAdvisors').doc(selectedStudent);
      const mappingDoc = await mappingDocRef.get();

      if (mappingDoc.exists) {
        // Existing advisors
        const existingAdvisors = mappingDoc.data().advisorIds || [];
        // New advisors to add (avoid duplicates)
        const advisorsToAdd = selectedAdvisors.filter(advisorId => !existingAdvisors.includes(advisorId));
        if (advisorsToAdd.length === 0) {
          setAssignMessage('Selected advisors are already assigned to this student.');
          toast.info('No new advisors to assign.');
          setAssignLoading(false);
          return;
        }

        // Update the advisorIds array
        await mappingDocRef.update({
          advisorIds: firebase.firestore.FieldValue.arrayUnion(...advisorsToAdd),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Update each advisor's assignedStudents array
        const updateAdvisorPromises = advisorsToAdd.map(advisorId => {
          return db.collection('advisors').doc(advisorId).update({
            assignedStudents: firebase.firestore.FieldValue.arrayUnion(selectedStudent),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });

        await Promise.all(updateAdvisorPromises);

        setAssignMessage('Advisors assigned to student successfully.');
        toast.success('Advisors assigned to student successfully.');
      } else {
        // Create a new studentAdvisors document
        await mappingDocRef.set({
          studentId: selectedStudent,
          advisorIds: selectedAdvisors,
          assignedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Update each advisor's assignedStudents array
        const updateAdvisorPromises = selectedAdvisors.map(advisorId => {
          return db.collection('advisors').doc(advisorId).update({
            assignedStudents: firebase.firestore.FieldValue.arrayUnion(selectedStudent),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });

        await Promise.all(updateAdvisorPromises);

        setAssignMessage('Advisors assigned to student successfully.');
        toast.success('Advisors assigned to student successfully.');
      }

      // Reset selection fields
      setSelectedStudent('');
      setSelectedAdvisors([]);
    } catch (error) {
      console.error('Error assigning advisors to student:', error);
      setAssignMessage(`Error assigning advisors to student: ${error.message}`);
      toast.error(`Error assigning advisors to student: ${error.message}`);
    }

    setAssignLoading(false);
  };

  // Function to Remove an Advisor from a Student
  const removeAdvisorFromStudent = async (studentId, advisorId) => {
    try {
      // Remove advisorId from studentAdvisors.advisorIds array
      await db.collection('studentAdvisors').doc(studentId).update({
        advisorIds: firebase.firestore.FieldValue.arrayRemove(advisorId),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Remove studentId from advisors.assignedStudents array
      await db.collection('advisors').doc(advisorId).update({
        assignedStudents: firebase.firestore.FieldValue.arrayRemove(studentId),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      toast.success('Advisor removed from student successfully.');

      // Optionally, refresh the students and advisors list or fetch current assignments
      // For simplicity, we're not doing that here
    } catch (error) {
      console.error('Error removing advisor from student:', error);
      toast.error(`Error removing advisor: ${error.message}`);
    }
  };


  // Function to Assign Transfer Credits
  const assignTransferCredits = async () => {
    // Hardcoded transfer data
    const transferData = {
      transferClasses: [
        {
          term: "Fall 2023",
          equivalentCourse: "MATH 150",
          credits: 3,
          institution: "SFU",
          grade: "A",
          incomingCourse: "MAT 216",
          sourceInstitution: "Arapahoe Cmty College"
        },
        {
          term: "Spring 2023",
          equivalentCourse: "MATH 151",
          credits: 3,
          institution: "SFU",
          grade: "B",
          incomingCourse: "MAT 217",
          sourceInstitution: "Arapahoe Cmty College"
        }
      ],
      testClasses: [
        {
          term: "Fall 2023",
          equivalentCourse: "ENGL 101W",
          credits: 3,
          institution: "SFU",
          testId: "SAT",
          testComponent: "ERWS",
          score: 770
        },
        {
          term: "Spring 2024",
          equivalentCourse: "BISC 100",
          credits: 3,
          institution: "SFU",
          testId: "AP Biology",
          testComponent: "N/A",
          score: 5
        }
      ],
      otherClasses: [
        {
          term: "Spring 2024",
          equivalentCourse: "MATH 152",
          credits: 3,
          institution: "SFU",
          grade: "A-",
          description: "Advanced mathematics course"
        }
      ]
    };

    // Input Validation
    if (!selectedStudent) {
      setAssignMessage('Please select a student.');
      return;
    }

    setLoading(true);
    setAssignMessage('');

    try {
      // Fetch the student document by UID
      const studentDocRef = db.collection('users').doc(selectedStudent);
      const studentDoc = await studentDocRef.get();

      if (!studentDoc.exists) {
        setAssignMessage('Student not found.');
        toast.error('Student not found.');
        setLoading(false);
        return;
      }

      // Add transferData field to the student's Firestore document
      await studentDocRef.update({
        transferData: transferData,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setAssignMessage('Transfer credits assigned successfully.');
      toast.success('Transfer credits assigned successfully.');

      // Reset fields after success
      setSelectedStudent('');
    } catch (error) {
      console.error('Error assigning transfer credits:', error);
      setAssignMessage(`Error assigning transfer credits: ${error.message}`);
      toast.error(`Error assigning transfer credits: ${error.message}`);
    }

    setLoading(false);
  };

  return (
    <div className="assign-role p-6 bg-white rounded-lg shadow-md">
      <CustomSidebar />
      {/* Assign Role to User Section */}
      <h2 className="text-2xl font-semibold mb-4">Assign Role to User</h2>
      <div className="space-y-4">
        {/* Email Input */}
        <div>
          <Label htmlFor="email" value="User Email" />
          <TextInput
            id="email"
            type="email"
            placeholder="user@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* First Name Input */}
        <div>
          <Label htmlFor="firstName" value="First Name" />
          <TextInput
            id="firstName"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>

        {/* Last Name Input */}
        <div>
          <Label htmlFor="lastName" value="Last Name" />
          <TextInput
            id="lastName"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        {/* Role Selection */}
        <div>
          <Label htmlFor="role" value="Role" />
          <Select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="" disabled>Select Role</option>
            <option value="student">Student</option>
            <option value="advisor">Advisor</option>
            <option value="admin">Admin</option>
            <option value="super_admin">Super Admin</option>
          </Select>
        </div>

        {/* Assign Role Button */}
        <Button onClick={assignRole} disabled={loading}>
          {loading ? <Spinner aria-label="Assigning role" size="sm" /> : 'Assign Role'}
        </Button>

        {/* Feedback Message */}
        {message && <p className="mt-2 text-sm text-gray-600">{message}</p>}
      </div>

      {/* Divider */}
      <div className="my-8 border-t"></div>

      {/* Assign Students to Advisors Section */}
      <h2 className="text-2xl font-semibold mb-4">Assign Student to Advisors</h2>
      <div className="space-y-4">
        {/* Select Student */}
        <div>
          <Label htmlFor="studentSelect" value="Select Student" />
          <Select
            id="studentSelect"
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
            required
          >
            <option value="" disabled>Select Student</option>
            {students.map(student => (
              <option key={student.uid} value={student.uid}>
                {student.firstName} {student.lastName} ({student.email})
              </option>
            ))}
          </Select>
        </div>

        {/* Select Advisors */}
        {selectedStudent && (
          <div>
            <Label value="Select Advisors" />
            <div className="flex flex-col space-y-2 max-h-48 overflow-y-auto border rounded p-2">
              {advisorsList.map(advisor => (
                <div key={advisor.uid} className="flex items-center">
                  <Checkbox
                    id={`advisor-${advisor.uid}`}
                    checked={selectedAdvisors.includes(advisor.uid)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedAdvisors([...selectedAdvisors, advisor.uid]);
                      } else {
                        setSelectedAdvisors(selectedAdvisors.filter(id => id !== advisor.uid));
                      }
                    }}
                  />
                  <label htmlFor={`advisor-${advisor.uid}`} className="ml-2 text-sm font-medium text-gray-700">
                    {advisor.firstName} {advisor.lastName} ({advisor.email})
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Assign Advisors Button */}
        <Button onClick={assignStudentToAdvisors} disabled={assignLoading}>
          {assignLoading ? <Spinner aria-label="Assigning advisors" size="sm" /> : 'Assign Advisors'}
        </Button>

        {/* Feedback Message */}
        {assignMessage && <p className="mt-2 text-sm text-gray-600">{assignMessage}</p>}
      </div>

      {/* Divider */}
      <div className="my-8 border-t"></div>

      {/* Display Assigned Advisors for Each Student */}
      <h2 className="text-2xl font-semibold mb-4">Assigned Advisors</h2>
      <div className="space-y-4">
        {students.map(student => (
          <div key={student.uid} className="p-4 border rounded-lg">
            <h3 className="text-xl font-semibold mb-2">{student.firstName} {student.lastName} ({student.email})</h3>
            {/* Fetch assigned advisors */}
            <AssignedAdvisors
              studentId={student.uid}
              removeAdvisor={removeAdvisorFromStudent}
            />
          </div>
        ))}
      </div>

      {/* Divider */}
      <div className="my-8 border-t"></div>

      {/* Assign Transfer Credits Section */}
      <h2 className="text-2xl font-semibold mb-4">Assign Transfer Credits</h2>
      <div className="space-y-4">
        {/* Select Student for Transfer Credit */}
        <div>
          <Label htmlFor="studentSelectTransfer" value="Select Student" />
          <Select
            id="studentSelectTransfer"
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
            required
          >
            <option value="" disabled>Select Student</option>
            {students.map(student => (
              <option key={student.uid} value={student.uid}>
                {student.firstName} {student.lastName} ({student.email})
              </option>
            ))}
          </Select>
        </div>

        {/* Assign Transfer Credits Button */}
        <Button onClick={assignTransferCredits} disabled={loading}>
          {loading ? <Spinner aria-label="Assigning transfer credits" size="sm" /> : 'Assign Transfer Credits'}
        </Button>

        {/* Feedback Message */}
        {assignMessage && <p className="mt-2 text-sm text-gray-600">{assignMessage}</p>}
      </div>
    </div>
  );
};

// Component to Display Assigned Advisors for a Student
const AssignedAdvisors = ({ studentId, removeAdvisor }) => {
  const [assignedAdvisors, setAssignedAdvisors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAssignedAdvisors = async () => {
      try {
        const mappingDoc = await db.collection('studentAdvisors').doc(studentId).get();
        if (mappingDoc.exists) {
          const advisorIds = mappingDoc.data().advisorIds || [];
          if (advisorIds.length === 0) {
            setAssignedAdvisors([]);
          } else {
            const advisorPromises = advisorIds.map(advisorId => db.collection('advisors').doc(advisorId).get());
            const advisorDocs = await Promise.all(advisorPromises);
            const advisorsData = advisorDocs.map(doc => ({
              uid: doc.id,
              firstName: doc.data().firstName,
              lastName: doc.data().lastName,
              email: doc.data().email,
            }));
            setAssignedAdvisors(advisorsData);
          }
        } else {
          setAssignedAdvisors([]);
        }
      } catch (error) {
        console.error('Error fetching assigned advisors:', error);
        toast.error('Failed to fetch assigned advisors.');
      }
      setLoading(false);
    };

    fetchAssignedAdvisors();
  }, [studentId]);

  if (loading) {
    return <Spinner aria-label="Loading assigned advisors" />;
  }

  if (assignedAdvisors.length === 0) {
    return <p className="text-sm text-gray-500">No advisors assigned.</p>;
  }

  return (
    <div className="space-y-2">
      {assignedAdvisors.map(advisor => (
        <div key={advisor.uid} className="flex items-center justify-between p-2 bg-gray-100 rounded">
          <div>
            <p className="font-medium">{advisor.firstName} {advisor.lastName}</p>
            <p className="text-sm text-gray-600">{advisor.email}</p>
          </div>
          <Button
            color="failure"
            size="xs"
            onClick={() => removeAdvisor(studentId, advisor.uid)}
          >
            Remove
          </Button>
        </div>
      ))}
    </div>
  );
};

export default AssignRole;
