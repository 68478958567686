import React from 'react';
import { Button, TextInput, Label } from 'flowbite-react';

const ScheduleReportModal = ({
  scheduleModalOpen,
  setScheduleModalOpen,
  scheduleName,
  setScheduleName,
  scheduleFrequency,
  setScheduleFrequency,
  scheduleTime,
  setScheduleTime,
  scheduleReport,
}) => {
  const closeModal = () => {
    setScheduleModalOpen(false);
  };

  return (
    <div
      id="schedule-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={closeModal}
    >
      <div
        className="relative p-4 w-full max-w-md max-h-full"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-lg font-semibold text-gray-900">
              Schedule Report
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={closeModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5">
            <div className="grid gap-4 mb-4">
              <div>
                <Label
                  htmlFor="scheduleName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Schedule Name
                </Label>
                <TextInput
                  id="scheduleName"
                  type="text"
                  placeholder="Enter schedule name"
                  value={scheduleName}
                  onChange={(e) => setScheduleName(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label
                  htmlFor="scheduleFrequency"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Frequency
                </Label>
                <select
                  id="scheduleFrequency"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={scheduleFrequency}
                  onChange={(e) => setScheduleFrequency(e.target.value)}
                >
                  <option value="">Select Frequency</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <div>
                <Label
                  htmlFor="scheduleTime"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Time
                </Label>
                <TextInput
                  id="scheduleTime"
                  type="time"
                  value={scheduleTime}
                  onChange={(e) => setScheduleTime(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="flex justify-center">
              <Button
                type="button"
                onClick={scheduleReport}
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"              >
                Schedule Report
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ScheduleReportModal;
