// src/utils/fetchElectives.js

/**
 * Fetches electives based on the provided majors.
 *
 * @param {Array} electiveMj - An array of majors to filter electives.
 * @param {Array} fetchedElective - An array of fetched elective data.
 * @returns {Array} - An array of electives corresponding to the provided majors.
 */
export const fetchElectivesFromDatabase = async (electiveMj, fetchedElective) => {
    try {
  
      const allElectives = [];
  
      // Filter electives that match the provided majors
      const fetchedEl = fetchedElective.filter((elective) => electiveMj.includes(elective.Major));
  
      // Map the filtered electives to the desired format
      fetchedEl.forEach((el) => {
        allElectives.push({
          Major: el.Major,
          Y1F: el['Year 1 Fall'],
          Y1S: el['Year 1 Spring'],
          Y2F: el['Year 2 Fall'],
          Y2S: el['Year 2 Spring'],
          Y3F: el['Year 3 Fall'],
          Y3S: el['Year 3 Spring'],
          Y4F: el['Year 4 Fall'],
          Y4S: el['Year 4 Spring'],
        });
      });
  
      return allElectives;
    } catch (error) {
      console.error('Error fetching electives from the Realtime Database:', error);
      return [];
    }
  };
  