// src/components/StudentAdvisorMeeting.jsx

import React, { useState, useEffect, useRef, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // For month view
import timeGridPlugin from '@fullcalendar/timegrid'; // For week/day view
import interactionPlugin from '@fullcalendar/interaction'; // For dateClick and eventClick
import { db } from '../firebase'; // Adjust the import path based on your project structure
import { useAuth } from '../../contexts/AuthContext'; // Adjust the import path
import {
  Button,
  Spinner,
  Label,
  Textarea,
  Table,
  Card,
  Avatar,
} from 'flowbite-react';
import { toast } from 'react-toastify';
import { FaUserCircle } from 'react-icons/fa'; // Import user icon
import firebase from 'firebase/compat/app'; // Ensure compatibility

// Predefined color palette for advisors
const ADVISOR_COLORS = [
  '#1f2937', // Gray-800
  '#3b82f6', // Blue-500
  '#10b981', // Green-500
  '#ef4444', // Red-500
  '#f59e0b', // Yellow-500
  '#8b5cf6', // Purple-500
  '#ec4899', // Pink-500
];

const StudentAdvisorMeeting = ({ advisors, onMeetingScheduled }) => {
  const { currentUser } = useAuth();
  const calendarRef = useRef(null);

  // State for selected advisors
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);

  // Compute advisorColors using useMemo to prevent unnecessary recalculations
  const advisorColors = useMemo(() => {
    const colors = {};
    if (advisors && advisors.length > 0) {
      advisors.forEach((advisor, index) => {
        colors[advisor.id] = ADVISOR_COLORS[index % ADVISOR_COLORS.length];
      });
    }
    return colors;
  }, [advisors]);

  const [advisorEvents, setAdvisorEvents] = useState([]);

  // State for modal form inputs
  const [meetingNotes, setMeetingNotes] = useState('');

  // State for scheduled meetings
  const [scheduledMeetings, setScheduledMeetings] = useState([]);

  // State for modals
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  const [isMeetingDetailsModalOpen, setIsMeetingDetailsModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedAdvisorForMeeting, setSelectedAdvisorForMeeting] = useState(null);

  // State for selected meeting
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isScheduling, setIsScheduling] = useState(false);
  const previousSelectedAdvisorsRef = useRef([]);

  // Initialize selected advisors
  useEffect(() => {
    if (advisors && advisors.length > 0) {
      const initialSelected = advisors.map((advisor) => advisor.id);
      setSelectedAdvisors(initialSelected);
    }
  }, [advisors]);

  // Fetch availability and advisor-created meetings in real-time
  useEffect(() => {
    const previousSelectedAdvisors = previousSelectedAdvisorsRef.current;
    const newSelectedAdvisors = selectedAdvisors;
  
    // Identify advisors that have been deselected
    const advisorsToRemove = previousSelectedAdvisors.filter(
      (id) => !newSelectedAdvisors.includes(id)
    );
  
    // Remove events related to the deselected advisors
    if (advisorsToRemove.length > 0) {
      setAdvisorEvents((prevEvents) =>
        prevEvents.filter(
          (event) => !advisorsToRemove.includes(event.extendedProps.advisorId)
        )
      );
    }
  
    let unsubscribe = () => {};
  
    if (newSelectedAdvisors.length > 0) {
      // Set up listeners for newly selected advisors
      const advisorUnsubscribes = newSelectedAdvisors.map((advisorId) => {
        return db
          .collection('availability')
          .doc(advisorId)
          .onSnapshot(
            (doc) => {
              if (doc.exists) {
                const data = doc.data();
                const availableSlots = data.availableSlots || [];
                const formattedAvailabilityEvents = availableSlots
                  .filter((slot) => !slot.isBooked)
                  .map((slot) => ({
                    id: `${advisorId}-${slot.id}`, // Ensure unique ID by prefixing with advisorId
                    title: 'Available',
                    start: `${slot.date}T${convertTimeTo24(slot.startTime)}`,
                    end: `${slot.date}T${convertTimeTo24(slot.endTime)}`,
                    backgroundColor: advisorColors[advisorId],
                    borderColor: advisorColors[advisorId],
                    extendedProps: {
                      isBooked: false,
                      advisorId: advisorId,
                      advisorName: `${advisors.find((a) => a.id === advisorId)?.firstName || ''} ${
                        advisors.find((a) => a.id === advisorId)?.lastName || ''
                      }`,
                      slotId: slot.id,
                    },
                  }));
  
                // Remove existing availability events for this advisor and add new ones
                setAdvisorEvents((prevEvents) => {
                  const filtered = prevEvents.filter(
                    (event) => event.extendedProps.advisorId !== advisorId || event.extendedProps.isBooked
                  );
                  return [...filtered, ...formattedAvailabilityEvents];
                });
              } else {
                // If the document doesn't exist, remove all availability events for this advisor
                setAdvisorEvents((prevEvents) =>
                  prevEvents.filter(
                    (event) => event.extendedProps.advisorId !== advisorId || event.extendedProps.isBooked
                  )
                );
              }
            },
            (error) => {
              console.error(`Error fetching availability for advisor ${advisorId}:`, error);
              toast.error(`Failed to fetch availability for advisor ${advisorId}.`);
            }
          );
      });
  
      // Listen to meetings created by advisors for the selected advisors
      const meetingsUnsubscribe =
        newSelectedAdvisors.length > 0
          ? db
              .collection('meetings')
              .where('advisorId', 'in', newSelectedAdvisors.slice(0, 10)) // Firestore 'in' queries support max 10
              .where('status', '==', 'Scheduled')
              .onSnapshot(
                (snapshot) => {
                  const formattedMeetingEvents = snapshot.docs.map((doc) => {
                    const meeting = doc.data();
                    const startDate = meeting.datetime.toDate();
                    const endDate = new Date(
                      startDate.getTime() + (meeting.meetingDuration || 60) * 60000
                    );
  
                    return {
                      id: `meeting-${doc.id}`, // Prefix to differentiate from availability
                      title: `Meeting with ${meeting.advisorName}`,
                      start: startDate.toISOString(),
                      end: endDate.toISOString(),
                      backgroundColor: '#6b7280', // Gray color for meetings
                      borderColor: '#6b7280',
                      extendedProps: {
                        isBooked: true,
                        meetingLink: meeting.meetingLink || '',
                        advisorId: meeting.advisorId,
                        advisorName: meeting.advisorName,
                        meetingId: doc.id,
                        isAdvisorCreated: true,
                        meetingNotes: meeting.meetingNotes || '',
                        slotId: meeting.slotId || null,
                        status: meeting.status || 'Scheduled',
                      },
                    };
                  });
  
                  setAdvisorEvents((prevEvents) => {
                    // Remove all advisor-created meetings
                    const filtered = prevEvents.filter(
                      (event) => !event.extendedProps.isAdvisorCreated
                    );
                    return [...filtered, ...formattedMeetingEvents];
                  });
                },
                (error) => {
                  console.error('Error fetching meetings:', error);
                  toast.error('Failed to fetch meetings.');
                }
              )
          : () => {};
  
      // Combine all unsubscribe functions
      unsubscribe = () => {
        advisorUnsubscribes.forEach((unsub) => unsub());
        if (typeof meetingsUnsubscribe === 'function') meetingsUnsubscribe();
      };
    }
  
    // Update the ref with the current selected advisors
    previousSelectedAdvisorsRef.current = newSelectedAdvisors;
  
    return () => {
      // Cleanup: Unsubscribe from Firestore listeners
      unsubscribe();
    };
  }, [selectedAdvisors, advisors, advisorColors]);

  // Fetch scheduled meetings for the student
  useEffect(() => {
    const fetchScheduledMeetings = () => {
      if (!currentUser) return;

      const unsubscribe = db
        .collection('meetings')
        .where('studentId', '==', currentUser.uid)
        .where('datetime', '>=', firebase.firestore.Timestamp.now()) // Fetch only upcoming meetings
        .orderBy('datetime', 'asc')
        .limit(50) // Increased limit to handle more meetings
        .onSnapshot(
          (snapshot) => {
            const meetingsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            console.log('Scheduled Meetings:', meetingsData); // Debugging log

            setScheduledMeetings(meetingsData);
          },
          (error) => {
            console.error('Error fetching scheduled meetings:', error);
            toast.error('Failed to fetch your scheduled meetings.');
          }
        );

      return () => unsubscribe();
    };

    fetchScheduledMeetings();
  }, [currentUser]);

  // Helper function to convert AM/PM to 24-hour format
  const convertTimeTo24 = (time) => {
    if (!time) return '00:00';
    const [timePart, meridiem] = time.split(' ');
    let [hour, minute] = timePart.split(':');
    hour = parseInt(hour, 10);

    if (meridiem === 'PM' && hour !== 12) {
      hour += 12;
    }
    if (meridiem === 'AM' && hour === 12) {
      hour = 0;
    }

    return `${hour.toString().padStart(2, '0')}:${minute}`;
  };

  // Handle advisor selection by clicking on avatars
  const handleAdvisorSelect = (advisorId) => {
    setSelectedAdvisors((prevSelected) =>
      prevSelected.includes(advisorId)
        ? prevSelected.filter((id) => id !== advisorId)
        : [...prevSelected, advisorId]
    );
  };

  // Handle event click to inform about booked slots or initiate booking
  const handleEventClick = (arg) => {
    const { event } = arg;
    console.log('Event clicked:', event);

    if (event.extendedProps.isBooked) {
      // Open meeting details modal
      setSelectedMeeting(event);
      setIsMeetingDetailsModalOpen(true);
    } else {
      // Initiate booking for available slot
      setSelectedSlot(event);
      setSelectedAdvisorForMeeting({
        id: event.extendedProps.advisorId,
        name: event.extendedProps.advisorName,
        slotId: event.extendedProps.slotId, // Use the event's slotId
        date: event.start,
        end: event.end,
      });
      setIsSchedulingModalOpen(true);
    }
  };

  // Schedule a meeting using Firestore batch for atomic operations
  const scheduleMeeting = async () => {
    if (!selectedSlot || !selectedAdvisorForMeeting) return;
    if (!meetingNotes.trim()) {
      toast.error('Please enter the purpose of the meeting.');
      return;
    }

    setIsScheduling(true);

    try {
      const { date, end, slotId, id: advisorId, name: advisorName } = selectedAdvisorForMeeting;
      const startDate = new Date(date);
      const endDate = new Date(end);
      const meetingDuration = Math.round((endDate - startDate) / (1000 * 60)); // Duration in minutes

      if (isNaN(meetingDuration) || meetingDuration <= 0) {
        toast.error('Invalid meeting duration.');
        setIsScheduling(false);
        return;
      }

      // Fetch Advisor's Meeting Link
      const advisorDoc = await db.collection('advisors').doc(advisorId).get();
      if (!advisorDoc.exists) {
        throw new Error('Advisor document does not exist.');
      }
      const advisorData = advisorDoc.data();
      const advisorMeetingLink = advisorData.meetingLink || ''; // Ensure there's a default or handle if empty

      if (!advisorMeetingLink) {
        toast.error('Advisor has not set up a meeting link.');
        setIsScheduling(false);
        return;
      }

      // Create a Firestore batch
      const batch = db.batch();

      // Create the Meeting Document
      const meetingRef = db.collection('meetings').doc(); // Generate a new document ID
      batch.set(meetingRef, {
        studentId: currentUser.uid,
        studentName: `${currentUser?.firstName ?? 'Student'} ${currentUser?.lastName ?? ''}`.trim() || 'Student',
        advisorId: advisorId,
        advisorName: advisorName,
        datetime: firebase.firestore.Timestamp.fromDate(startDate),
        status: 'Scheduled',
        meetingNotes: meetingNotes.trim(),
        meetingLink: advisorMeetingLink, // Set to advisor's meeting link
        meetingDuration: meetingDuration,
        slotId: slotId, // Store slotId
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Fetch the Availability Document
      const availabilityDocRef = db.collection('availability').doc(advisorId);
      const availabilityDoc = await availabilityDocRef.get();
      if (!availabilityDoc.exists) {
        throw new Error('Advisor availability document does not exist.');
      }
      const availabilityData = availabilityDoc.data();
      const slotToRemove = availabilityData.availableSlots.find((slot) => slot.id === slotId);

      if (!slotToRemove) {
        throw new Error('Selected availability slot does not exist.');
      }

      // Remove the Availability Slot
      batch.update(availabilityDocRef, {
        availableSlots: firebase.firestore.FieldValue.arrayRemove(slotToRemove),
      });

      // Create the Booked Slot with `isBooked: true` and Include `meetingLink`
      const bookedSlot = {
        ...slotToRemove,
        isBooked: true,
        meetingLink: advisorMeetingLink, // Use advisor's meeting link
      };

      // Add the Booked Slot Back
      batch.update(availabilityDocRef, {
        availableSlots: firebase.firestore.FieldValue.arrayUnion(bookedSlot),
      });

      // Commit the batch
      await batch.commit();

      toast.success('Meeting scheduled successfully!');
      setIsSchedulingModalOpen(false);
      setSelectedSlot(null);
      setMeetingNotes('');

      // Emit event to the parent component
      if (onMeetingScheduled) onMeetingScheduled();
    } catch (error) {
      console.error('Error scheduling meeting:', error);
      toast.error('Failed to schedule meeting. Please try again.');
    } finally {
      setIsScheduling(false);
    }
  };

  // Handle meeting cancellation using Firestore batch for atomic operations
  const handleCancelMeeting = async (meeting) => {
    const confirmCancel = window.confirm('Are you sure you want to cancel this meeting?');
    if (!confirmCancel) return;

    try {
      const batch = db.batch();

      // Update meeting status to 'Canceled'
      const meetingRef = db.collection('meetings').doc(meeting.extendedProps.meetingId);
      batch.update(meetingRef, {
        status: 'Canceled',
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Re-add the availability slot to 'availability' collection
      const advisorId = meeting.extendedProps.advisorId;
      const slotId = meeting.extendedProps.slotId; // Retrieve the original slotId

      if (!slotId) {
        throw new Error('Original slotId is missing from the meeting document.');
      }

      const availabilityDocRef = db.collection('availability').doc(advisorId);
      const availabilityDoc = await availabilityDocRef.get();

      if (!availabilityDoc.exists) {
        throw new Error('Advisor availability document does not exist.');
      }

      const availabilityData = availabilityDoc.data();

      // Find the original slot
      const originalSlot = availabilityData.availableSlots.find((slot) => slot.id === slotId);
      if (!originalSlot) {
        throw new Error('Original availability slot not found.');
      }

      // Re-add the slot with isBooked: false
      const updatedSlot = {
        ...originalSlot,
        isBooked: false,
        meetingLink: '', // Remove the meeting link
      };

      // Remove the booked slot and re-add the updated slot
      batch.update(availabilityDocRef, {
        availableSlots: firebase.firestore.FieldValue.arrayRemove({
          ...originalSlot,
          isBooked: true,
          meetingLink: originalSlot.meetingLink,
        }),
        availableSlots: firebase.firestore.FieldValue.arrayUnion(updatedSlot),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Commit the batch
      await batch.commit();

      toast.success('Meeting canceled successfully!');
      setIsMeetingDetailsModalOpen(false);
      setSelectedMeeting(null);
    } catch (error) {
      console.error('Error canceling meeting:', error);
      toast.error('Failed to cancel the meeting. Please try again.');
    }
  };

  // Optional: Use eventDidMount to ensure pointer events
  const handleEventDidMount = (info) => {
    info.el.style.pointerEvents = 'auto'; // Ensure the event element can receive pointer events
  };

  // MeetingDetailsModal Component
  const MeetingDetailsModal = ({ isOpen, onClose, meeting, onCancel }) => {
    if (!isOpen || !meeting) return null;

    const handleCancel = () => {
      onCancel(meeting);
    };

    return (
      <div
        className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
        onClick={onClose} // Close when clicking on the backdrop
      >
        <div
          className="relative w-full max-w-lg h-auto"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        >
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700 flex flex-col">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 border-b dark:border-gray-600">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Meeting Details
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1l6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            {/* Modal body */}
            <div className="p-4">
              <div className="space-y-4">
                <div>
                  <Label className="text-sm font-medium text-gray-700">
                    Date
                  </Label>
                  <p className="text-gray-900">
                    {new Date(meeting.start).toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <Label className="text-sm font-medium text-gray-700">
                    Time
                  </Label>
                  <p className="text-gray-900">
                    {new Date(meeting.start).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}{' '}
                    -{' '}
                    {new Date(meeting.end).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                </div>
                <div>
                  <Label className="text-sm font-medium text-gray-700">
                    Advisor
                  </Label>
                  <p className="text-gray-900">
                    {meeting.extendedProps.advisorName}
                  </p>
                </div>
                <div>
                  <Label className="text-sm font-medium text-gray-700">
                    Purpose
                  </Label>
                  <p className="text-gray-900">
                    {meeting.extendedProps.meetingNotes}
                  </p>
                </div>
              </div>

              {/* Modal Actions */}
              <div className="flex justify-end space-x-2 mt-4">
                <Button
                  color="failure"
                  onClick={handleCancel}
                  disabled={meeting.extendedProps.status === 'Canceled'}
                >
                  Cancel Meeting
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    if (meeting.extendedProps.meetingLink) {
                      window.open(meeting.extendedProps.meetingLink, '_blank');
                    } else {
                      toast.info('No meeting link available.');
                    }
                  }}
                >
                  Join Meeting
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        {/* Combined "My Success Team" and Advisor Selection */}
        <Card className="mb-4">
          <h3 className="text-xl font-semibold">My Success Team</h3>
          <div className="flex flex-wrap">
            {advisors.map((advisor) => (
              <div
                key={advisor.id}
                className={`flex flex-col items-center mr-4 cursor-pointer ${
                  selectedAdvisors.includes(advisor.id) ? 'opacity-100' : 'opacity-50'
                }`}
                onClick={() => handleAdvisorSelect(advisor.id)}
              >
                <Avatar
                  img={advisor.profilePicture || ''}
                  rounded={true}
                  fallback={<FaUserCircle size={40} className="text-gray-400" />}
                  alt={`${advisor.firstName} ${advisor.lastName}`}
                  className="mb-2 border-2"
                  style={{
                    borderColor: selectedAdvisors.includes(advisor.id)
                      ? advisorColors[advisor.id]
                      : 'transparent',
                  }}
                />
                <div className="flex items-center">
                  {/* Colored Dot Indicator */}
                  <span
                    className="inline-block w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: advisorColors[advisor.id] }}
                  ></span>
                  <span className="text-sm font-medium text-gray-700">
                    {`${advisor.firstName} ${advisor.lastName}`}
                  </span>
                </div>
                {/* Optional: Add a checkmark or indicator for selected advisors */}
                {selectedAdvisors.includes(advisor.id) && (
                  <span className="text-blue-500 text-xs">Selected</span>
                )}
              </div>
            ))}
          </div>
        </Card>

        {/* Calendar Section */}
        <Card className="mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
            Advisor Availability
          </h3>
          {advisorEvents && Array.isArray(advisorEvents) ? (
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              selectable={false}
              selectMirror={false}
              dayMaxEvents={true}
              weekends={false}
              events={advisorEvents}
              ref={calendarRef}
              eventClick={handleEventClick}
              height="600px"
              eventDidMount={handleEventDidMount}
              editable={false}
              eventOverlap={false}
              businessHours={{
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: '08:00',
                endTime: '17:00',
              }}
              slotMinTime="08:00:00"
              slotMaxTime="17:00:00"
              allDaySlot={false}
              slotDuration="00:15:00"
              nowIndicator={true}
              scrollTime="08:00:00"
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }}
              timeZone="local"
            />
          ) : (
            <div className="flex justify-center items-center">
              <Spinner aria-label="Loading advisor availability" />
            </div>
          )}
        </Card>

        {/* Scheduled Meetings Section */}
        <Card className="mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
            Your Scheduled Meetings
          </h3>
          {scheduledMeetings && scheduledMeetings.length > 0 ? (
            <Table hoverable={true}>
              <Table.Head>
                <Table.HeadCell>Date</Table.HeadCell>
                <Table.HeadCell>Time</Table.HeadCell>
                <Table.HeadCell>Advisor</Table.HeadCell>
                <Table.HeadCell>Purpose</Table.HeadCell>
                <Table.HeadCell>Meeting Link</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {scheduledMeetings.map((meeting) => (
                  <Table.Row
                    key={meeting.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell>
                      {meeting.datetime.toDate().toLocaleDateString()}
                    </Table.Cell>
                    <Table.Cell>
                      {meeting.datetime.toDate().toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Table.Cell>
                    <Table.Cell>{meeting.advisorName}</Table.Cell>
                    <Table.Cell>{meeting.meetingNotes}</Table.Cell>
                    <Table.Cell>
                      {meeting.meetingLink ? (
                        <a
                          href={meeting.meetingLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          Join
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <span
                        className={`px-2 py-1 text-xs font-semibold rounded-full ${
                          meeting.status === 'Scheduled'
                            ? 'bg-green-100 text-green-800'
                            : meeting.status === 'Completed'
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {meeting.status || 'N/A'}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <p className="text-gray-600 dark:text-gray-300">
              You have no scheduled meetings.
            </p>
          )}
        </Card>
      </div>

      {/* Schedule Meeting Modal */}
      {isSchedulingModalOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
          onClick={() => setIsSchedulingModalOpen(false)} // Close when clicking on the backdrop
        >
          <div
            className="relative w-full max-w-lg h-auto"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700 flex flex-col">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 border-b dark:border-gray-600">
                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                  Schedule a Meeting
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsSchedulingModalOpen(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              {/* Modal body */}
              <div className="p-4">
                {/* Meeting Details */}
                {selectedAdvisorForMeeting ? (
                  <div className="space-y-4">
                    <div>
                      <Label className="text-sm font-medium text-gray-700">
                        Date
                      </Label>
                      <p className="text-gray-900">
                        {new Date(selectedAdvisorForMeeting.date).toLocaleDateString()}
                      </p>
                    </div>
                    <div>
                      <Label className="text-sm font-medium text-gray-700">
                        Time
                      </Label>
                      <p className="text-gray-900">
                        {new Date(selectedAdvisorForMeeting.date).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}{' '}
                        -{' '}
                        {new Date(selectedAdvisorForMeeting.end).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>
                    </div>

                    <div>
                      <Label htmlFor="meetingNotes" value="Purpose of Meeting" />
                      <Textarea
                        id="meetingNotes"
                        placeholder="e.g., Discuss course selection"
                        value={meetingNotes}
                        onChange={(e) => setMeetingNotes(e.target.value)}
                        required
                      />
                    </div>

                    <div className="flex justify-end space-x-2">
                      <Button
                        color="failure"
                        onClick={() => setIsSchedulingModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="success"
                        onClick={scheduleMeeting}
                        disabled={isScheduling}
                      >
                        {isScheduling ? <Spinner size="sm" /> : 'Confirm'}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <Spinner aria-label="Loading meeting details" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Meeting Details Modal */}
      {isMeetingDetailsModalOpen && selectedMeeting && (
        <MeetingDetailsModal
          isOpen={isMeetingDetailsModalOpen}
          onClose={() => setIsMeetingDetailsModalOpen(false)}
          meeting={selectedMeeting}
          onCancel={handleCancelMeeting}
        />
      )}
    </div>
  );
};

export default StudentAdvisorMeeting;
