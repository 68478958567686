// MilestoneModal.js
import React, { useState, useEffect } from 'react';

const MilestoneModal = ({ showModal, setShowModal, handleSaveMilestone, initialData = null }) => {
  const [milestoneTitle, setMilestoneTitle] = useState('');
  const [milestoneDescription, setMilestoneDescription] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!showModal) {
      // Clear the error and input fields when the modal is closed
      setError('');
      setMilestoneTitle('');
      setMilestoneDescription('');
      setDueDate('');
    } else if (initialData) {
      // If editing, pre-fill the fields with correct date format "yyyy-MM-dd"
      setMilestoneTitle(initialData.Title);
      setMilestoneDescription(initialData.Description);
      // Convert existing dueDate to "yyyy-MM-dd" format if necessary
      const existingDueDate = new Date(initialData.DueDate);
      const formattedDueDate = existingDueDate.toISOString().split('T')[0];
      setDueDate(formattedDueDate);
    }
  }, [showModal, initialData]);

  const saveMilestone = () => {
    // Validation: Check if all required fields are filled
    if (!milestoneTitle || !milestoneDescription || !dueDate) {
      setError('All fields are required.');
      return; // Do not proceed if validation fails
    }

    handleSaveMilestone(
      milestoneTitle,
      milestoneDescription,
      dueDate,
      initialData ? initialData.Milestone_ID : null
    );
    setShowModal(false); // Close the modal after saving
  };

  return (
    showModal && (
      <div
        id="crud-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full overflow-y-auto overflow-x-hidden flex"
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {initialData ? 'Edit Milestone' : 'Add Milestone'}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <form className="p-4 md:p-5">
              <div className="grid gap-4 mb-4">
                <div className="col-span-2">
                  <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Enter milestone title"
                    value={milestoneTitle}
                    onChange={(e) => setMilestoneTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Description
                  </label>
                  <textarea
                    id="description"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Enter milestone description"
                    value={milestoneDescription}
                    onChange={(e) => setMilestoneDescription(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="col-span-2">
                  <label htmlFor="dueDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Due Date
                  </label>
                  <input
                    type="date"
                    id="dueDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    required
                  />
                </div>
              </div>
              {error && (
                <div className="flex justify-center mb-4">
                  <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                    {error}
                  </span>
                </div>
              )}
              <div className="flex justify-center">
                <button
                  type="button"
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={saveMilestone}
                >
                  {initialData ? 'Save Changes' : 'Save Milestone'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default MilestoneModal;